
import {
  CostType,
  Money,
  Product,
  ProductCombinationType,
  ProductPackageSet,
  ProductSetCost,
  ProductSetInfoKeyType,
  ProjectActivityTarget,
  TupleOfCostTypeAndStringAndBooleanInput,
  TupleOfStringAndBooleanInput,
  TupleOfGuidAndPriceVariantInput,
  PriceVariant,
  ProductPackageCategory,
  Category
} from '@/__generated__/globalTypes'
import UpdateProductSetInfos from '@/components/updateProductSetInfos/UpdateProductSetInfos.vue'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import { CombinationProductSetActivityFragment } from '@/views/productPackageView/gql/__generated__/combinationProductSetActivities.query'
import { CategoryWithProductSetInfoKeysStructure } from '@/views/productPackageView/types'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import AddProductModal from './AddProductModal.vue'
import ProductSetActivitiesList from './ProductSetActivitiesList.vue'
import {
  CreateProductSetDocument,
  CreateProductSetMutation,
  CreateProductSetMutationVariables
} from './gql/__generated__/createProductSet.mutation'
import {
  ProductInfoDocument,
  ProductInfoQuery,
  ProductInfoQueryVariables
} from './gql/__generated__/getProductInfo.query'
import {
  AssignedPriceVariantFragment,
  AssignedProductSetActivityFragment
} from './gql/__generated__/getProductSetList.query'
import {
  UpdateProductSetDocument,
  UpdateProductSetMutation,
  UpdateProductSetMutationVariables
} from './gql/__generated__/updateProductSet.mutation'
import { ProductSetInfoKeyModel, UpsertActionType } from './types'
import PercentageField from '@/components/designPackages/PercentageField.vue'
import { TableColumn } from '../simpleDataTable/types'
import SimpleDataTable from '../simpleDataTable/SimpleDataTable.vue'

@Component({
  mixins: [DebounceMixin],
  components: {
    UpdateProductSetInfos,
    AddProductModal,
    ProductSetActivitiesList,
    PercentageField,
    SimpleDataTable
  }
})
export default class ProductSetUpsertModal extends Vue {
  @Prop({ default: false }) show!: boolean
  // This prop will be used as the structure of the product set based on a list of product set info keys
  @Prop() categoryWithProductSetFields?: CategoryWithProductSetInfoKeysStructure | null
  @Prop({ required: true }) readonly allCategories!: ProductPackageCategory[] | undefined
  @Prop({ required: true }) readonly categoriesCmsContent: Category[] | undefined
  // This field will be used if we have to update a product set
  @Prop() productSetToUpdate?: ProductPackageSet | null
  @Prop() isDefault?: boolean | null
  @Prop() isNoProductNeeded?: boolean | null
  @Prop() actionType?: UpsertActionType | null
  @Prop() combinationProductSetActivities?: CombinationProductSetActivityFragment[]
  @Prop({ required: true, default: false }) readonly isMasterProductPackage!: boolean
  @Prop({ required: true, default: false }) readonly isPriceVariantsVisible!: boolean
  @Prop({ required: true, default: false }) readonly combinationType!: ProductCombinationType

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() upsertProductSetForm?: any

  // Structure to save
  mProductSetName = ''
  mProductSetInfoKeys: ProductSetInfoKeyModel[] = []
  mProductSetProducts: Product[] = []
  mProductSetBrandlineId = ''
  mPrimaryArticleCode = ''
  mActivities: AssignedProductSetActivityFragment[] = []
  mCosts = [
    {
      costType: CostType.WInstallationCosts,
      cost: { amount: 0 },
      isStandard: true
    },
    {
      costType: CostType.EInstallationCosts,
      cost: { amount: 0 },
      isStandard: true
    },
    {
      costType: CostType.CvInstallationCosts,
      cost: { amount: 0 },
      isStandard: true
    },
    {
      costType: CostType.InstallationConstructionCosts,
      cost: { amount: 0 },
      isStandard: true
    },
    {
      costType: CostType.TilerInstallationCosts,
      cost: { amount: 0 },
      isStandard: true
    },
    {
      costType: CostType.WInstallationCosts,
      cost: { amount: 0 },
      isStandard: false
    },
    {
      costType: CostType.EInstallationCosts,
      cost: { amount: 0 },
      isStandard: false
    },
    {
      costType: CostType.CvInstallationCosts,
      cost: { amount: 0 },
      isStandard: false
    },
    {
      costType: CostType.InstallationConstructionCosts,
      cost: { amount: 0 },
      isStandard: false
    },
    {
      costType: CostType.TilerInstallationCosts,
      cost: { amount: 0 },
      isStandard: false
    }
  ] as ProductSetCost[]

  // new product code to add to the product list
  hasNoProducts = false
  isFetchingProducts = false
  productCode: string | null = null
  newProductCode = ''
  availableProducts: Array<{
    value: string
    text?: string | null
    costPrice: Money
    packagingUnits: string
    priceType: ProjectActivityTarget
  }> = []
  searchProducts: ((value: string) => void) | null = null

  showNewTileModal = false
  isLoading = false
  ProductSetInfoKeyType = ProductSetInfoKeyType
  ProductCombinationType = ProductCombinationType
  rules = {
    required: (value: string) =>
      !!value?.toString()?.length || this.$t('productPackage.edit.category.productSets.upsert.validations.required'),
    numeric: (value: string) => {
      return (
        (Number.isInteger(Number(value)) && Number(value) >= 0) ||
        this.$t('productPackage.edit.category.productSets.upsert.validations.numeric')
      )
    },
    money: (value: string) => {
      // Can contain numbers, commas and dots
      const regex = /^[0-9]{1,9}([,.][0-9]{1,2})?$/
      return regex.test(value) || this.$t('productPackage.edit.category.productSets.upsert.validations.money')
    },
    maxsize: (value: string) =>
      value?.toString()?.length <= 150 || this.$t('productPackage.edit.category.productSets.upsert.validations.maxsize')
  }

  selectInputPriceVariantValue: string | undefined = ''
  mPriceVariantsData: AssignedPriceVariantFragment[] = []
  selectInputCategoryValue: string | undefined = ''

  get categoryName() {
    return this.categoryWithProductSetFields?.name
  }

  get standardCosts() {
    return this.mCosts.filter((x) => x.isStandard === true)
  }

  get nonStandardCosts() {
    return this.mCosts.filter((x) => x.isStandard === false)
  }

  get productsRequiredValidation() {
    return this.hasNoProducts ? true : !!this.mProductSetProducts?.length
  }

  get selectInputPriceVariantValues() {
    return [
      { text: PriceVariant.Comfort, value: PriceVariant.Comfort },
      { text: PriceVariant.Premium, value: PriceVariant.Premium },
      { text: PriceVariant.Excellent, value: PriceVariant.Excellent }
    ]
  }

  get selectInputPriceVariantLabel() {
    return this.$t('productPackage.edit.category.productSets.upsert.fields.priceVariants.label')
  }

  get selectInputCategoryValues(): {
    text: string | number | object
    value: string | number | object
    disabled?: boolean
    divider?: boolean
    header?: string
  }[] {
    if (this.categoryWithProductSetFields?.combination == ProductCombinationType.Tiles) {
      const toSort = this.allCategories
        ?.filter(
          (cat) =>
            !cat.productCategory?.isHidden &&
            (cat.productCategory?.id === this.categoryWithProductSetFields?.categoryId ||
              cat.productCategory?.loadProductSetsFromCategoryId === this.categoryWithProductSetFields?.categoryId ||
              cat.productCategory?.loadProductSetsFromAdditionalCategoryId ===
                this.categoryWithProductSetFields?.categoryId)
        )
        .slice()

      if (toSort?.length == 1) {
        this.selectInputCategoryValue = this.categoryWithProductSetFields?.categoryId
      }
      return (
        toSort
          ?.sort((st1, st2): number => {
            if ((st1.productCategory?.position ?? 10) < (st2.productCategory?.position ?? 10)) return -1
            if ((st1.productCategory?.position ?? 10) > (st2.productCategory?.position ?? 10)) return 1
            return 0
          })
          ?.map((cat) => ({
            text: this.getCategoryNameCMS(cat.productCategory?.id),
            value: cat.productCategory?.id
          })) ?? []
      )
    } else {
      this.selectInputCategoryValue = this.categoryWithProductSetFields?.categoryId
      return [
        {
          text: this.categoryCmsContent?.name ?? '',
          value: this.categoryWithProductSetFields?.categoryId ?? ''
        }
      ]
    }
  }

  get selectInputCategoryLabel() {
    return this.$t('productPackage.edit.category.productSets.upsert.fields.priceVariants.categoryLabel')
  }

  get priceVariantsHeaders(): TableColumn[] {
    return [
      {
        key: 'priceVariant',
        label: this.$t('productPackage.edit.category.productSets.upsert.priceVariantsListTable.priceVariant').toString()
      },
      {
        key: 'productCategory.name',
        label: this.$t('productPackage.edit.category.productSets.upsert.priceVariantsListTable.categoryName').toString()
      }
    ]
  }

  get categoryCmsContent(): Category | undefined {
    return this.categoriesCmsContent?.find((cat) => cat.alias?.alias === this.categoryWithProductSetFields?.categoryId)
  }

  @Watch('show')
  onshowUpdate() {
    this.setProductSetModal()
  }

  @Watch('productSetToUpdate')
  onProductSetToUpdate() {
    this.setProductSetModal()
  }

  @Watch('newProductCode')
  onNewProductCodeUpdate() {
    if (this.searchProducts) this.searchProducts(this.newProductCode)
  }

  onHasNoProductsUpdate() {
    if (this.hasNoProducts) this.mProductSetProducts = []
  }

  mounted() {
    this.searchProducts = this.debounce((value: string) => {
      if (!value) return

      this.isFetchingProducts = true
      this.$apollo
        .query<ProductInfoQuery, ProductInfoQueryVariables>({
          query: ProductInfoDocument,
          variables: {
            code: this.newProductCode
          }
        })
        .then((result) => {
          this.availableProducts = result.data.productInfo
            ? [
                {
                  text: result.data.productInfo.description,
                  value: result.data.productInfo.code,
                  costPrice: result.data.productInfo.costPrice as Money,
                  packagingUnits: result.data.productInfo.packagingUnits,
                  priceType: result.data.productInfo.priceType as ProjectActivityTarget
                }
              ]
            : []
        })
        .catch(() => {
          this.availableProducts = []
        })
        .finally(() => {
          this.isFetchingProducts = false
        })
    }, debounceDelay)
  }

  setProductSetModal() {
    this.mProductSetName = this.productSetToUpdate?.productSet.name ?? ''
    this.mProductSetBrandlineId = this.productSetToUpdate?.productSet.brandline?.id
    this.mProductSetProducts =
      this.productSetToUpdate?.productSet.assignedProductSetProducts.map((x) => x.product) ?? []
    this.mPrimaryArticleCode =
      this.productSetToUpdate?.productSet?.assignedProductSetProducts?.find((x) => !!x.isPrimary)?.product?.code ?? ''
    this.hasNoProducts =
      (this.productSetToUpdate && !this.productSetToUpdate?.productSet.assignedProductSetProducts.length) ?? false
    this.mActivities = [...(this.productSetToUpdate?.productSet?.assignedProductSetActivities ?? [])]

    // Map the costs from the product set to update to the cost structure, or set the price to 0
    this.mCosts = this.mCosts.map(
      (x) =>
        ({
          ...x,
          cost: {
            amount:
              this.productSetToUpdate?.productSet.assignedCosts
                .find((y) => y?.costType === x.costType && y.isStandard === x.isStandard)
                ?.cost.amount?.toString() ?? '0'
          }
        } as ProductSetCost)
    )

    // Set the values of the product set info keys to the structure of the fields.
    this.mProductSetInfoKeys =
      this.categoryWithProductSetFields?.productSetInfoKeys?.map((infoKeyStructure) => {
        const existingInfoKey = this.productSetToUpdate?.productSet.assignedProductSetInfos?.find(
          (assignedProductSetInfo) => assignedProductSetInfo.productSetInfoKeyId === infoKeyStructure.id
        )
        let value = existingInfoKey?.value

        // Exception for BASIC_INCLUDED. Rik wants this to be set to 'true' on default (of course only on the create, not the update).
        if (infoKeyStructure?.key === ProductSetInfoKeyType.BasicIncluded && !this.productSetToUpdate) value = 'true'

        return {
          ...infoKeyStructure,
          value
        } as ProductSetInfoKeyModel
      }) ?? []

    this.mPriceVariantsData =
      this.productSetToUpdate?.assignedPriceVariants?.map(
        (priceVariant): AssignedPriceVariantFragment => ({
          id: priceVariant?.id ?? '',
          priceVariant: priceVariant?.priceVariant || PriceVariant.Comfort,
          productCategory: {
            id: priceVariant?.productCategory.id,
            name: this.getCategoryNameCMS(priceVariant?.productCategory.id)
          }
        })
      ) ?? []
  }

  getProductSetMutationData(): CreateProductSetMutationVariables {
    const productCodes =
      this.mProductSetProducts?.reduce((list: TupleOfStringAndBooleanInput[], current) => {
        const isPrimary = current.code === this.mPrimaryArticleCode
        // This checks if there is already a product in the list with the same code and is already marked as primary
        const hasDuplicatePrimaryInList = list.some((x) => x.item1 === current.code && !!x.item2)

        const article = {
          item1: current.code,
          item2: isPrimary && !hasDuplicatePrimaryInList
        }
        return [...list, article]
      }, []) ?? []

    return {
      name: this.mProductSetName,
      isDefault: this.isMasterProductPackage,
      productCodes,
      productPackageCategoryId: this.categoryWithProductSetFields?.id,
      productSetBrandlineId: this.mProductSetBrandlineId,
      productSetInfoValues:
        this.mProductSetInfoKeys?.map((x) => ({
          item1: x.id,
          item2: x.value?.toString() ?? ''
        })) ?? [],
      costDetails: this.mCosts.map(
        (x) =>
          ({
            item1: x.costType,
            item2: x.cost.amount,
            item3: x.isStandard
          } as TupleOfCostTypeAndStringAndBooleanInput)
      ),
      activities: this.mActivities.map((x) => ({
        item1: x.activity?.id ?? '',
        item2: x.isForStandardProductSet
      })),
      priceVariants: this.mPriceVariantsData.map(
        (item) =>
          ({
            item1: item.productCategory.id,
            item2: item.priceVariant
          } as TupleOfGuidAndPriceVariantInput)
      )
    }
  }

  getPriceTypes(inputString: string) {
    const priceType = this.$t(`productPackage.edit.category.productSets.upsert.priceTypes.${inputString}`).toString()
    return priceType
  }

  getCategoryNameCMS(categoryId: string): string {
    return this.categoriesCmsContent?.find((cat) => cat.alias?.alias === categoryId)?.name ?? ''
  }

  addProduct() {
    if (!this.availableProducts?.length) return

    this.mProductSetProducts.push({
      code: this.availableProducts?.[0]?.value,
      description: this.availableProducts?.[0]?.text,
      costPrice: this.availableProducts?.[0]?.costPrice as Money,
      packagingUnits: this.availableProducts?.[0]?.packagingUnits,
      priceType: this.availableProducts?.[0]?.priceType as ProjectActivityTarget
    } as Product)

    // reset the field
    this.$nextTick(() => {
      this.newProductCode = ''
      this.productCode = null
      this.availableProducts = []
    })
  }

  addCustomProduct(product: {
    code: string
    description: string
    costPrice: Money
    priceType: ProjectActivityTarget
  }) {
    this.mProductSetProducts.push({
      code: product.code,
      description: product.description,
      costPrice: product.costPrice,
      priceType: product.priceType
    } as Product)
  }

  removeProduct(index: number) {
    this.mProductSetProducts.splice(index, 1)
  }

  removeActivity(assignedProductSetActivity: AssignedProductSetActivityFragment, isForStandardProductSet: boolean) {
    this.mActivities = this.mActivities.filter(
      (x) =>
        !(
          x.activity?.id === assignedProductSetActivity.activity?.id &&
          x.isForStandardProductSet === isForStandardProductSet
        )
    )
  }

  addActivity(activity: CombinationProductSetActivityFragment, isForStandardProductSet: boolean) {
    if (
      !this.mActivities.some(
        (x) => x.activity?.id === activity?.id && x.isForStandardProductSet === isForStandardProductSet
      )
    ) {
      this.mActivities.push({
        id: '',
        activity,
        isForStandardProductSet
      })
    }
  }

  addPriceVariant() {
    this.mPriceVariantsData.push({
      priceVariant: this.selectInputPriceVariantValue,
      productCategory: {
        id: this.selectInputCategoryValue,
        name: this.selectInputCategoryValues.find((item) => item.value === this.selectInputCategoryValue)?.text || ''
      }
    } as AssignedPriceVariantFragment)

    // reset the field
    this.$nextTick(() => {
      this.selectInputPriceVariantValue = undefined
      this.selectInputCategoryValues.length > 1 ? (this.selectInputCategoryValue = undefined) : true
    })
  }

  removePriceVariant(priceVariantItem: AssignedPriceVariantFragment) {
    this.mPriceVariantsData = this.mPriceVariantsData.filter(
      (x) =>
        !(
          x.id === priceVariantItem.id &&
          x.priceVariant === priceVariantItem.priceVariant &&
          x.productCategory.id === priceVariantItem.productCategory.id
        )
    )
  }

  async upsertProductSet() {
    if (!this.upsertProductSetForm?.validate()) return

    const requestData = this.getProductSetMutationData()

    this.isLoading = true
    try {
      if (this.actionType === UpsertActionType.UPDATE) {
        await this.updateExistingProductSet(requestData)
      }

      if (this.actionType === UpsertActionType.SAVE) {
        await this.saveNewProductSet(requestData)
      }

      this.close(true)
    } catch {
      this.close()
    } finally {
      this.isLoading = false
    }
  }

  async saveNewProductSet(requestData: CreateProductSetMutationVariables) {
    await this.$apolloMutate<CreateProductSetMutation, CreateProductSetMutationVariables>({
      mutation: CreateProductSetDocument,
      variables: requestData,
      error: 'E4169'
    })
  }

  async updateExistingProductSet(requestData: CreateProductSetMutationVariables) {
    await this.$apolloMutate<UpdateProductSetMutation, UpdateProductSetMutationVariables>({
      mutation: UpdateProductSetDocument,
      variables: {
        id: this.productSetToUpdate?.productSet.id,
        ...requestData
      },
      error: 'E4170'
    })
  }

  close(value?: boolean) {
    this.selectInputPriceVariantValue = undefined
    if (value) {
      this.$emit('close', value)
    } else {
      this.$emit('close')
    }
  }
}
