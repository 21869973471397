/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  Long: any;
  URL: any;
  UUID: any;
};

export type AdUserDto = {
  __typename?: 'ADUserDto';
  displayName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Represents a About page. */
export type AboutPage = ContentItem & {
  __typename?: 'AboutPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** HtmlField */
  htmlBody?: Maybe<HtmlField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  teaserSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type AboutPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the AboutPage content item filters */
export type AboutPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<AboutPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<AboutPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<AboutPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents an activity template with activity template activities. */
export type ActivityTemplate = {
  __typename?: 'ActivityTemplate';
  /** Gets the costs per project activity. */
  activityTemplateActivities: Array<ActivityTemplateActivity>;
  activityTemplates: Array<ActivityTemplateActivity>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of the activity template. */
  name: Scalars['String'];
};


/** Represents an activity template with activity template activities. */
export type ActivityTemplateActivityTemplateActivitiesArgs = {
  order?: InputMaybe<Array<ActivityTemplateActivitySortInput>>;
  where?: InputMaybe<ActivityTemplateActivityFilterInput>;
};


/** Represents an activity template with activity template activities. */
export type ActivityTemplateActivityTemplatesArgs = {
  order?: InputMaybe<Array<ActivityTemplateActivitySortInput>>;
  where?: InputMaybe<ActivityTemplateActivityFilterInput>;
};

/** Represents an activity assigned to a activity template with installations costs. */
export type ActivityTemplateActivity = {
  __typename?: 'ActivityTemplateActivity';
  /** Gets the activity. */
  activity: ProjectActivity;
  /** Gets the collections of costs. */
  activityCosts: Array<ActivityTemplateActivityCost>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
};

/** Represents a cost of an activity in a activity template. */
export type ActivityTemplateActivityCost = {
  __typename?: 'ActivityTemplateActivityCost';
  /** Gets the type of cost. */
  costType: CostType;
  /** Gets the cost value. */
  costValue?: Maybe<Money>;
};

/** Represents a cost of an activity in a activity template. */
export type ActivityTemplateActivityCostFilterInput = {
  /** Gets the activity template activity. */
  activityTemplateActivity?: InputMaybe<ActivityTemplateActivityFilterInput>;
  /** Gets the activity template activity identifier. */
  activityTemplateActivityId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ActivityTemplateActivityCostFilterInput>>;
  /** Gets the type of cost. */
  costType?: InputMaybe<CostTypeOperationFilterInput>;
  /** Gets the cost value. */
  costValue?: InputMaybe<MoneyFilterInput>;
  or?: InputMaybe<Array<ActivityTemplateActivityCostFilterInput>>;
};

/** Represents an activity assigned to a activity template with installations costs. */
export type ActivityTemplateActivityFilterInput = {
  /** Gets the activity. */
  activity?: InputMaybe<ProjectActivityFilterInput>;
  /** Gets the collections of costs. */
  activityCosts?: InputMaybe<ListFilterInputTypeOfActivityTemplateActivityCostFilterInput>;
  /** Gets the ActivityTemplate. */
  activityTemplate?: InputMaybe<ActivityTemplateFilterInput>;
  /** Gets the ActivityTemplate identifier. */
  activityTemplateId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ActivityTemplateActivityFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ActivityTemplateActivityFilterInput>>;
};

/** Represents an activity assigned to a activity template with installations costs. */
export type ActivityTemplateActivitySortInput = {
  /** Gets the activity. */
  activity?: InputMaybe<ProjectActivitySortInput>;
  /** Gets the ActivityTemplate. */
  activityTemplate?: InputMaybe<ActivityTemplateSortInput>;
  /** Gets the ActivityTemplate identifier. */
  activityTemplateId?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
};

/** Represents an activity template with activity template activities. */
export type ActivityTemplateFilterInput = {
  /** Gets the costs per project activity. */
  activityTemplateActivities?: InputMaybe<ListFilterInputTypeOfActivityTemplateActivityFilterInput>;
  and?: InputMaybe<Array<ActivityTemplateFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name of the activity template. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ActivityTemplateFilterInput>>;
};

/** Represents an activity template with activity template activities. */
export type ActivityTemplateSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of the activity template. */
  name?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ActivityTemplatesCollectionSegment = {
  __typename?: 'ActivityTemplatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ActivityTemplate>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Properties for adding a new default product set. */
export type AddDefaultProductSetDtoInput = {
  /** Gets or sets the id of the category where this product will be a default. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets the id of the product set. */
  productSetId: Scalars['UUID'];
  /** Gets or sets a value indicating whether this default should be stored as project default. */
  setAsProjectDefault: Scalars['Boolean'];
  /** Gets or sets the id of the design package wall. */
  wallId?: InputMaybe<Scalars['UUID']>;
};

/** Properties for adding a new pre-selected product set. */
export type AddPreselectedProductSetDtoInput = {
  /** Gets or sets the id of the category where this product will be a default. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets the id of the product set. */
  productSetId: Scalars['UUID'];
  /** Gets or sets the id of the design package wall. */
  wallId?: InputMaybe<Scalars['UUID']>;
};

/** Alternative path for the content item */
export type AliasPart = {
  __typename?: 'AliasPart';
  alias?: Maybe<Scalars['String']>;
};

/** the alias part of the content item */
export type AliasPartInput = {
  /** the alias of the content item is equal to */
  alias?: InputMaybe<Scalars['String']>;
  /** the alias of the content item contains the string */
  alias_contains?: InputMaybe<Scalars['String']>;
  /** the alias of the content item ends with the string */
  alias_ends_with?: InputMaybe<Scalars['String']>;
  /** the alias of the content item is in collection */
  alias_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the alias of the content item is not equal to */
  alias_not?: InputMaybe<Scalars['String']>;
  /** the alias of the content item does not contain the string */
  alias_not_contains?: InputMaybe<Scalars['String']>;
  /** the alias of the content item does not end with the string */
  alias_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the alias of the content item is not in collection */
  alias_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the alias of the content item does not start with the string */
  alias_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the alias of the content item starts with the string */
  alias_starts_with?: InputMaybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

/** A segment of a collection. */
export type AssignedCategoryProductSetsCollectionSegment = {
  __typename?: 'AssignedCategoryProductSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<ProductPackageSet>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type AssignedProductSetsCollectionSegment = {
  __typename?: 'AssignedProductSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<ProductPackageSet>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Represents a Become Partner Page. */
export type BecomePartnerPage = ContentItem & {
  __typename?: 'BecomePartnerPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Content stored as HTML. */
  htmlBody?: Maybe<HtmlBodyPart>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** Represents a NavigationTitle. */
  navigationTitle?: Maybe<NavigationTitle>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type BecomePartnerPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BecomePartnerPage content item filters */
export type BecomePartnerPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BecomePartnerPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BecomePartnerPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BecomePartnerPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type Block1 = {
  __typename?: 'Block1';
  /** Text field */
  text?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type Block2 = {
  __typename?: 'Block2';
  /** Text field */
  text?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type Block3 = {
  __typename?: 'Block3';
  /** Text field */
  text?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** Brand represents a single brand for decorating rooms. */
export type Brand = {
  __typename?: 'Brand';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of the brand. */
  name: Scalars['String'];
  /** Gets the product package of the brand. */
  productPackage?: Maybe<ProductPackage>;
  /** Gets the identifier of the product package of the brand. */
  productPackageId?: Maybe<Scalars['UUID']>;
  usedInDesignPackages: Array<DesignPackage>;
  usedInProjects: Array<ProjectBrand>;
};


/** Brand represents a single brand for decorating rooms. */
export type BrandUsedInDesignPackagesArgs = {
  where?: InputMaybe<DesignPackageFilterInput>;
};


/** Brand represents a single brand for decorating rooms. */
export type BrandUsedInProjectsArgs = {
  where?: InputMaybe<ProjectBrandFilterInput>;
};

/** Represents a Brand detail. */
export type BrandDetail = ContentItem & {
  __typename?: 'BrandDetail';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** MediaField */
  teaserImage?: Maybe<MediaField>;
  /** Text field */
  teaserText?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type BrandDetailOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BrandDetail content item filters */
export type BrandDetailWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BrandDetailWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BrandDetailWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BrandDetailWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Brand represents a single brand for decorating rooms. */
export type BrandFilterInput = {
  and?: InputMaybe<Array<BrandFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name of the brand. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BrandFilterInput>>;
  /** Gets the product package of the brand. */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets the identifier of the product package of the brand. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the collection of DesignPackage objects that reference this brand. */
  usedInDesignPackages?: InputMaybe<ListFilterInputTypeOfDesignPackageFilterInput>;
  /** Gets the collection of ProjectBrand objects that reference this brand. */
  usedInProjects?: InputMaybe<ListFilterInputTypeOfProjectBrandFilterInput>;
};

/** Represents a Brand Listing Page. */
export type BrandListingPage = ContentItem & {
  __typename?: 'BrandListingPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  description?: Maybe<HtmlField>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  goToBrand?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type BrandListingPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BrandListingPage content item filters */
export type BrandListingPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BrandListingPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BrandListingPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BrandListingPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type BrandOverviewBlock1 = {
  __typename?: 'BrandOverviewBlock1';
  /** HtmlField */
  text?: Maybe<HtmlField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type BrandOverviewBlock2 = {
  __typename?: 'BrandOverviewBlock2';
  /** HtmlField */
  text?: Maybe<HtmlField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

/** Represents a Brand Page. */
export type BrandPage = ContentItem & {
  __typename?: 'BrandPage';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** ContentPickerField */
  brandDetails?: Maybe<ContentPickerField>;
  /** MediaField */
  brandOverViewBanner?: Maybe<MediaField>;
  /** MediaField */
  brandOverViewImages?: Maybe<MediaField>;
  /** Represents a BrandOverviewBlock. */
  brandOverviewBlock1?: Maybe<BrandOverviewBlock1>;
  /** Represents a BrandOverviewBlock. */
  brandOverviewBlock2?: Maybe<BrandOverviewBlock2>;
  /** ContentPickerField */
  brandPartners?: Maybe<ContentPickerField>;
  /** Represents a BrandOverView. */
  brandoverview?: Maybe<Brandoverview>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Numeric field */
  position?: Maybe<Scalars['Decimal']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** MediaField */
  teaserImage?: Maybe<MediaField>;
  /** Text field */
  teaserSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type BrandPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BrandPage content item filters */
export type BrandPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BrandPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BrandPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BrandPageWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Brand Partner. */
export type BrandPartner = ContentItem & {
  __typename?: 'BrandPartner';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** MediaField */
  detailPageBannerImage?: Maybe<MediaField>;
  /** MediaField */
  detailPageContentBlock1Image?: Maybe<MediaField>;
  /** HtmlField */
  detailPageContentBlock1Text?: Maybe<HtmlField>;
  /** MediaField */
  detailPageContentBlock2Images?: Maybe<MediaField>;
  /** HtmlField */
  detailPageContentBlock2Text?: Maybe<HtmlField>;
  /** MediaField */
  detailPagePromotionVideo?: Maybe<MediaField>;
  /** Text field */
  detailPageSubtitle?: Maybe<Scalars['String']>;
  /** HtmlField */
  detailPageSummaryBlockDescription?: Maybe<HtmlField>;
  /** Text field */
  detailPageSummaryBlockTitle?: Maybe<Scalars['String']>;
  /** Text field */
  detailPageTitle?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** MediaField */
  logo?: Maybe<MediaField>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** HtmlField */
  text?: Maybe<HtmlField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type BrandPartnerOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BrandPartner content item filters */
export type BrandPartnerWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BrandPartnerWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BrandPartnerWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BrandPartnerWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Brand represents a single brand for decorating rooms. */
export type BrandSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of the brand. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the product package of the brand. */
  productPackage?: InputMaybe<ProductPackageSortInput>;
  /** Gets the identifier of the product package of the brand. */
  productPackageId?: InputMaybe<SortEnumType>;
};

export type Brandoverview = {
  __typename?: 'Brandoverview';
  /** HtmlField */
  introduction?: Maybe<HtmlField>;
};

/** Represents a Buyers Page. */
export type BuyersPage = ContentItem & {
  __typename?: 'BuyersPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  description?: Maybe<HtmlField>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Content stored as HTML. */
  htmlBody?: Maybe<HtmlBodyPart>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Represents a StepBlock. */
  step1?: Maybe<Step1>;
  /** Represents a StepBlock. */
  step2?: Maybe<Step2>;
  /** Represents a StepBlock. */
  step3?: Maybe<Step3>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type BuyersPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the BuyersPage content item filters */
export type BuyersPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<BuyersPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<BuyersPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<BuyersPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Category. */
export type Category = ContentItem & {
  __typename?: 'Category';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  brandLineHeader?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  explanationText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  name?: Maybe<Scalars['String']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  popupTriggerForRoomLayout?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type CategoryOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the Category content item filters */
export type CategoryWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<CategoryWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<CategoryWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<CategoryWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

/** The confirmed status types for a real estate */
export enum ConfirmedStatusType {
  /** Additional Work */
  AdditionalWork = 'ADDITIONAL_WORK',
  /** Casco */
  Casco = 'CASCO',
  /** Casco+ */
  CascoPlus = 'CASCO_PLUS',
  /** Standard */
  Standard = 'STANDARD',
  /** Standard+ */
  StandardPlus = 'STANDARD_PLUS'
}

/** Represents a Contact Page. */
export type ContactPage = ContentItem & {
  __typename?: 'ContactPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  content?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type ContactPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ContactPage content item filters */
export type ContactPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ContactPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ContactPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ContactPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** A segment of a collection. */
export type ContactRequestMessagesCollectionSegment = {
  __typename?: 'ContactRequestMessagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MessageDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** The valid status for a contact request. */
export enum ContactRequestStatus {
  /** Closed */
  Closed = 'CLOSED',
  /** New */
  New = 'NEW',
  /** Pending */
  Pending = 'PENDING'
}

export type ContactRequestStatusOperationFilterInput = {
  eq?: InputMaybe<ContactRequestStatus>;
  in?: InputMaybe<Array<ContactRequestStatus>>;
  neq?: InputMaybe<ContactRequestStatus>;
  nin?: InputMaybe<Array<ContactRequestStatus>>;
};

/** A segment of a collection. */
export type ContactRequestsAsyncCollectionSegment = {
  __typename?: 'ContactRequestsAsyncCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RequestForContact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Contact type. */
export enum ContactType {
  /** E-mail. */
  Email = 'EMAIL',
  /** No preference. */
  NoPreference = 'NO_PREFERENCE',
  /** Phone. */
  Phone = 'PHONE'
}

export type ContactTypeOperationFilterInput = {
  eq?: InputMaybe<ContactType>;
  in?: InputMaybe<Array<ContactType>>;
  neq?: InputMaybe<ContactType>;
  nin?: InputMaybe<Array<ContactType>>;
};

export type ContentItem = {
  author: Scalars['String'];
  contentItemId: Scalars['String'];
  contentItemVersionId: Scalars['String'];
  contentType: Scalars['String'];
  createdUtc?: Maybe<Scalars['DateTime']>;
  displayText?: Maybe<Scalars['String']>;
  latest: Scalars['Boolean'];
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  owner: Scalars['String'];
  published: Scalars['Boolean'];
  publishedUtc?: Maybe<Scalars['DateTime']>;
};

export type ContentPickerField = {
  __typename?: 'ContentPickerField';
  /** content item ids */
  contentItemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** the content items */
  contentItems?: Maybe<Array<Maybe<ContentItem>>>;
};


export type ContentPickerFieldContentItemIdsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ContentPickerFieldContentItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Represents a cost. */
export type CostEntry = {
  __typename?: 'CostEntry';
  /** Gets or sets the cost value */
  cost: Money;
  /** Gets or sets the costy type. */
  type: CostType;
};

/** Available cost type in badkamer */
export enum CostType {
  /** Commission Costs for the builder */
  CoordinationCommissionBuilderCosts = 'COORDINATION_COMMISSION_BUILDER_COSTS',
  /** Commission Costs */
  CoordinationCommissionCosts = 'COORDINATION_COMMISSION_COSTS',
  /** Commission Costs for the installer */
  CoordinationCommissionInstallerCosts = 'COORDINATION_COMMISSION_INSTALLER_COSTS',
  /** Custom Offer Costs */
  CustomOfferCosts = 'CUSTOM_OFFER_COSTS',
  /** CV-installation costs */
  CvInstallationCosts = 'CV_INSTALLATION_COSTS',
  /** 3D Drawing Costs */
  DrawingCosts = 'DRAWING_COSTS',
  /** E-installation costs */
  EInstallationCosts = 'E_INSTALLATION_COSTS',
  /** For calculations - Tiler cost. */
  ForCalculationTilesInstaller = 'FOR_CALCULATION_TILES_INSTALLER',
  /** For calculations - Tiler cost per meter. */
  ForCalculationTilesInstallerM1 = 'FOR_CALCULATION_TILES_INSTALLER_M1',
  /** For calculations - Tiler cost per square meter. */
  ForCalculationTilesInstallerM2 = 'FOR_CALCULATION_TILES_INSTALLER_M2',
  /** Installation Costs */
  InstallationConstructionCosts = 'INSTALLATION_CONSTRUCTION_COSTS',
  /** CV-installation costs */
  KitterInstallationCosts = 'KITTER_INSTALLATION_COSTS',
  /** Lower Price Products Costs */
  LowerPriceStandardProductsCosts = 'LOWER_PRICE_STANDARD_PRODUCTS_COSTS',
  /** Product Costs */
  ProductsAndInstallationMaterialsCosts = 'PRODUCTS_AND_INSTALLATION_MATERIALS_COSTS',
  /** Tiler installation costs */
  TilerInstallationCosts = 'TILER_INSTALLATION_COSTS',
  /** Tiles Costs */
  TilesAndFastenersCosts = 'TILES_AND_FASTENERS_COSTS',
  /** Total Costs */
  TotalSurchargeCosts = 'TOTAL_SURCHARGE_COSTS',
  /** VAT costs */
  VatCosts = 'VAT_COSTS',
  /** W-installation costs */
  WInstallationCosts = 'W_INSTALLATION_COSTS'
}

export type CostTypeOperationFilterInput = {
  eq?: InputMaybe<CostType>;
  in?: InputMaybe<Array<CostType>>;
  neq?: InputMaybe<CostType>;
  nin?: InputMaybe<Array<CostType>>;
};

/** Available currencies in badkamer according to ISO 4217 standard */
export enum Currency {
  /** EURO */
  Eur = 'EUR'
}

export type CurrencyOperationFilterInput = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  nin?: InputMaybe<Array<Currency>>;
};

/** CustomOffer represents a CustomOffer. */
export type CustomOffer = {
  __typename?: 'CustomOffer';
  /** Gets the costs assigned to this custom offer room. */
  assignedRooms: Array<Maybe<CustomOfferRoom>>;
  /** Gets the creation date of the custom offer. */
  createdAtUtc: Scalars['DateTime'];
  /** Gets or sets the custom offer request of the relation. */
  customOfferRequest: RequestForCustomOffer;
  /** Gets the custom offer request id of the relation. */
  customOfferRequestId: Scalars['UUID'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether the current offer has been shared with the customer. */
  isSharedWithCustomer: Scalars['Boolean'];
  /** Gets the 3d link for the offer. */
  link: Scalars['String'];
  /** Gets the name of the offer. */
  name: Scalars['String'];
  /** Gets the notes of the custom offer. */
  notes?: Maybe<Scalars['String']>;
  /** Gets the custom offer status. */
  status: CustomOfferStatus;
  /**
   * Gets the stringified version.
   *
   *
   * **Returns:**
   * The string representation of the version.
   */
  strVersion: Scalars['String'];
  /** Gets the version of the custom offer. */
  version: Scalars['Int'];
};

/** The type of a custom offer request. */
export enum CustomOfferAppointmentType {
  /** Custom offer by contact. */
  Contact = 'CONTACT',
  /** Custom offer by face-to-face appointment. */
  Meeting = 'MEETING',
  /** Custom offer without costs. */
  NoCosts = 'NO_COSTS'
}

export type CustomOfferAppointmentTypeOperationFilterInput = {
  eq?: InputMaybe<CustomOfferAppointmentType>;
  in?: InputMaybe<Array<CustomOfferAppointmentType>>;
  neq?: InputMaybe<CustomOfferAppointmentType>;
  nin?: InputMaybe<Array<CustomOfferAppointmentType>>;
};

/** Represents a Custom Offer Content. */
export type CustomOfferContent = ContentItem & {
  __typename?: 'CustomOfferContent';
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  blockButtonText?: Maybe<Scalars['String']>;
  /** HtmlField */
  blockDescription?: Maybe<HtmlField>;
  /** HtmlField */
  blockDescriptionRequested?: Maybe<HtmlField>;
  /** HtmlField */
  blockDescriptionWhenCustomOfferIsAvailable?: Maybe<HtmlField>;
  /** Text field */
  blockTitle?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestDialogCancelButton?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestDialogConfirmButton?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestDialogSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestDialogTitle?: Maybe<Scalars['String']>;
  /** Text field */
  confirmRequestButtonText?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** Text field */
  locationPreferenceLabel?: Maybe<Scalars['String']>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  option1Description?: Maybe<HtmlField>;
  /** Text field */
  option1Title?: Maybe<Scalars['String']>;
  /** HtmlField */
  option2Description?: Maybe<HtmlField>;
  /** Text field */
  option2Title?: Maybe<Scalars['String']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  popupSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  popupTitle?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangeButton?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangePopUpSubmitButtonLabel?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangePopUpSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangePopUpTextAreaLabel?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangePopUpTextAreaPlaceholder?: Maybe<Scalars['String']>;
  /** Text field */
  requestChangePopUpTitle?: Maybe<Scalars['String']>;
  /** Text field */
  requestNotesCompleteNewLabel?: Maybe<Scalars['String']>;
  /** Text field */
  requestNotesSomeChangesLabel?: Maybe<Scalars['String']>;
  /** Text field */
  roomSelection?: Maybe<Scalars['String']>;
  /** Text field */
  scheduleAppointmentLabel?: Maybe<Scalars['String']>;
  /** Text field */
  successPopupDescription?: Maybe<Scalars['String']>;
  /** Text field */
  successPopupTitle?: Maybe<Scalars['String']>;
};

export type CustomOfferContentOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the CustomOfferContent content item filters */
export type CustomOfferContentWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<CustomOfferContentWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<CustomOfferContentWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<CustomOfferContentWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Custom offer file types. */
export enum CustomOfferFileType {
  /** When is a drawing file. */
  Drawing = 'DRAWING',
  /** When is the offer file. */
  Offer = 'OFFER'
}

/** CustomOffer represents a CustomOffer. */
export type CustomOfferFilterInput = {
  and?: InputMaybe<Array<CustomOfferFilterInput>>;
  /** Gets the costs assigned to this custom offer room. */
  assignedRooms?: InputMaybe<ListFilterInputTypeOfCustomOfferRoomFilterInput>;
  /** Gets the creation date of the custom offer. */
  createdAtUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets or sets the custom offer request of the relation. */
  customOfferRequest?: InputMaybe<RequestForCustomOfferFilterInput>;
  /** Gets the custom offer request id of the relation. */
  customOfferRequestId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the custom offer has associated files. */
  hasFiles?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the current offer has been shared with the customer. */
  isSharedWithCustomer?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the 3d link for the offer. */
  link?: InputMaybe<StringOperationFilterInput>;
  /** Gets the name of the offer. */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Gets the notes of the custom offer. */
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomOfferFilterInput>>;
  /** Gets the custom offer status. */
  status?: InputMaybe<CustomOfferStatusOperationFilterInput>;
  /**
   * Gets the stringified version.
   *
   *
   * **Returns:**
   * The string representation of the version.
   */
  strVersion?: InputMaybe<StringOperationFilterInput>;
  /** Gets the version of the custom offer. */
  version?: InputMaybe<IntOperationFilterInput>;
};

/** Represent a CustomOfferRequestAppointment. */
export type CustomOfferRequestAppointment = {
  __typename?: 'CustomOfferRequestAppointment';
  /** Gets the date of the custom offer request appointment. */
  appointmentDate: Scalars['DateTime'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the CustomOfferAppointmentType. */
  type: CustomOfferAppointmentType;
};

/** Represent a CustomOfferRequestAppointment. */
export type CustomOfferRequestAppointmentFilterInput = {
  and?: InputMaybe<Array<CustomOfferRequestAppointmentFilterInput>>;
  /** Gets the date of the custom offer request appointment. */
  appointmentDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the custom offer request identifier. */
  customOfferRequestId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomOfferRequestAppointmentFilterInput>>;
  /** Gets the CustomOfferAppointmentType. */
  type?: InputMaybe<CustomOfferAppointmentTypeOperationFilterInput>;
};

/** The valid status for a custom offer request. */
export enum CustomOfferRequestStatus {
  /** Make an appointment. */
  Appointment = 'APPOINTMENT',
  /** Offer cancelled. */
  Cancelled = 'CANCELLED',
  /** Completed custom offer. */
  Completed = 'COMPLETED',
  /** Create an offer */
  CreateOffer = 'CREATE_OFFER',
  /** New request. */
  New = 'NEW',
  /** Request a custom offer change. */
  RequestForChange = 'REQUEST_FOR_CHANGE',
  /** Offer shared with customer. */
  SharedWithCustomer = 'SHARED_WITH_CUSTOMER'
}

export type CustomOfferRequestStatusOperationFilterInput = {
  eq?: InputMaybe<CustomOfferRequestStatus>;
  in?: InputMaybe<Array<CustomOfferRequestStatus>>;
  neq?: InputMaybe<CustomOfferRequestStatus>;
  nin?: InputMaybe<Array<CustomOfferRequestStatus>>;
};

/** A segment of a collection. */
export type CustomOfferRequestsCollectionSegment = {
  __typename?: 'CustomOfferRequestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RequestForCustomOffer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** CustomOfferRoom represents the costs of using a CustomOfferRoom. */
export type CustomOfferRoom = {
  __typename?: 'CustomOfferRoom';
  /** Gets the costs assigned to this custom offer room. */
  assignedCosts: Array<Maybe<CustomOfferRoomCost>>;
  /** Gets or sets the custom offer of the relation. */
  customOffer: CustomOffer;
  /** Gets the custom offer id of the relation. */
  customOfferId: Scalars['UUID'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the room of the relation. */
  room?: Maybe<Room>;
  /** Gets the room id of the relation. */
  roomId?: Maybe<Scalars['UUID']>;
};


/** CustomOfferRoom represents the costs of using a CustomOfferRoom. */
export type CustomOfferRoomAssignedCostsArgs = {
  where?: InputMaybe<CustomOfferRoomCostFilterInput>;
};

/** CustomOfferRoomCost represents the costs of using a CustomOfferRoomCost. */
export type CustomOfferRoomCost = {
  __typename?: 'CustomOfferRoomCost';
  /** Gets the cost for the Room for a specific CostType for a RequestForCustomOffer. */
  cost: Money;
  /** Gets the cost type of this value for a custom offer request room. */
  costType: CostType;
  /** Gets the custom offer room id of the relation. */
  customOfferRoomId: Scalars['UUID'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
};

/** CustomOfferRoomCost represents the costs of using a CustomOfferRoomCost. */
export type CustomOfferRoomCostFilterInput = {
  and?: InputMaybe<Array<CustomOfferRoomCostFilterInput>>;
  /** Gets the cost for the Room for a specific CostType for a RequestForCustomOffer. */
  cost?: InputMaybe<MoneyFilterInput>;
  /** Gets the cost type of this value for a custom offer request room. */
  costType?: InputMaybe<CostTypeOperationFilterInput>;
  /** Gets or sets the custom offer room of the relation. */
  customOfferRoom?: InputMaybe<CustomOfferRoomFilterInput>;
  /** Gets the custom offer room id of the relation. */
  customOfferRoomId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomOfferRoomCostFilterInput>>;
};

/** Contains information about a custom offer room. */
export type CustomOfferRoomDtoInput = {
  /** Gets or sets the costs for a custom offer room. */
  costs: Array<KeyValuePairOfCostTypeAndStringInput>;
  /** Gets or sets the room id. */
  roomId?: InputMaybe<Scalars['UUID']>;
};

/** CustomOfferRoom represents the costs of using a CustomOfferRoom. */
export type CustomOfferRoomFilterInput = {
  and?: InputMaybe<Array<CustomOfferRoomFilterInput>>;
  /** Gets the costs assigned to this custom offer room. */
  assignedCosts?: InputMaybe<ListFilterInputTypeOfCustomOfferRoomCostFilterInput>;
  /** Gets or sets the custom offer of the relation. */
  customOffer?: InputMaybe<CustomOfferFilterInput>;
  /** Gets the custom offer id of the relation. */
  customOfferId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomOfferRoomFilterInput>>;
  /** Gets or sets the room of the relation. */
  room?: InputMaybe<RoomFilterInput>;
  /** Gets the room id of the relation. */
  roomId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the room selection identifier. */
  roomSelectionId?: InputMaybe<UuidOperationFilterInput>;
};

/** Custom Offer Status. */
export enum CustomOfferStatus {
  /** Active status. */
  Active = 'ACTIVE',
  /** Deleted status. */
  Deleted = 'DELETED',
  /** Unshared status. */
  Unshared = 'UNSHARED'
}

export type CustomOfferStatusOperationFilterInput = {
  eq?: InputMaybe<CustomOfferStatus>;
  in?: InputMaybe<Array<CustomOfferStatus>>;
  neq?: InputMaybe<CustomOfferStatus>;
  nin?: InputMaybe<Array<CustomOfferStatus>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackage = {
  __typename?: 'DesignPackage';
  /** Gets the costs assigned to this design package. */
  assignedCosts?: Maybe<Array<Maybe<DesignPackageCost>>>;
  /** Gets the default product sets assigned to this design package. */
  assignedDefaultProducts?: Maybe<Array<Maybe<DesignPackageDefaultProductSet>>>;
  /** Gets the pre-selected product sets assigned to this design package. */
  assignedPreselectedProductSets?: Maybe<Array<Maybe<DesignPackagePreselectedProductSet>>>;
  /** Gets the product packages assigned restrictions to this project. */
  assignedRestrictions?: Maybe<Array<Maybe<DesignPackageProductSetRestriction>>>;
  /** Gets the walls assigned to this design package. */
  assignedWalls?: Maybe<Array<Maybe<DesignPackageWall>>>;
  availableProductCategories?: Maybe<Array<Maybe<ProductPackageCategory>>>;
  availableProductCombinations?: Maybe<Array<Maybe<ProductCombination>>>;
  availableProductSets?: Maybe<Array<Maybe<ProductPackageSet>>>;
  /** Gets Brand for the Design Package */
  brand?: Maybe<Brand>;
  /** Gets a value indicating whether the Design Package has a bath label. */
  hasBath: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a bathtub. */
  hasBathtub: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a fountain. */
  hasFountain: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a radiator. */
  hasRadiator: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a shower. */
  hasShower: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a tiles. */
  hasTiles: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a toilet. */
  hasToilet: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a toilet label. */
  hasToiletLabel: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a utilities. */
  hasUtilities: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has a washbasin. */
  hasWashbasin: Scalars['Boolean'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /**
   * Gets a value indicating whether the Design Package involves a fixed price,
   * or that the price is determines based on the smallest possible set of dimensions for similar rooms in the same project.
   */
  isFixedPrice: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package is a Standard one. */
  isStandard: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package has tiles selected to ceiling. */
  isTilesToCeiling: Scalars['Boolean'];
  /** Gets a value indicating whether the Design Package involves increasing the room size, i.e. XL label. */
  isXL: Scalars['Boolean'];
  /** Gets Name for the Design Package */
  name: Scalars['String'];
  overview?: Maybe<OverviewDto>;
  /** Gets the Position for the Design Package */
  position: Scalars['Int'];
  /** Gets Price for the Design Package, based on smallest possible set of dimensions for this room. */
  price: Money;
  /** Gets price variant for the Design Package */
  priceVariant?: Maybe<PriceVariant>;
  /**
   * Gets the ProductCombinationType assigned for the DesignPackage.
   *
   *
   * **Returns:**
   * The list of ProductCombinationType assigned for this DesignPackage.
   */
  productCombinationTypes: Array<ProductCombinationType>;
  /** Gets product package for the Design Package */
  productPackage?: Maybe<ProductPackage>;
  /** Gets the Room for the Design Package */
  room: Room;
  selectedProductSets: Array<Maybe<RequestForQuoteRoomSelectionItemDto>>;
  /** Gets Style for the Design Package */
  style?: Maybe<Style>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageAssignedRestrictionsArgs = {
  order?: InputMaybe<Array<DesignPackageProductSetRestrictionSortInput>>;
  where?: InputMaybe<DesignPackageProductSetRestrictionFilterInput>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageAssignedWallsArgs = {
  order?: InputMaybe<Array<DesignPackageWallSortInput>>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageAvailableProductCategoriesArgs = {
  combinationType?: InputMaybe<ProductCombinationType>;
  order?: InputMaybe<Array<ProductPackageCategorySortInput>>;
  selectionName?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductPackageCategoryFilterInput>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageAvailableProductCombinationsArgs = {
  combinationType?: InputMaybe<ProductCombinationType>;
  order?: InputMaybe<Array<ProductCombinationSortInput>>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageAvailableProductSetsArgs = {
  builtInOption?: InputMaybe<Scalars['Boolean']>;
  combinationType?: InputMaybe<ProductCombinationType>;
  order?: InputMaybe<Array<ProductPackageSetSortInput>>;
  productCategoryId?: InputMaybe<Scalars['UUID']>;
  selectedProductSets?: InputMaybe<Array<InputMaybe<SelectedProductSetDtoInput>>>;
  wallId?: InputMaybe<Scalars['UUID']>;
  where?: InputMaybe<ProductPackageSetFilterInput>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageOverviewArgs = {
  selectionName?: InputMaybe<Scalars['String']>;
};


/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageSelectedProductSetsArgs = {
  selectionName?: InputMaybe<Scalars['String']>;
};

/** DesignPackageCost represents the costs of using a DesignPackage. */
export type DesignPackageCost = {
  __typename?: 'DesignPackageCost';
  /** Gets the cost for the DesignPackage for a specific CostType. */
  cost: Money;
  /** Gets the cost type of this value for a design package. */
  costType: CostType;
  /** Gets the design package id of the relation. */
  designPackageId: Scalars['UUID'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
};

/** DesignPackageCost represents the costs of using a DesignPackage. */
export type DesignPackageCostFilterInput = {
  and?: InputMaybe<Array<DesignPackageCostFilterInput>>;
  /** Gets the cost for the DesignPackage for a specific CostType. */
  cost?: InputMaybe<MoneyFilterInput>;
  /** Gets the cost type of this value for a design package. */
  costType?: InputMaybe<CostTypeOperationFilterInput>;
  /** Gets or sets the design package of the relation. */
  designPackage?: InputMaybe<DesignPackageFilterInput>;
  /** Gets the design package id of the relation. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageCostFilterInput>>;
};

/** DesignPackageDefaultProductSet represents a default ProductSet for a ProductCategory in a DesignPackage. */
export type DesignPackageDefaultProductSet = {
  __typename?: 'DesignPackageDefaultProductSet';
  /** Gets or sets the wall of the relation. */
  designPackageWall?: Maybe<DesignPackageWall>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the product category of the relation. */
  productCategory?: Maybe<ProductCategory>;
  /** Gets or sets the product set of the relation. */
  productSet?: Maybe<ProductSet>;
};

/** DesignPackageDefaultProductSet represents a default ProductSet for a ProductCategory in a DesignPackage. */
export type DesignPackageDefaultProductSetFilterInput = {
  and?: InputMaybe<Array<DesignPackageDefaultProductSetFilterInput>>;
  /** Gets or sets the design package of the relation. */
  designPackage?: InputMaybe<DesignPackageFilterInput>;
  /** Gets the design package id of the relation. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the wall of the relation. */
  designPackageWall?: InputMaybe<DesignPackageWallFilterInput>;
  /** Gets the wall id of the relation. */
  designPackageWallId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageDefaultProductSetFilterInput>>;
  /** Gets or sets the product category of the relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product set of the relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the product set id of the relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
};

/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageFilterInput = {
  and?: InputMaybe<Array<DesignPackageFilterInput>>;
  /** Gets the costs assigned to this design package. */
  assignedCosts?: InputMaybe<ListFilterInputTypeOfDesignPackageCostFilterInput>;
  /** Gets the default product sets assigned to this design package. */
  assignedDefaultProducts?: InputMaybe<ListFilterInputTypeOfDesignPackageDefaultProductSetFilterInput>;
  /** Gets the pre-selected product sets assigned to this design package. */
  assignedPreselectedProductSets?: InputMaybe<ListFilterInputTypeOfDesignPackagePreselectedProductSetFilterInput>;
  /** Gets the product packages assigned restrictions to this project. */
  assignedRestrictions?: InputMaybe<ListFilterInputTypeOfDesignPackageProductSetRestrictionFilterInput>;
  /** Gets the walls assigned to this design package. */
  assignedWalls?: InputMaybe<ListFilterInputTypeOfDesignPackageWallFilterInput>;
  /** Gets Brand for the Design Package */
  brand?: InputMaybe<BrandFilterInput>;
  /** Gets the brand id of the relation. */
  brandId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a bath label. */
  hasBath?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a bathtub. */
  hasBathtub?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a fountain. */
  hasFountain?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a radiator. */
  hasRadiator?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a shower. */
  hasShower?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a tiles. */
  hasTiles?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a toilet. */
  hasToilet?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a toilet label. */
  hasToiletLabel?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a utilities. */
  hasUtilities?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has a washbasin. */
  hasWashbasin?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Gets a value indicating whether the Design Package involves a fixed price,
   * or that the price is determines based on the smallest possible set of dimensions for similar rooms in the same project.
   */
  isFixedPrice?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package is a Standard one. */
  isStandard?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package has tiles selected to ceiling. */
  isTilesToCeiling?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the Design Package involves increasing the room size, i.e. XL label. */
  isXL?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets Name for the Design Package */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageFilterInput>>;
  /** Gets the Position for the Design Package */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets Price for the Design Package, based on smallest possible set of dimensions for this room. */
  price?: InputMaybe<MoneyFilterInput>;
  /** Gets price variant for the Design Package */
  priceVariant?: InputMaybe<NullableOfPriceVariantOperationFilterInput>;
  /** Gets product package for the Design Package */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets the product package id of the relation. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the Room for the Design Package */
  room?: InputMaybe<RoomFilterInput>;
  /** Gets Style for the Design Package */
  style?: InputMaybe<StyleFilterInput>;
  /** Gets the style id of the relation. */
  styleId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a Design package page. */
export type DesignPackagePage = ContentItem & {
  __typename?: 'DesignPackagePage';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** MediaField */
  bannerImages?: Maybe<MediaField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  description?: Maybe<HtmlField>;
  /** Text field */
  designPackagePopupSummary?: Maybe<Scalars['String']>;
  /** Text field */
  displayName?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  information?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** MediaField */
  layoutFile?: Maybe<MediaField>;
  /** MediaField */
  layoutImage?: Maybe<MediaField>;
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** MediaField */
  photos?: Maybe<MediaField>;
  /** MediaField */
  productsListPDF?: Maybe<MediaField>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  summary?: Maybe<Scalars['String']>;
  /** MediaField */
  teaserImage?: Maybe<MediaField>;
};

export type DesignPackagePageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the DesignPackagePage content item filters */
export type DesignPackagePageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<DesignPackagePageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<DesignPackagePageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<DesignPackagePageWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** DesignPackagePreselectedProductSet represents a pre-selected ProductSet for a ProductCategory in a DesignPackage. */
export type DesignPackagePreselectedProductSet = {
  __typename?: 'DesignPackagePreselectedProductSet';
  /** Gets or sets the wall of the relation. */
  designPackageWall?: Maybe<DesignPackageWall>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the product category of the relation. */
  productCategory?: Maybe<ProductCategory>;
  /** Gets or sets the product set of the relation. */
  productSet?: Maybe<ProductSet>;
};

/** DesignPackagePreselectedProductSet represents a pre-selected ProductSet for a ProductCategory in a DesignPackage. */
export type DesignPackagePreselectedProductSetFilterInput = {
  and?: InputMaybe<Array<DesignPackagePreselectedProductSetFilterInput>>;
  /** Gets or sets the design package of the relation. */
  designPackage?: InputMaybe<DesignPackageFilterInput>;
  /** Gets the design package id of the relation. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the wall of the relation. */
  designPackageWall?: InputMaybe<DesignPackageWallFilterInput>;
  /** Gets the wall id of the relation. */
  designPackageWallId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackagePreselectedProductSetFilterInput>>;
  /** Gets or sets the product category of the relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product set of the relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the product set id of the relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
};

/**
 * DesignPackageProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of room dimensions or other.
 */
export type DesignPackageProductSetRestriction = {
  __typename?: 'DesignPackageProductSetRestriction';
  /** Gets or sets the design package of the relation. */
  designPackage?: Maybe<DesignPackage>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the ProductCategory parent on this relation. */
  productCategory?: Maybe<ProductCategory>;
  /** Gets or sets the compare operation to preform. */
  productSetInfoCompareOperation?: Maybe<ProductSetInfoCompareOperation>;
  /** Gets or sets the value to be used in the compare operation. */
  productSetInfoCompareValue: Scalars['String'];
  /** Gets or sets the product set information key to be used to compare. */
  productSetInfoKey?: Maybe<ProductSetInfoKeyType>;
};

/**
 * DesignPackageProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of room dimensions or other.
 */
export type DesignPackageProductSetRestrictionFilterInput = {
  and?: InputMaybe<Array<DesignPackageProductSetRestrictionFilterInput>>;
  /** Gets or sets the design package of the relation. */
  designPackage?: InputMaybe<DesignPackageFilterInput>;
  /** Gets the design package id of the relation. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageProductSetRestrictionFilterInput>>;
  /** Gets or sets the ProductCategory parent on this relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets or sets the id of the ProductCategory parent on this relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the compare operation to preform. */
  productSetInfoCompareOperation?: InputMaybe<ProductSetInfoCompareOperationOperationFilterInput>;
  /** Gets or sets the value to be used in the compare operation. */
  productSetInfoCompareValue?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the product set information key to be used to compare. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
};

/** ProductCombinationRestrictionInfoKey represents a valid key of information about a single DesignPackageProductSetRestriction. */
export type DesignPackageProductSetRestrictionInfoKey = {
  __typename?: 'DesignPackageProductSetRestrictionInfoKey';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether the rule is optional. */
  isOptional: Scalars['Boolean'];
  /** Gets the operation to use for the restriction rule. */
  operation: ProductSetInfoCompareOperation;
  /** Gets the integer by which the Restriction InfoKey should be ordered when displaying. */
  position: Scalars['Int'];
  /** Gets or sets the product combination of the relation. */
  productCategory?: Maybe<ProductCategory>;
  /** Gets or sets the product combination of the relation. */
  productCombination?: Maybe<ProductCombination>;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey?: Maybe<ProductSetInfoKey>;
};

/** ProductCombinationRestrictionInfoKey represents a valid key of information about a single DesignPackageProductSetRestriction. */
export type DesignPackageProductSetRestrictionInfoKeyFilterInput = {
  and?: InputMaybe<Array<DesignPackageProductSetRestrictionInfoKeyFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the rule is optional. */
  isOptional?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the operation to use for the restriction rule. */
  operation?: InputMaybe<ProductSetInfoCompareOperationOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageProductSetRestrictionInfoKeyFilterInput>>;
  /** Gets the integer by which the Restriction InfoKey should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets or sets the product combination of the relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product combination of the relation. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the product combination id of the relation. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyFilterInput>;
  /** Gets the product set information key id of the relation. */
  productSetInfoKeyId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProductCombinationRestrictionInfoKey represents a valid key of information about a single DesignPackageProductSetRestriction. */
export type DesignPackageProductSetRestrictionInfoKeySortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the rule is optional. */
  isOptional?: InputMaybe<SortEnumType>;
  /** Gets the operation to use for the restriction rule. */
  operation?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the Restriction InfoKey should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
  /** Gets or sets the product combination of the relation. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** Gets or sets the product combination of the relation. */
  productCombination?: InputMaybe<ProductCombinationSortInput>;
  /** Gets the product combination id of the relation. */
  productCombinationId?: InputMaybe<SortEnumType>;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeySortInput>;
  /** Gets the product set information key id of the relation. */
  productSetInfoKeyId?: InputMaybe<SortEnumType>;
};

/**
 * DesignPackageProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of room dimensions or other.
 */
export type DesignPackageProductSetRestrictionSortInput = {
  /** Gets or sets the design package of the relation. */
  designPackage?: InputMaybe<DesignPackageSortInput>;
  /** Gets the design package id of the relation. */
  designPackageId?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductCategory parent on this relation. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets or sets the id of the ProductCategory parent on this relation. */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** Gets or sets the compare operation to preform. */
  productSetInfoCompareOperation?: InputMaybe<SortEnumType>;
  /** Gets or sets the value to be used in the compare operation. */
  productSetInfoCompareValue?: InputMaybe<SortEnumType>;
  /** Gets or sets the product set information key to be used to compare. */
  productSetInfoKey?: InputMaybe<SortEnumType>;
};

/** Represents a Design Package Selection. */
export type DesignPackageSelection = ContentItem & {
  __typename?: 'DesignPackageSelection';
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  checkBoxCasco?: Maybe<Scalars['String']>;
  /** Text field */
  checkBoxCustom?: Maybe<Scalars['String']>;
  /** Text field */
  checkBoxStandard?: Maybe<Scalars['String']>;
  /** Text field */
  chooseDesignButton?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  deleteDesignButton?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  enlarged?: Maybe<Scalars['String']>;
  /** HtmlField */
  extraInfo?: Maybe<HtmlField>;
  /** HtmlField */
  extraInfoWhenQuoteRequested?: Maybe<HtmlField>;
  /** Text field */
  for?: Maybe<Scalars['String']>;
  /** Text field */
  from?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  submitButton?: Maybe<Scalars['String']>;
  /** Text field */
  submitButtonWhenQuoteRequested?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  subtitleWhenQuoteRequested?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
  /** Text field */
  tooltipCasco?: Maybe<Scalars['String']>;
  /** Text field */
  tooltipCustom?: Maybe<Scalars['String']>;
  /** Text field */
  tooltipStandard?: Maybe<Scalars['String']>;
};

export type DesignPackageSelectionOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the DesignPackageSelection content item filters */
export type DesignPackageSelectionWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<DesignPackageSelectionWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<DesignPackageSelectionWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<DesignPackageSelectionWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Design Package represents a set of materials, including installation costs, for a single room. */
export type DesignPackageSortInput = {
  /** Gets Brand for the Design Package */
  brand?: InputMaybe<BrandSortInput>;
  /** Gets the brand id of the relation. */
  brandId?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a bath label. */
  hasBath?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a bathtub. */
  hasBathtub?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a fountain. */
  hasFountain?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a radiator. */
  hasRadiator?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a shower. */
  hasShower?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a tiles. */
  hasTiles?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a toilet. */
  hasToilet?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a toilet label. */
  hasToiletLabel?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a utilities. */
  hasUtilities?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has a washbasin. */
  hasWashbasin?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether the Design Package involves a fixed price,
   * or that the price is determines based on the smallest possible set of dimensions for similar rooms in the same project.
   */
  isFixedPrice?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package is a Standard one. */
  isStandard?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package has tiles selected to ceiling. */
  isTilesToCeiling?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the Design Package involves increasing the room size, i.e. XL label. */
  isXL?: InputMaybe<SortEnumType>;
  /** Gets Name for the Design Package */
  name?: InputMaybe<SortEnumType>;
  /** Gets the Position for the Design Package */
  position?: InputMaybe<SortEnumType>;
  /** Gets Price for the Design Package, based on smallest possible set of dimensions for this room. */
  price?: InputMaybe<MoneySortInput>;
  /** Gets price variant for the Design Package */
  priceVariant?: InputMaybe<SortEnumType>;
  /** Gets product package for the Design Package */
  productPackage?: InputMaybe<ProductPackageSortInput>;
  /** Gets the product package id of the relation. */
  productPackageId?: InputMaybe<SortEnumType>;
  /** Gets the Room for the Design Package */
  room?: InputMaybe<RoomSortInput>;
  /** Gets Style for the Design Package */
  style?: InputMaybe<StyleSortInput>;
  /** Gets the style id of the relation. */
  styleId?: InputMaybe<SortEnumType>;
};

/** Represents a wall in a DesignPackage. */
export type DesignPackageWall = {
  __typename?: 'DesignPackageWall';
  /** Gets the wall area. */
  area: Scalars['Decimal'];
  /** Gets the area of the wall to the ceiling. */
  ceilingArea: Scalars['Decimal'];
  /** Gets the wall length close to the ceiling. */
  ceilingLength: Scalars['Decimal'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether this is an accent wall. */
  isAccentWall: Scalars['Boolean'];
  /** Gets the wall name. */
  name: Scalars['String'];
  /** Gets the wall profile length. */
  profileLength: Scalars['Decimal'];
};

/** Contains information a wall in a Design Package. */
export type DesignPackageWallDtoInput = {
  /** Gets the identifier of the product set to be default accent tile on this wall. */
  accentTileDefaultProductSetId?: InputMaybe<Scalars['UUID']>;
  /** Gets the identifier of the product set to be pre-selected accent tile on this wall. */
  accentTilePreselectedProductSetId?: InputMaybe<Scalars['UUID']>;
  /** Gets the wall area. */
  area: Scalars['Decimal'];
  /** Gets the area of the wall to the ceiling. */
  ceilingArea: Scalars['Decimal'];
  /** Gets the wall length close to the ceiling. */
  ceilingLength: Scalars['Decimal'];
  /** Gets the wall identifier. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Gets a value indicating whether this is an accent wall. */
  isAccentWall: Scalars['Boolean'];
  /** Gets the wall name. */
  name: Scalars['String'];
  /** Gets the wall profile length. */
  profileLength: Scalars['Decimal'];
};

/** Represents a wall in a DesignPackage. */
export type DesignPackageWallFilterInput = {
  and?: InputMaybe<Array<DesignPackageWallFilterInput>>;
  /** Gets the wall area. */
  area?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the area of the wall to the ceiling. */
  ceilingArea?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the wall length close to the ceiling. */
  ceilingLength?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the identifier of the DesignPackage this wall belongs. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether this is an accent wall. */
  isAccentWall?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the wall name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DesignPackageWallFilterInput>>;
  /** Gets the wall profile length. */
  profileLength?: InputMaybe<DecimalOperationFilterInput>;
};

/** Represents a wall in a DesignPackage. */
export type DesignPackageWallSortInput = {
  /** Gets the wall area. */
  area?: InputMaybe<SortEnumType>;
  /** Gets the area of the wall to the ceiling. */
  ceilingArea?: InputMaybe<SortEnumType>;
  /** Gets the wall length close to the ceiling. */
  ceilingLength?: InputMaybe<SortEnumType>;
  /** Gets the identifier of the DesignPackage this wall belongs. */
  designPackageId?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether this is an accent wall. */
  isAccentWall?: InputMaybe<SortEnumType>;
  /** Gets the wall name. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the wall profile length. */
  profileLength?: InputMaybe<SortEnumType>;
};

export type DictionaryField = {
  __typename?: 'DictionaryField';
  data?: Maybe<Array<Maybe<DictionaryItem>>>;
};

export type DictionaryItem = {
  __typename?: 'DictionaryItem';
  /** Dictionary item name */
  name: Scalars['String'];
  /** Dictionary item value */
  value: Scalars['String'];
};

/** The valid status for a drawing request. */
export enum DrawingRequestStatus {
  /** Drawing request cancelled */
  Cancelled = 'CANCELLED',
  /** Drawing has a drawer selected */
  InProgress = 'IN_PROGRESS',
  /** Drawing not requested */
  NotRequested = 'NOT_REQUESTED',
  /** Drawing ready for client. */
  Ready = 'READY',
  /** Drawing requested but not yet available. */
  Requested = 'REQUESTED'
}

export type DrawingRequestStatusOperationFilterInput = {
  eq?: InputMaybe<DrawingRequestStatus>;
  in?: InputMaybe<Array<DrawingRequestStatus>>;
  neq?: InputMaybe<DrawingRequestStatus>;
  nin?: InputMaybe<Array<DrawingRequestStatus>>;
};

/** Email object describes and Email address. */
export type Email = {
  __typename?: 'Email';
  /** Gets the Address */
  address: Scalars['String'];
};

/** Email object describes and Email address. */
export type EmailFilterInput = {
  /** Gets the Address */
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EmailFilterInput>>;
  or?: InputMaybe<Array<EmailFilterInput>>;
};

/** Email object describes and Email address. */
export type EmailSortInput = {
  /** Gets the Address */
  address?: InputMaybe<SortEnumType>;
};

/** Represents a Error Definitions. */
export type ErrorDefinitions = ContentItem & {
  __typename?: 'ErrorDefinitions';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  defaultErrorMessage?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  errorTitle?: Maybe<Scalars['String']>;
  /** DictionaryField */
  errors?: Maybe<DictionaryField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type ErrorDefinitionsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ErrorDefinitions content item filters */
export type ErrorDefinitionsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ErrorDefinitionsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ErrorDefinitionsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ErrorDefinitionsWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Explanation Page. */
export type ExplanationPage = ContentItem & {
  __typename?: 'ExplanationPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  explanationTitle?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** ContentPickerField */
  subjectsWithContent?: Maybe<ContentPickerField>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type ExplanationPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ExplanationPage content item filters */
export type ExplanationPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ExplanationPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ExplanationPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ExplanationPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Veelgestelde vragen. */
export type FaqPage = ContentItem & {
  __typename?: 'FAQPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Content stored as HTML. */
  htmlBody?: Maybe<HtmlBodyPart>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Text field */
  projectType?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type FaqPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the FAQPage content item filters */
export type FaqPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<FaqPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<FaqPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<FaqPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export enum FileExtension {
  Csv = 'CSV',
  Msg = 'MSG',
  Pdf = 'PDF',
  Xlsx = 'XLSX'
}

/** Represents a Help page. */
export type HelpPage = ContentItem & {
  __typename?: 'HelpPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  body?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  teaserSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type HelpPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the HelpPage content item filters */
export type HelpPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<HelpPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<HelpPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<HelpPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Home Page. */
export type HomePage = ContentItem & {
  __typename?: 'HomePage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** Text field */
  costSpecificationLinkText?: Maybe<Scalars['String']>;
  /** Text field */
  costSpecificationTitle?: Maybe<Scalars['String']>;
  /** Text field */
  costSpecificationTotal?: Maybe<Scalars['String']>;
  /** Text field */
  createOrderButtonText?: Maybe<Scalars['String']>;
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  customOfferDownloadDrawing?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferDownloadOffer?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferLink?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferSubmitOpenCustomOfferWillBeCanceled?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferSubmitOpenCustomOfferWillBeCompleted?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferSubmitPendingCustomOfferRequestConfirmMessage?: Maybe<Scalars['String']>;
  /** Text field */
  customOfferUnavailableTooltip?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** HtmlField */
  footerText?: Maybe<HtmlField>;
  /** Represents a HomePageInvalidConfigDialog. */
  homePageInvalidConfigDialog?: Maybe<HomePageInvalidConfigDialog>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  myRoomsDescription?: Maybe<Scalars['String']>;
  /** Text field */
  myRoomsTitle?: Maybe<Scalars['String']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  removeDesignPopupContent?: Maybe<HtmlField>;
  /** Text field */
  removeDesignPopupTitle?: Maybe<Scalars['String']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemCascoOption?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemCascoOptionTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemChooseLayout?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemCompleteAndInstalled?: Maybe<Scalars['String']>;
  /** HtmlField */
  roomListItemFooterText?: Maybe<HtmlField>;
  /** Text field */
  roomListItemFooterTextTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemNoSelectionYet?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemStandardOfferSelectionName?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemSurcharge?: Maybe<Scalars['String']>;
  /** Text field */
  roomListItemUnavailableProductSetsMessage?: Maybe<Scalars['String']>;
  /** Text field */
  unavailableProductSetsMessage?: Maybe<Scalars['String']>;
};

export type HomePageInvalidConfigDialog = {
  __typename?: 'HomePageInvalidConfigDialog';
  /** Text field */
  invalidConfigurationDialogButton?: Maybe<Scalars['String']>;
  /** Text field */
  invalidConfigurationDialogText?: Maybe<Scalars['String']>;
  /** Text field */
  invalidConfigurationDialogTitle?: Maybe<Scalars['String']>;
};

export type HomePageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the HomePage content item filters */
export type HomePageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<HomePageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<HomePageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<HomePageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a How Does It Work - Content Item. */
export type HowDoesItWorkContentItem = ContentItem & {
  __typename?: 'HowDoesItWorkContentItem';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  subjectTitle?: Maybe<Scalars['String']>;
  /** Boolean field */
  theme?: Maybe<Scalars['Boolean']>;
};

export type HowDoesItWorkContentItemOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the HowDoesItWorkContentItem content item filters */
export type HowDoesItWorkContentItemWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<HowDoesItWorkContentItemWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<HowDoesItWorkContentItemWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<HowDoesItWorkContentItemWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type HowDoesItWorkPageContent = {
  __typename?: 'HowDoesItWorkPageContent';
  /** Text field */
  headerContentBlocks?: Maybe<Scalars['String']>;
  /** ContentPickerField */
  howDoesItWorkTopics?: Maybe<ContentPickerField>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

/** Represents a How Does It Work Page - Design Rooms. */
export type HowDoesItWorkPageDesignRooms = ContentItem & {
  __typename?: 'HowDoesItWorkPageDesignRooms';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Represents a HowDoesItWorkPageContent. */
  howDoesItWorkPageContent?: Maybe<HowDoesItWorkPageContent>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type HowDoesItWorkPageDesignRoomsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the HowDoesItWorkPageDesignRooms content item filters */
export type HowDoesItWorkPageDesignRoomsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageDesignRoomsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageDesignRoomsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageDesignRoomsWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a How Does It Work Page - New Home. */
export type HowDoesItWorkPageNewHome = ContentItem & {
  __typename?: 'HowDoesItWorkPageNewHome';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Represents a HowDoesItWorkPageContent. */
  howDoesItWorkPageContent?: Maybe<HowDoesItWorkPageContent>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type HowDoesItWorkPageNewHomeOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the HowDoesItWorkPageNewHome content item filters */
export type HowDoesItWorkPageNewHomeWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageNewHomeWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageNewHomeWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<HowDoesItWorkPageNewHomeWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Content stored as HTML. */
export type HtmlBodyPart = {
  __typename?: 'HtmlBodyPart';
  /** the HTML content */
  html?: Maybe<Scalars['String']>;
};

/** Content stored as HTML. */
export type HtmlField = {
  __typename?: 'HtmlField';
  /** the HTML content */
  html?: Maybe<Scalars['String']>;
};

/** ImportProductPackage class represents a request to import a ProductPackage from an CVS file. */
export type ImportProductPackage = {
  __typename?: 'ImportProductPackage';
  /** Gets the file name. */
  filename: Scalars['String'];
  /** Gets the date the import process ended. */
  finishDate?: Maybe<Scalars['DateTime']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the information gathered during processing of the import. */
  information?: Maybe<Scalars['String']>;
  /** Gets the storage path to th~e file. */
  path: Scalars['String'];
  /** Gets the product package name to import to. */
  productPackageName: Scalars['String'];
  /** Gets the type of project the product package is going to be used on. */
  propositionType: Badkamercore_ProjectType;
  /** Gets the status of the import process. */
  status: ImportStatus;
  /** Gets the date the import file was uploaded. */
  uploadDate: Scalars['DateTime'];
};

/** ImportProductPackage class represents a request to import a ProductPackage from an CVS file. */
export type ImportProductPackageFilterInput = {
  and?: InputMaybe<Array<ImportProductPackageFilterInput>>;
  /** Gets the file name. */
  filename?: InputMaybe<StringOperationFilterInput>;
  /** Gets the date the import process ended. */
  finishDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the information gathered during processing of the import. */
  information?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImportProductPackageFilterInput>>;
  /** Gets the storage path to th~e file. */
  path?: InputMaybe<StringOperationFilterInput>;
  /** Gets the product package name to import to. */
  productPackageName?: InputMaybe<StringOperationFilterInput>;
  /** Gets the type of project the product package is going to be used on. */
  propositionType?: InputMaybe<ProjectTypeOperationFilterInput>;
  /** Gets the status of the import process. */
  status?: InputMaybe<ImportStatusOperationFilterInput>;
  /** Gets the date the import file was uploaded. */
  uploadDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** ImportProductPackage class represents a request to import a ProductPackage from an CVS file. */
export type ImportProductPackageSortInput = {
  /** Gets the file name. */
  filename?: InputMaybe<SortEnumType>;
  /** Gets the date the import process ended. */
  finishDate?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the information gathered during processing of the import. */
  information?: InputMaybe<SortEnumType>;
  /** Gets the storage path to th~e file. */
  path?: InputMaybe<SortEnumType>;
  /** Gets the product package name to import to. */
  productPackageName?: InputMaybe<SortEnumType>;
  /** Gets the type of project the product package is going to be used on. */
  propositionType?: InputMaybe<SortEnumType>;
  /** Gets the status of the import process. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the date the import file was uploaded. */
  uploadDate?: InputMaybe<SortEnumType>;
};

/** Details about a Product Set to be added to Product Package. */
export type ImportProductSetInput = {
  /** Gets or sets details about the installation costs of this product set. */
  costDetails: Array<TupleOfStringAndStringAndBooleanInput>;
  /** Gets or sets a value indicating whether the product set is eligible for being a default in Design Packages. */
  isDefault: Scalars['Boolean'];
  /** Gets or sets a value indicating whether the product set is assigned to higher price variant. */
  isHigherPriceVariant: Scalars['Boolean'];
  /** Gets or sets a value indicating whether the product set is assigned to intermediate price variant. */
  isIntermediatePriceVariant: Scalars['Boolean'];
  /** Gets or sets a value indicating whether the product set is assigned to lower price variant. */
  isLowerPriceVariant: Scalars['Boolean'];
  /** Gets or sets the identifier of the category where the product set belongs. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets article codes for the articles that compose this product set. */
  productCodes: Array<Scalars['String']>;
  /** Gets or sets identifier of the brand line where this product set is included. */
  productSetBrandlineId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the name of the brand line. */
  productSetBrandlineName?: InputMaybe<Scalars['String']>;
  /** Gets or sets information values about product set characteristics. */
  productSetInfoValues: Array<TupleOfGuidAndStringInput>;
  /** Gets or sets name of the product set. */
  productSetName: Scalars['String'];
  /** Gets or sets the row number on the import file. */
  rowNumber: Scalars['String'];
};

/** Staatus that an import process can have. */
export enum ImportStatus {
  /** The import process is complete. */
  Complete = 'COMPLETE',
  /** The import process ended with errors. */
  Error = 'ERROR',
  /** New request for import. Not handled yet. */
  New = 'NEW',
  /** While the import is being procesed. */
  Processing = 'PROCESSING'
}

export type ImportStatusOperationFilterInput = {
  eq?: InputMaybe<ImportStatus>;
  in?: InputMaybe<Array<ImportStatus>>;
  neq?: InputMaybe<ImportStatus>;
  nin?: InputMaybe<Array<ImportStatus>>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type KeyValuePairOfCostTypeAndMoney = {
  __typename?: 'KeyValuePairOfCostTypeAndMoney';
  key: CostType;
  value: Money;
};

export type KeyValuePairOfCostTypeAndMoneyFilterInput = {
  and?: InputMaybe<Array<KeyValuePairOfCostTypeAndMoneyFilterInput>>;
  key?: InputMaybe<CostTypeOperationFilterInput>;
  or?: InputMaybe<Array<KeyValuePairOfCostTypeAndMoneyFilterInput>>;
  value?: InputMaybe<MoneyFilterInput>;
};

export type KeyValuePairOfCostTypeAndStringInput = {
  key: CostType;
  value: Scalars['String'];
};

export type KeyValuePairOfSelectedProductSetDictionaryKeyAndProductPackageSet = {
  __typename?: 'KeyValuePairOfSelectedProductSetDictionaryKeyAndProductPackageSet';
  key: SelectedProductSetDictionaryKey;
  value: ProductPackageSet;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Represents a Landing Page. */
export type LandingPage = ContentItem & {
  __typename?: 'LandingPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Represents a LandingPageContent. */
  block1?: Maybe<Block1>;
  /** Represents a LandingPageContent. */
  block2?: Maybe<Block2>;
  /** Represents a LandingPageContent. */
  block3?: Maybe<Block3>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Numeric field */
  rotationTime?: Maybe<Scalars['Decimal']>;
};

export type LandingPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the LandingPage content item filters */
export type LandingPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<LandingPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<LandingPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<LandingPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Layout for one or more RealEstate objects in a Project. */
export type Layout = {
  __typename?: 'Layout';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the collection of Room to Layout relations. */
  layoutRooms: Array<LayoutRoom>;
  /** Gets the name of this Layout. */
  name: Scalars['String'];
};


/** Layout for one or more RealEstate objects in a Project. */
export type LayoutLayoutRoomsArgs = {
  where?: InputMaybe<LayoutRoomFilterInput>;
};

/** Layout for one or more RealEstate objects in a Project. */
export type LayoutFilterInput = {
  and?: InputMaybe<Array<LayoutFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the collection of Room to Layout relations. */
  layoutRooms?: InputMaybe<ListFilterInputTypeOfLayoutRoomFilterInput>;
  /** Gets the name of this Layout. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LayoutFilterInput>>;
  /** Gets the Project identifier this layout belongs. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** Relational class that keeps track of relations (many to many) between Layout and Room. */
export type LayoutRoom = {
  __typename?: 'LayoutRoom';
  /** Gets or sets the Layout part of this relation. */
  layout: Layout;
  /** Gets or sets the Room part of this relation. */
  room: Room;
};

/** Relational class that keeps track of relations (many to many) between Layout and Room. */
export type LayoutRoomFilterInput = {
  and?: InputMaybe<Array<LayoutRoomFilterInput>>;
  /** Gets or sets the Layout part of this relation. */
  layout?: InputMaybe<LayoutFilterInput>;
  /** Gets the id of the Layout part of this relation. */
  layoutId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<LayoutRoomFilterInput>>;
  /** Gets or sets the Room part of this relation. */
  room?: InputMaybe<RoomFilterInput>;
  /** Gets the id of the Room part of this relation. */
  roomId?: InputMaybe<UuidOperationFilterInput>;
};

/** Layout for one or more RealEstate objects in a Project. */
export type LayoutSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of this Layout. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the Project identifier this layout belongs. */
  projectId?: InputMaybe<SortEnumType>;
};

/** Represents a Layouts. */
export type Layouts = ContentItem & {
  __typename?: 'Layouts';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Represents a TranslationLayout. */
  translationLayout?: Maybe<TranslationLayout>;
};

export type LayoutsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the Layouts content item filters */
export type LayoutsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<LayoutsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<LayoutsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<LayoutsWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type LinkField = {
  __typename?: 'LinkField';
  /** the text of the link */
  text?: Maybe<Scalars['String']>;
  /** the url of the link */
  url?: Maybe<Scalars['String']>;
};

export type ListFilterInputTypeOfActivityTemplateActivityCostFilterInput = {
  all?: InputMaybe<ActivityTemplateActivityCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ActivityTemplateActivityCostFilterInput>;
  some?: InputMaybe<ActivityTemplateActivityCostFilterInput>;
};

export type ListFilterInputTypeOfActivityTemplateActivityFilterInput = {
  all?: InputMaybe<ActivityTemplateActivityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ActivityTemplateActivityFilterInput>;
  some?: InputMaybe<ActivityTemplateActivityFilterInput>;
};

export type ListFilterInputTypeOfCustomOfferFilterInput = {
  all?: InputMaybe<CustomOfferFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomOfferFilterInput>;
  some?: InputMaybe<CustomOfferFilterInput>;
};

export type ListFilterInputTypeOfCustomOfferRequestAppointmentFilterInput = {
  all?: InputMaybe<CustomOfferRequestAppointmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomOfferRequestAppointmentFilterInput>;
  some?: InputMaybe<CustomOfferRequestAppointmentFilterInput>;
};

export type ListFilterInputTypeOfCustomOfferRoomCostFilterInput = {
  all?: InputMaybe<CustomOfferRoomCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomOfferRoomCostFilterInput>;
  some?: InputMaybe<CustomOfferRoomCostFilterInput>;
};

export type ListFilterInputTypeOfCustomOfferRoomFilterInput = {
  all?: InputMaybe<CustomOfferRoomFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomOfferRoomFilterInput>;
  some?: InputMaybe<CustomOfferRoomFilterInput>;
};

export type ListFilterInputTypeOfDesignPackageCostFilterInput = {
  all?: InputMaybe<DesignPackageCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackageCostFilterInput>;
  some?: InputMaybe<DesignPackageCostFilterInput>;
};

export type ListFilterInputTypeOfDesignPackageDefaultProductSetFilterInput = {
  all?: InputMaybe<DesignPackageDefaultProductSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackageDefaultProductSetFilterInput>;
  some?: InputMaybe<DesignPackageDefaultProductSetFilterInput>;
};

export type ListFilterInputTypeOfDesignPackageFilterInput = {
  all?: InputMaybe<DesignPackageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackageFilterInput>;
  some?: InputMaybe<DesignPackageFilterInput>;
};

export type ListFilterInputTypeOfDesignPackagePreselectedProductSetFilterInput = {
  all?: InputMaybe<DesignPackagePreselectedProductSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackagePreselectedProductSetFilterInput>;
  some?: InputMaybe<DesignPackagePreselectedProductSetFilterInput>;
};

export type ListFilterInputTypeOfDesignPackageProductSetRestrictionFilterInput = {
  all?: InputMaybe<DesignPackageProductSetRestrictionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackageProductSetRestrictionFilterInput>;
  some?: InputMaybe<DesignPackageProductSetRestrictionFilterInput>;
};

export type ListFilterInputTypeOfDesignPackageWallFilterInput = {
  all?: InputMaybe<DesignPackageWallFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DesignPackageWallFilterInput>;
  some?: InputMaybe<DesignPackageWallFilterInput>;
};

export type ListFilterInputTypeOfKeyValuePairOfCostTypeAndMoneyFilterInput = {
  all?: InputMaybe<KeyValuePairOfCostTypeAndMoneyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<KeyValuePairOfCostTypeAndMoneyFilterInput>;
  some?: InputMaybe<KeyValuePairOfCostTypeAndMoneyFilterInput>;
};

export type ListFilterInputTypeOfLayoutFilterInput = {
  all?: InputMaybe<LayoutFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LayoutFilterInput>;
  some?: InputMaybe<LayoutFilterInput>;
};

export type ListFilterInputTypeOfLayoutRoomFilterInput = {
  all?: InputMaybe<LayoutRoomFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LayoutRoomFilterInput>;
  some?: InputMaybe<LayoutRoomFilterInput>;
};

export type ListFilterInputTypeOfProductCategoryFilterInput = {
  all?: InputMaybe<ProductCategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductCategoryFilterInput>;
  some?: InputMaybe<ProductCategoryFilterInput>;
};

export type ListFilterInputTypeOfProductCategoryProductSetInfoKeyFilterInput = {
  all?: InputMaybe<ProductCategoryProductSetInfoKeyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductCategoryProductSetInfoKeyFilterInput>;
  some?: InputMaybe<ProductCategoryProductSetInfoKeyFilterInput>;
};

export type ListFilterInputTypeOfProductCombinationRestrictionFilterInput = {
  all?: InputMaybe<ProductCombinationRestrictionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductCombinationRestrictionFilterInput>;
  some?: InputMaybe<ProductCombinationRestrictionFilterInput>;
};

export type ListFilterInputTypeOfProductPackageCategoryFilterInput = {
  all?: InputMaybe<ProductPackageCategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductPackageCategoryFilterInput>;
  some?: InputMaybe<ProductPackageCategoryFilterInput>;
};

export type ListFilterInputTypeOfProductPackageSetFilterInput = {
  all?: InputMaybe<ProductPackageSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductPackageSetFilterInput>;
  some?: InputMaybe<ProductPackageSetFilterInput>;
};

export type ListFilterInputTypeOfProductPackageSetPriceVariantFilterInput = {
  all?: InputMaybe<ProductPackageSetPriceVariantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductPackageSetPriceVariantFilterInput>;
  some?: InputMaybe<ProductPackageSetPriceVariantFilterInput>;
};

export type ListFilterInputTypeOfProductSetAssignedActivityFilterInput = {
  all?: InputMaybe<ProductSetAssignedActivityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetAssignedActivityFilterInput>;
  some?: InputMaybe<ProductSetAssignedActivityFilterInput>;
};

export type ListFilterInputTypeOfProductSetBrandlineFilterInput = {
  all?: InputMaybe<ProductSetBrandlineFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetBrandlineFilterInput>;
  some?: InputMaybe<ProductSetBrandlineFilterInput>;
};

export type ListFilterInputTypeOfProductSetCostFilterInput = {
  all?: InputMaybe<ProductSetCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetCostFilterInput>;
  some?: InputMaybe<ProductSetCostFilterInput>;
};

export type ListFilterInputTypeOfProductSetFilterInput = {
  all?: InputMaybe<ProductSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetFilterInput>;
  some?: InputMaybe<ProductSetFilterInput>;
};

export type ListFilterInputTypeOfProductSetInfoFilterInput = {
  all?: InputMaybe<ProductSetInfoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetInfoFilterInput>;
  some?: InputMaybe<ProductSetInfoFilterInput>;
};

export type ListFilterInputTypeOfProductSetProductFilterInput = {
  all?: InputMaybe<ProductSetProductFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetProductFilterInput>;
  some?: InputMaybe<ProductSetProductFilterInput>;
};

export type ListFilterInputTypeOfProductSetRestrictionFilterInput = {
  all?: InputMaybe<ProductSetRestrictionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductSetRestrictionFilterInput>;
  some?: InputMaybe<ProductSetRestrictionFilterInput>;
};

export type ListFilterInputTypeOfProjectActivityCostFilterInput = {
  all?: InputMaybe<ProjectActivityCostFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectActivityCostFilterInput>;
  some?: InputMaybe<ProjectActivityCostFilterInput>;
};

export type ListFilterInputTypeOfProjectAssignedActivityFilterInput = {
  all?: InputMaybe<ProjectAssignedActivityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectAssignedActivityFilterInput>;
  some?: InputMaybe<ProjectAssignedActivityFilterInput>;
};

export type ListFilterInputTypeOfProjectBrandFilterInput = {
  all?: InputMaybe<ProjectBrandFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectBrandFilterInput>;
  some?: InputMaybe<ProjectBrandFilterInput>;
};

export type ListFilterInputTypeOfProjectDefaultProductSetFilterInput = {
  all?: InputMaybe<ProjectDefaultProductSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectDefaultProductSetFilterInput>;
  some?: InputMaybe<ProjectDefaultProductSetFilterInput>;
};

export type ListFilterInputTypeOfProjectDossierContactFilterInput = {
  all?: InputMaybe<ProjectDossierContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectDossierContactFilterInput>;
  some?: InputMaybe<ProjectDossierContactFilterInput>;
};

export type ListFilterInputTypeOfProjectProductPackageFilterInput = {
  all?: InputMaybe<ProjectProductPackageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectProductPackageFilterInput>;
  some?: InputMaybe<ProjectProductPackageFilterInput>;
};

export type ListFilterInputTypeOfProjectProductReturnPriceFilterInput = {
  all?: InputMaybe<ProjectProductReturnPriceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectProductReturnPriceFilterInput>;
  some?: InputMaybe<ProjectProductReturnPriceFilterInput>;
};

export type ListFilterInputTypeOfProjectStyleFilterInput = {
  all?: InputMaybe<ProjectStyleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectStyleFilterInput>;
  some?: InputMaybe<ProjectStyleFilterInput>;
};

export type ListFilterInputTypeOfRealEstateContactFilterInput = {
  all?: InputMaybe<RealEstateContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RealEstateContactFilterInput>;
  some?: InputMaybe<RealEstateContactFilterInput>;
};

export type ListFilterInputTypeOfRealEstateContactNotificationFilterInput = {
  all?: InputMaybe<RealEstateContactNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RealEstateContactNotificationFilterInput>;
  some?: InputMaybe<RealEstateContactNotificationFilterInput>;
};

export type ListFilterInputTypeOfRealEstateExternalActionFilterInput = {
  all?: InputMaybe<RealEstateExternalActionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RealEstateExternalActionFilterInput>;
  some?: InputMaybe<RealEstateExternalActionFilterInput>;
};

export type ListFilterInputTypeOfRealEstateFilterInput = {
  all?: InputMaybe<RealEstateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RealEstateFilterInput>;
  some?: InputMaybe<RealEstateFilterInput>;
};

export type ListFilterInputTypeOfRealEstateStatusUpdateFilterInput = {
  all?: InputMaybe<RealEstateStatusUpdateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RealEstateStatusUpdateFilterInput>;
  some?: InputMaybe<RealEstateStatusUpdateFilterInput>;
};

export type ListFilterInputTypeOfRequestFor3DDrawingFilterInput = {
  all?: InputMaybe<RequestFor3DDrawingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestFor3DDrawingFilterInput>;
  some?: InputMaybe<RequestFor3DDrawingFilterInput>;
};

export type ListFilterInputTypeOfRequestForContactFilterInput = {
  all?: InputMaybe<RequestForContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForContactFilterInput>;
  some?: InputMaybe<RequestForContactFilterInput>;
};

export type ListFilterInputTypeOfRequestForContactStatusUpdateFilterInput = {
  all?: InputMaybe<RequestForContactStatusUpdateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForContactStatusUpdateFilterInput>;
  some?: InputMaybe<RequestForContactStatusUpdateFilterInput>;
};

export type ListFilterInputTypeOfRequestForCustomOfferFilterInput = {
  all?: InputMaybe<RequestForCustomOfferFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForCustomOfferFilterInput>;
  some?: InputMaybe<RequestForCustomOfferFilterInput>;
};

export type ListFilterInputTypeOfRequestForCustomOfferStatusUpdateFilterInput = {
  all?: InputMaybe<RequestForCustomOfferStatusUpdateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForCustomOfferStatusUpdateFilterInput>;
  some?: InputMaybe<RequestForCustomOfferStatusUpdateFilterInput>;
};

export type ListFilterInputTypeOfRequestForQuoteFilterInput = {
  all?: InputMaybe<RequestForQuoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForQuoteFilterInput>;
  some?: InputMaybe<RequestForQuoteFilterInput>;
};

export type ListFilterInputTypeOfRequestForTechnicalDrawingFilterInput = {
  all?: InputMaybe<RequestForTechnicalDrawingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RequestForTechnicalDrawingFilterInput>;
  some?: InputMaybe<RequestForTechnicalDrawingFilterInput>;
};

export type ListFilterInputTypeOfRoomFilterInput = {
  all?: InputMaybe<RoomFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RoomFilterInput>;
  some?: InputMaybe<RoomFilterInput>;
};

/** the localization part of the content item */
export type LocalizationInputObjectType = {
  /** the culture of the content item to filter is equal to */
  culture?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter contains the string */
  culture_contains?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter ends with the string */
  culture_ends_with?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter is in collection */
  culture_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the culture of the content item to filter is not equal to */
  culture_not?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter does not contain the string */
  culture_not_contains?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter does not end with the string */
  culture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter is not in collection */
  culture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the culture of the content item to filter does not start with the string */
  culture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the culture of the content item to filter starts with the string */
  culture_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization set of the content item to filter */
  localizationSet?: InputMaybe<Scalars['String']>;
};

/** Localization cultures for your content item. */
export type LocalizationPart = {
  __typename?: 'LocalizationPart';
  /** The culture for your content item. */
  culture: Scalars['String'];
  /** The localization set for your content item. */
  localizationSet: Scalars['String'];
  /** The localizations of the content item. */
  localizations?: Maybe<Array<Maybe<ContentItem>>>;
};


/** Localization cultures for your content item. */
export type LocalizationPartLocalizationsArgs = {
  culture?: InputMaybe<Scalars['String']>;
};

export type MediaAsset = {
  __typename?: 'MediaAsset';
  /** The date and time in UTC when the asset was last modified. */
  lastModifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The length of the file. */
  length: Scalars['Long'];
  /** The name of the asset. */
  name: Scalars['String'];
  /** The url to the asset. */
  path?: Maybe<Scalars['String']>;
};

export type MediaField = {
  __typename?: 'MediaField';
  /** the media paths */
  paths?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** the absolute urls of the media items */
  urls?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MediaFieldPathsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MediaFieldUrlsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MessageDto = {
  __typename?: 'MessageDto';
  content: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateModified?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  operationId?: Maybe<Scalars['UUID']>;
  operationType: OperationType;
  parentId?: Maybe<Scalars['UUID']>;
  realEstate?: Maybe<RealEstate>;
  realEstateId: Scalars['UUID'];
  sender: Scalars['String'];
  senderRole: Scalars['String'];
  status: MessageStatus;
};


export type MessageDtoRealEstateArgs = {
  where?: InputMaybe<RealEstateFilterInput>;
};

export type MessageDtoFilterInput = {
  and?: InputMaybe<Array<MessageDtoFilterInput>>;
  content?: InputMaybe<StringOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  operationId?: InputMaybe<UuidOperationFilterInput>;
  operationType?: InputMaybe<OperationTypeOperationFilterInput>;
  or?: InputMaybe<Array<MessageDtoFilterInput>>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  realEstateContactId?: InputMaybe<UuidOperationFilterInput>;
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  sender?: InputMaybe<StringOperationFilterInput>;
  senderRole?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<MessageStatusOperationFilterInput>;
};

export type MessageDtoSortInput = {
  content?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPublic?: InputMaybe<SortEnumType>;
  operationId?: InputMaybe<SortEnumType>;
  operationType?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  realEstateContactId?: InputMaybe<SortEnumType>;
  realEstateId?: InputMaybe<SortEnumType>;
  sender?: InputMaybe<SortEnumType>;
  senderRole?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export enum MessageStatus {
  Closed = 'CLOSED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type MessageStatusOperationFilterInput = {
  eq?: InputMaybe<MessageStatus>;
  in?: InputMaybe<Array<MessageStatus>>;
  neq?: InputMaybe<MessageStatus>;
  nin?: InputMaybe<Array<MessageStatus>>;
};

/** Money object represents money (currency and value). */
export type Money = {
  __typename?: 'Money';
  /** Gets the amount of money in the currency. */
  amount: Scalars['Decimal'];
  /** Gets the currency of the money/ */
  currency: Currency;
};

/** Money object represents money (currency and value). */
export type MoneyFilterInput = {
  /** Gets the amount of money in the currency. */
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<MoneyFilterInput>>;
  /** Gets the currency of the money/ */
  currency?: InputMaybe<CurrencyOperationFilterInput>;
  or?: InputMaybe<Array<MoneyFilterInput>>;
};

/** Money object represents money (currency and value). */
export type MoneyInput = {
  /** Gets the amount of money in the currency. */
  amount: Scalars['Decimal'];
  /** Gets the currency of the money/ */
  currency: Currency;
};

/** Money object represents money (currency and value). */
export type MoneySortInput = {
  /** Gets the amount of money in the currency. */
  amount?: InputMaybe<SortEnumType>;
  /** Gets the currency of the money/ */
  currency?: InputMaybe<SortEnumType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCustomOfferRequestAppointment: CustomOfferRequestAppointment;
  addCustomProductSetInfoKey: Scalars['UUID'];
  addManualProduct: Product;
  addProductPackageFavourite: Scalars['Boolean'];
  addToMySelection: Array<RealEstateRoomSelection>;
  assignBrandToProject: ProjectBrand;
  assignDrawerTo3DDrawing: Scalars['Boolean'];
  assignDrawerToTechnicalDrawing: Scalars['Boolean'];
  assignProductPackageToProject: ProjectProductPackage;
  assignSingleBrandToProject: ProjectBrand;
  assignSingleProductPackageToProject: ProjectProductPackage;
  assignStyleToProject: ProjectStyle;
  cancel3DDrawingForRoomSelection: Scalars['Boolean'];
  cancelCustomOfferChangeRequest: Scalars['UUID'];
  cancelCustomOfferRequest: Scalars['Boolean'];
  cancelTechnicalDrawing: Scalars['Boolean'];
  checkCustomOfferForProject: Scalars['Boolean'];
  checkCustomOfferFromMySelection: Array<RealEstateCustomOfferSelection>;
  checkDivideCustomOfferByRoomForProject: Scalars['Boolean'];
  checkMySelection: Array<RealEstateRoomSelection>;
  checkUseReturnPriceCalculationForProject: Scalars['Boolean'];
  closeProject: Project;
  completeCustomOfferRequest: Scalars['UUID'];
  copyBrand: Scalars['UUID'];
  copyProductPackage: Scalars['UUID'];
  copyProductSet: Scalars['UUID'];
  copyStyle: Scalars['UUID'];
  createActivityTemplate: ActivityTemplate;
  createBrand: Brand;
  createCustomOffer: CustomOffer;
  createDesignPackage: Scalars['UUID'];
  createNote: Scalars['UUID'];
  createProductCategory: ProductCategory;
  createProductPackage: ProductPackage;
  createProductSet: Scalars['UUID'];
  createProductSetBrandline: ProductSetBrandline;
  createRoom: Scalars['UUID'];
  createStyle: Style;
  deleteActivityTemplate: Scalars['Boolean'];
  deleteBrand: Scalars['Boolean'];
  deleteCustomOffer: Scalars['Boolean'];
  deleteCustomOfferRequestAppointment: Scalars['Boolean'];
  deleteDesignPackage: Scalars['Boolean'];
  deleteProductPackage: Scalars['Boolean'];
  deleteProductSet: Scalars['Boolean'];
  deleteProductSetBrandline: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteRealEstateFile: Scalars['Boolean'];
  deleteRoom: Scalars['Boolean'];
  deleteStyle: Scalars['Boolean'];
  endProject: Project;
  endRealEstateExternalAction: Scalars['Boolean'];
  endRequestForContact: Scalars['Boolean'];
  enrichProject: Project;
  forceCancel3DDrawingForRoomSelection: Scalars['Boolean'];
  forceRealEstateSelection: Scalars['Boolean'];
  generateVariantDesignPackages: Scalars['Boolean'];
  openRealEstateExternalAction: RealEstateExternalAction;
  processImportProductPackage: Scalars['Boolean'];
  projectActivityTemplateImport: Array<ProjectAssignedActivity>;
  publishProject: Project;
  reOpenCustomOfferRequest: Scalars['Boolean'];
  removeFromMySelection: Array<RealEstateRoomSelection>;
  removeProductPackageFavourite: Scalars['Boolean'];
  request3DDrawingForRoomSelection: Array<RealEstateRoomSelection>;
  requestChangesCustomOffer: Scalars['Boolean'];
  requestForContact: Scalars['UUID'];
  requestForCustomOffer: RequestForCustomOffer;
  resetDesignPackageSelection: Scalars['Boolean'];
  resetProjectStatus: Project;
  resetRealEstateSelections: Scalars['Boolean'];
  resetUserPassword: Scalars['Boolean'];
  resolveSharedWaitingCustomOffer: Scalars['Boolean'];
  saveCustomOffer: CustomOffer;
  saveDesignPackages: Scalars['Decimal'];
  saveStandardSelection: Scalars['Decimal'];
  selectCustomDesignPackage: Scalars['Boolean'];
  selectStandardDesignPackage: Scalars['Boolean'];
  sendInvitation: Scalars['Boolean'];
  sendInvitationToRealEstates: Scalars['Boolean'];
  sendNoLoginNotification: Scalars['Boolean'];
  sendNoQuoteNotification: Scalars['Boolean'];
  sendNotification: Scalars['Boolean'];
  sendRealEstateDossier: Scalars['Boolean'];
  sendRealEstateQuote: Scalars['Boolean'];
  setProductSetAvailability: Scalars['Boolean'];
  setUserPassword: Scalars['Boolean'];
  syncSelectionsCost: Scalars['Boolean'];
  syncWithEstimate: Scalars['Boolean'];
  unassignBrandFromProject: Scalars['Boolean'];
  unassignProductPackageFromProject: Scalars['Boolean'];
  unassignStyleFromProject: Scalars['Boolean'];
  uncheckCustomOfferFromMySelection: Array<RealEstateCustomOfferSelection>;
  uncheckMySelection: Array<RealEstateRoomSelection>;
  updateActivityTemplate: ActivityTemplateActivity;
  updateActivityTemplateName: Scalars['UUID'];
  updateCustomOfferRequest: RequestForCustomOffer;
  updateCustomOfferRequestAppointment: CustomOfferRequestAppointment;
  updateCustomOfferShared: CustomOffer;
  updateDesignPackage: Scalars['UUID'];
  updateImportProductPackage: ImportProductPackage;
  updateMessageStatus: MessageDto;
  updateMultipleProductSetPrices: Array<Scalars['UUID']>;
  updateProduct: Scalars['UUID'];
  updateProductPackage: Scalars['UUID'];
  updateProductPackageCategory: ProductPackageCategory;
  updateProductReturnPrice: Scalars['Boolean'];
  updateProductSet: Scalars['UUID'];
  updateProjectActivityCosts: ProjectAssignedActivity;
  updateProjectBrands: Scalars['UUID'];
  updateProjectCostSettings: Scalars['UUID'];
  updateProjectDossierContacts: Array<ProjectDossierContact>;
  updateProjectStyles: Scalars['UUID'];
  updateRealEstateExternalAction: RealEstateExternalAction;
  updateRealEstateNoticationsDisabled: Scalars['Boolean'];
  updateRoom: Scalars['UUID'];
  updatesProductCategory: Scalars['UUID'];
};


export type MutationAddCustomOfferRequestAppointmentArgs = {
  appointmentDate: Scalars['DateTime'];
  appointmentType: CustomOfferAppointmentType;
  customOfferRequestId: Scalars['UUID'];
};


export type MutationAddCustomProductSetInfoKeyArgs = {
  dutchLabel: Scalars['String'];
  englishLabel: Scalars['String'];
  isDisplayable?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAddManualProductArgs = {
  priceType: ProjectActivityTarget;
  productCode: Scalars['String'];
  productDescription: Scalars['String'];
  productPriceEuros: Scalars['Decimal'];
  productSupplierCode?: InputMaybe<Scalars['String']>;
};


export type MutationAddProductPackageFavouriteArgs = {
  productPackageId: Scalars['UUID'];
  productSetId: Scalars['UUID'];
};


export type MutationAddToMySelectionArgs = {
  requestAddToSelectionItems: Array<RequestForQuoteAddToRoomSelectionDtoInput>;
};


export type MutationAssignBrandToProjectArgs = {
  brandId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationAssignDrawerTo3DDrawingArgs = {
  drawerId: Scalars['String'];
  requestId: Scalars['UUID'];
};


export type MutationAssignDrawerToTechnicalDrawingArgs = {
  drawerId: Scalars['String'];
  requestId: Scalars['UUID'];
};


export type MutationAssignProductPackageToProjectArgs = {
  productPackageId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationAssignSingleBrandToProjectArgs = {
  brandId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationAssignSingleProductPackageToProjectArgs = {
  productPackageId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationAssignStyleToProjectArgs = {
  projectId: Scalars['UUID'];
  styleId: Scalars['UUID'];
};


export type MutationCancel3DDrawingForRoomSelectionArgs = {
  request3DDrawingId: Scalars['UUID'];
};


export type MutationCancelCustomOfferChangeRequestArgs = {
  customOfferRequestId: Scalars['UUID'];
};


export type MutationCancelCustomOfferRequestArgs = {
  customOfferRequestId: Scalars['UUID'];
};


export type MutationCancelTechnicalDrawingArgs = {
  notes: Scalars['String'];
  requestTechnicalDrawingId: Scalars['UUID'];
};


export type MutationCheckCustomOfferForProjectArgs = {
  isActive: Scalars['Boolean'];
  projectId: Scalars['UUID'];
};


export type MutationCheckCustomOfferFromMySelectionArgs = {
  selectionId: Scalars['UUID'];
};


export type MutationCheckDivideCustomOfferByRoomForProjectArgs = {
  isActive: Scalars['Boolean'];
  projectId: Scalars['UUID'];
};


export type MutationCheckMySelectionArgs = {
  requestUpdateRoomSelections: Array<RequestForQuoteUpdateFromRoomSelectionDtoInput>;
};


export type MutationCheckUseReturnPriceCalculationForProjectArgs = {
  isActive: Scalars['Boolean'];
  projectId: Scalars['UUID'];
};


export type MutationCloseProjectArgs = {
  projectId: Scalars['UUID'];
};


export type MutationCompleteCustomOfferRequestArgs = {
  customOfferRequestId: Scalars['UUID'];
};


export type MutationCopyBrandArgs = {
  brandId: Scalars['UUID'];
  brandName: Scalars['String'];
};


export type MutationCopyProductPackageArgs = {
  name: Scalars['String'];
  productPackageId: Scalars['UUID'];
};


export type MutationCopyProductSetArgs = {
  name: Scalars['String'];
  productCombinationId: Scalars['UUID'];
  productPackageId: Scalars['UUID'];
  productSetId: Scalars['UUID'];
};


export type MutationCopyStyleArgs = {
  styleId: Scalars['UUID'];
  styleName: Scalars['String'];
};


export type MutationCreateActivityTemplateArgs = {
  activityTemplateName: Scalars['String'];
};


export type MutationCreateBrandArgs = {
  name: Scalars['String'];
};


export type MutationCreateCustomOfferArgs = {
  customOfferRequestId: Scalars['UUID'];
};


export type MutationCreateDesignPackageArgs = {
  brandId: Scalars['UUID'];
  designPackageDefaults?: InputMaybe<Array<AddDefaultProductSetDtoInput>>;
  designPackageName: Scalars['String'];
  designPackagePreselected?: InputMaybe<Array<AddPreselectedProductSetDtoInput>>;
  designPackageRestrictionValues?: InputMaybe<Array<TupleOfGuidAndStringInput>>;
  designPackageWalls?: InputMaybe<Array<DesignPackageWallDtoInput>>;
  hasBath: Scalars['Boolean'];
  hasBathtub?: InputMaybe<Scalars['Boolean']>;
  hasFountain?: InputMaybe<Scalars['Boolean']>;
  hasRadiator?: InputMaybe<Scalars['Boolean']>;
  hasShower?: InputMaybe<Scalars['Boolean']>;
  hasTiles?: InputMaybe<Scalars['Boolean']>;
  hasToilet?: InputMaybe<Scalars['Boolean']>;
  hasToiletLabel: Scalars['Boolean'];
  hasUtilities?: InputMaybe<Scalars['Boolean']>;
  hasWashbasin?: InputMaybe<Scalars['Boolean']>;
  isStandard: Scalars['Boolean'];
  isTilesToCeiling: Scalars['Boolean'];
  isXL: Scalars['Boolean'];
  position: Scalars['Int'];
  priceAmount: Scalars['String'];
  priceCurrency: Currency;
  priceDetails: Array<TupleOfCostTypeAndStringInput>;
  productPackageId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  roomId: Scalars['UUID'];
  styleId: Scalars['UUID'];
};


export type MutationCreateNoteArgs = {
  note: Scalars['String'];
  realEstateId: Scalars['UUID'];
};


export type MutationCreateProductCategoryArgs = {
  name: Scalars['String'];
  position: Scalars['Int'];
  productCombinationId: Scalars['UUID'];
};


export type MutationCreateProductPackageArgs = {
  name: Scalars['String'];
  productPackageType: ProjectTypeEnum;
};


export type MutationCreateProductSetArgs = {
  activities: Array<TupleOfGuidAndNullableOfBooleanInput>;
  costDetails: Array<TupleOfCostTypeAndStringAndBooleanInput>;
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  priceVariants: Array<TupleOfGuidAndPriceVariantInput>;
  productCodes: Array<TupleOfStringAndBooleanInput>;
  productPackageCategoryId: Scalars['UUID'];
  productSetBrandlineId?: InputMaybe<Scalars['UUID']>;
  productSetInfoValues: Array<TupleOfGuidAndStringInput>;
};


export type MutationCreateProductSetBrandlineArgs = {
  brandlineName: Scalars['String'];
  categoryId: Scalars['UUID'];
};


export type MutationCreateRoomArgs = {
  layoutIds: Array<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  roomName: Scalars['String'];
  roomPosition: Scalars['Int'];
};


export type MutationCreateStyleArgs = {
  name: Scalars['String'];
};


export type MutationDeleteActivityTemplateArgs = {
  activityTemplateId: Scalars['UUID'];
};


export type MutationDeleteBrandArgs = {
  brandId: Scalars['UUID'];
};


export type MutationDeleteCustomOfferArgs = {
  customOfferId: Scalars['UUID'];
};


export type MutationDeleteCustomOfferRequestAppointmentArgs = {
  customOfferRequestAppointmentId: Scalars['UUID'];
  customOfferRequestId: Scalars['UUID'];
};


export type MutationDeleteDesignPackageArgs = {
  designPackageId: Scalars['UUID'];
};


export type MutationDeleteProductPackageArgs = {
  productPackageId: Scalars['UUID'];
};


export type MutationDeleteProductSetArgs = {
  id: Scalars['UUID'];
  productPackageCategoryId: Scalars['UUID'];
};


export type MutationDeleteProductSetBrandlineArgs = {
  productSetBrandlineId: Scalars['UUID'];
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['UUID'];
};


export type MutationDeleteRealEstateFileArgs = {
  fileName: Scalars['String'];
  realEstateId: Scalars['UUID'];
};


export type MutationDeleteRoomArgs = {
  roomId: Scalars['UUID'];
};


export type MutationDeleteStyleArgs = {
  styleId: Scalars['UUID'];
};


export type MutationEndProjectArgs = {
  projectId: Scalars['UUID'];
};


export type MutationEndRealEstateExternalActionArgs = {
  closingNote: Scalars['String'];
  description: Scalars['String'];
  realEstateId: Scalars['UUID'];
  target: RealEstateExternalActionTargetType;
};


export type MutationEndRequestForContactArgs = {
  closingNote: Scalars['String'];
  messageId: Scalars['UUID'];
};


export type MutationEnrichProjectArgs = {
  layoutToCopyFromId: Scalars['UUID'];
  layoutToCopyTowardsId: Scalars['UUID'];
  projectToCopyFromId: Scalars['UUID'];
  projectToCopyTowardsId: Scalars['UUID'];
};


export type MutationForceCancel3DDrawingForRoomSelectionArgs = {
  request3DDrawingId: Scalars['UUID'];
};


export type MutationForceRealEstateSelectionArgs = {
  realEstateId: Scalars['UUID'];
  selectionType: SelectionType;
};


export type MutationGenerateVariantDesignPackagesArgs = {
  projectId: Scalars['UUID'];
};


export type MutationOpenRealEstateExternalActionArgs = {
  description: Scalars['String'];
  realEstateId: Scalars['UUID'];
  target: RealEstateExternalActionTargetType;
};


export type MutationProcessImportProductPackageArgs = {
  importProductPackageId: Scalars['UUID'];
  productPackageName: Scalars['String'];
  productSets: Array<ImportProductSetInput>;
};


export type MutationProjectActivityTemplateImportArgs = {
  activityTemplateId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationPublishProjectArgs = {
  projectId: Scalars['UUID'];
};


export type MutationReOpenCustomOfferRequestArgs = {
  customOfferRequestId: Scalars['UUID'];
};


export type MutationRemoveFromMySelectionArgs = {
  requestRemoveFromSelectionItems: Array<RequestForQuoteUpdateFromRoomSelectionDtoInput>;
};


export type MutationRemoveProductPackageFavouriteArgs = {
  productPackageId: Scalars['UUID'];
  productSetId: Scalars['UUID'];
};


export type MutationRequest3DDrawingForRoomSelectionArgs = {
  requestUpdateRoomSelections: Array<RequestForQuoteUpdateFromRoomSelectionDtoInput>;
};


export type MutationRequestChangesCustomOfferArgs = {
  changesNotes: Scalars['String'];
  customOfferRequestId: Scalars['UUID'];
};


export type MutationRequestForContactArgs = {
  contactType: ContactType;
  messageText: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationRequestForCustomOfferArgs = {
  appointmentType: CustomOfferAppointmentType;
  contactType?: InputMaybe<ContactType>;
  phone?: InputMaybe<Scalars['String']>;
  realEstateId?: InputMaybe<Scalars['UUID']>;
  requestNotes?: InputMaybe<Scalars['String']>;
  rooms?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationResetDesignPackageSelectionArgs = {
  realEstateId: Scalars['UUID'];
};


export type MutationResetProjectStatusArgs = {
  projectId: Scalars['UUID'];
};


export type MutationResetRealEstateSelectionsArgs = {
  realEstateId: Scalars['UUID'];
};


export type MutationResetUserPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResolveSharedWaitingCustomOfferArgs = {
  customOfferRequestId: Scalars['UUID'];
  notes: Scalars['String'];
};


export type MutationSaveCustomOfferArgs = {
  customOfferId: Scalars['UUID'];
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  priceCurrency: Currency;
  rooms: Array<CustomOfferRoomDtoInput>;
  shareWithCustomer: Scalars['Boolean'];
};


export type MutationSaveDesignPackagesArgs = {
  requestForQuoteSelectionItems: Array<RequestForQuoteRoomSelectionItemDtoInput>;
};


export type MutationSaveStandardSelectionArgs = {
  requestForQuoteSelectionItems: Array<RequestForQuoteRoomSelectionItemDtoInput>;
};


export type MutationSelectCustomDesignPackageArgs = {
  designPackageId: Scalars['UUID'];
};


export type MutationSelectStandardDesignPackageArgs = {
  designPackageId: Scalars['UUID'];
};


export type MutationSendInvitationArgs = {
  realEstateId: Scalars['UUID'];
};


export type MutationSendInvitationToRealEstatesArgs = {
  realEstateIds: Array<Scalars['UUID']>;
};


export type MutationSendNoLoginNotificationArgs = {
  realEstateId: Scalars['UUID'];
};


export type MutationSendNoQuoteNotificationArgs = {
  realEstateId: Scalars['UUID'];
};


export type MutationSendNotificationArgs = {
  fromEmailAddress: Scalars['String'];
  fromName?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  templateId: Scalars['String'];
  toEmailAddress: Scalars['String'];
};


export type MutationSendRealEstateDossierArgs = {
  emailRecipients: Array<Scalars['String']>;
  files: Array<TupleOfStringAndUploadFileTypeInput>;
  realEstateId: Scalars['UUID'];
};


export type MutationSendRealEstateQuoteArgs = {
  requestForQuoteGlobalSelectionItems: Array<RequestForQuoteGlobalSelectionItemDtoInput>;
  requestForQuoteRoomSelectionItems: Array<RequestForQuoteRoomSelectionItemDtoInput>;
};


export type MutationSetProductSetAvailabilityArgs = {
  id: Scalars['UUID'];
  isAvailable: Scalars['Boolean'];
  productPackageCategoryId: Scalars['UUID'];
};


export type MutationSetUserPasswordArgs = {
  oldPassword?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationUnassignBrandFromProjectArgs = {
  brandId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationUnassignProductPackageFromProjectArgs = {
  productPackageId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationUnassignStyleFromProjectArgs = {
  projectId: Scalars['UUID'];
  styleId: Scalars['UUID'];
};


export type MutationUncheckCustomOfferFromMySelectionArgs = {
  selectionId: Scalars['UUID'];
};


export type MutationUncheckMySelectionArgs = {
  requestUpdateRoomSelections: Array<RequestForQuoteUpdateFromRoomSelectionDtoInput>;
};


export type MutationUpdateActivityTemplateArgs = {
  activityTemplateId: Scalars['UUID'];
  costs: Array<ProjectActivityCostDtoInput>;
  projectActivityId: Scalars['UUID'];
};


export type MutationUpdateActivityTemplateNameArgs = {
  activityTemplateId: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationUpdateCustomOfferRequestArgs = {
  appointmentType: CustomOfferAppointmentType;
  assignedSpecialistId?: InputMaybe<Scalars['String']>;
  customOfferRequestId: Scalars['UUID'];
};


export type MutationUpdateCustomOfferRequestAppointmentArgs = {
  appointmentDate: Scalars['DateTime'];
  appointmentType: CustomOfferAppointmentType;
  customOfferRequestAppointmentId: Scalars['UUID'];
  customOfferRequestId: Scalars['UUID'];
};


export type MutationUpdateCustomOfferSharedArgs = {
  customOfferId: Scalars['UUID'];
  isShared: Scalars['Boolean'];
};


export type MutationUpdateDesignPackageArgs = {
  designPackageDefaults?: InputMaybe<Array<AddDefaultProductSetDtoInput>>;
  designPackageId: Scalars['UUID'];
  designPackageName: Scalars['String'];
  designPackagePreselected?: InputMaybe<Array<AddPreselectedProductSetDtoInput>>;
  designPackageRestrictionValues?: InputMaybe<Array<TupleOfGuidAndStringInput>>;
  designPackageWalls?: InputMaybe<Array<DesignPackageWallDtoInput>>;
  hasBath: Scalars['Boolean'];
  hasBathtub?: InputMaybe<Scalars['Boolean']>;
  hasFountain?: InputMaybe<Scalars['Boolean']>;
  hasRadiator?: InputMaybe<Scalars['Boolean']>;
  hasShower?: InputMaybe<Scalars['Boolean']>;
  hasTiles?: InputMaybe<Scalars['Boolean']>;
  hasToilet?: InputMaybe<Scalars['Boolean']>;
  hasToiletLabel: Scalars['Boolean'];
  hasUtilities?: InputMaybe<Scalars['Boolean']>;
  hasWashbasin?: InputMaybe<Scalars['Boolean']>;
  isStandard: Scalars['Boolean'];
  isTilesToCeiling: Scalars['Boolean'];
  isXL: Scalars['Boolean'];
  position: Scalars['Int'];
  priceAmount: Scalars['String'];
  priceCurrency: Currency;
  priceDetails: Array<TupleOfCostTypeAndStringInput>;
  projectId: Scalars['UUID'];
};


export type MutationUpdateImportProductPackageArgs = {
  importProductPackageId: Scalars['UUID'];
  isComplete: Scalars['Boolean'];
  isError: Scalars['Boolean'];
  logInformation?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMessageStatusArgs = {
  messageId: Scalars['UUID'];
  status: MessageStatus;
};


export type MutationUpdateMultipleProductSetPricesArgs = {
  activities: Array<TupleOfGuidAndNullableOfBooleanInput>;
  costDetails: Array<TupleOfCostTypeAndStringAndBooleanInput>;
  productPackageCategoryId: Scalars['UUID'];
  productSetIds: Array<Scalars['UUID']>;
  productSetInfos: Array<TupleOfGuidAndStringInput>;
};


export type MutationUpdateProductArgs = {
  brand?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  costPrice: Scalars['Decimal'];
  description?: InputMaybe<Scalars['String']>;
  grossSalesPrice: Scalars['Decimal'];
  isAvailable: Scalars['Boolean'];
  longDescription?: InputMaybe<Scalars['String']>;
  marktingText?: InputMaybe<Scalars['String']>;
  statusCode: Scalars['Int'];
  supplierCode?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductPackageArgs = {
  productPackageId: Scalars['UUID'];
  productPackageName: Scalars['String'];
};


export type MutationUpdateProductPackageCategoryArgs = {
  allowPickByBrand: Scalars['Boolean'];
  categoryId: Scalars['UUID'];
  productPackageId: Scalars['UUID'];
};


export type MutationUpdateProductReturnPriceArgs = {
  productCombinationId: Scalars['UUID'];
  productId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  returnPrice: Scalars['String'];
};


export type MutationUpdateProductSetArgs = {
  activities: Array<TupleOfGuidAndNullableOfBooleanInput>;
  costDetails: Array<TupleOfCostTypeAndStringAndBooleanInput>;
  id: Scalars['UUID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  priceVariants: Array<TupleOfGuidAndPriceVariantInput>;
  productCodes: Array<TupleOfStringAndBooleanInput>;
  productPackageCategoryId: Scalars['UUID'];
  productSetBrandlineId?: InputMaybe<Scalars['UUID']>;
  productSetInfoValues: Array<TupleOfGuidAndStringInput>;
};


export type MutationUpdateProjectActivityCostsArgs = {
  costs: Array<ProjectActivityCostDtoInput>;
  projectActivityId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


export type MutationUpdateProjectBrandsArgs = {
  brandIds: Array<Scalars['UUID']>;
  projectId: Scalars['UUID'];
};


export type MutationUpdateProjectCostSettingsArgs = {
  companyMarginSanitary?: InputMaybe<Scalars['String']>;
  companyMarginTiles?: InputMaybe<Scalars['String']>;
  correctionConstructionFactor?: InputMaybe<Scalars['String']>;
  correctionInstallationCVFactor?: InputMaybe<Scalars['String']>;
  correctionInstallationEFactor?: InputMaybe<Scalars['String']>;
  correctionInstallationWFactor?: InputMaybe<Scalars['String']>;
  correctionTilerFactor?: InputMaybe<Scalars['String']>;
  marginBuilder?: InputMaybe<Scalars['String']>;
  marginInstaller?: InputMaybe<Scalars['String']>;
  marginTiler?: InputMaybe<Scalars['String']>;
  projectId: Scalars['UUID'];
};


export type MutationUpdateProjectDossierContactsArgs = {
  contacts: Array<ProjectDossierContactDtoInput>;
  projectId: Scalars['UUID'];
};


export type MutationUpdateProjectStylesArgs = {
  projectId: Scalars['UUID'];
  styleIds: Array<Scalars['UUID']>;
};


export type MutationUpdateRealEstateExternalActionArgs = {
  description: Scalars['String'];
  realEstateId: Scalars['UUID'];
  target: RealEstateExternalActionTargetType;
};


export type MutationUpdateRealEstateNoticationsDisabledArgs = {
  disabled: Scalars['Boolean'];
  realEstateId: Scalars['UUID'];
};


export type MutationUpdateRoomArgs = {
  layoutIds: Array<Scalars['UUID']>;
  roomId: Scalars['UUID'];
  roomName: Scalars['String'];
  roomPosition: Scalars['Int'];
};


export type MutationUpdatesProductCategoryArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  productCombinationId: Scalars['UUID'];
  productSetInfoKeys: Array<ProductCategoryPropertyKeyDtoInput>;
};

export type NavigationTitle = {
  __typename?: 'NavigationTitle';
  /** Text field */
  navigationtitle?: Maybe<Scalars['String']>;
};

export type NullableOfContactRequestStatusOperationFilterInput = {
  eq?: InputMaybe<ContactRequestStatus>;
  in?: InputMaybe<Array<InputMaybe<ContactRequestStatus>>>;
  neq?: InputMaybe<ContactRequestStatus>;
  nin?: InputMaybe<Array<InputMaybe<ContactRequestStatus>>>;
};

export type NullableOfContactTypeOperationFilterInput = {
  eq?: InputMaybe<ContactType>;
  in?: InputMaybe<Array<InputMaybe<ContactType>>>;
  neq?: InputMaybe<ContactType>;
  nin?: InputMaybe<Array<InputMaybe<ContactType>>>;
};

export type NullableOfCustomOfferRequestStatusOperationFilterInput = {
  eq?: InputMaybe<CustomOfferRequestStatus>;
  in?: InputMaybe<Array<InputMaybe<CustomOfferRequestStatus>>>;
  neq?: InputMaybe<CustomOfferRequestStatus>;
  nin?: InputMaybe<Array<InputMaybe<CustomOfferRequestStatus>>>;
};

export type NullableOfDrawingRequestStatusOperationFilterInput = {
  eq?: InputMaybe<DrawingRequestStatus>;
  in?: InputMaybe<Array<InputMaybe<DrawingRequestStatus>>>;
  neq?: InputMaybe<DrawingRequestStatus>;
  nin?: InputMaybe<Array<InputMaybe<DrawingRequestStatus>>>;
};

export type NullableOfPriceVariantOperationFilterInput = {
  eq?: InputMaybe<PriceVariant>;
  in?: InputMaybe<Array<InputMaybe<PriceVariant>>>;
  neq?: InputMaybe<PriceVariant>;
  nin?: InputMaybe<Array<InputMaybe<PriceVariant>>>;
};

export type NullableOfProductSetInfoCompareOperationOperationFilterInput = {
  eq?: InputMaybe<ProductSetInfoCompareOperation>;
  in?: InputMaybe<Array<InputMaybe<ProductSetInfoCompareOperation>>>;
  neq?: InputMaybe<ProductSetInfoCompareOperation>;
  nin?: InputMaybe<Array<InputMaybe<ProductSetInfoCompareOperation>>>;
};

export type NullableOfProductSetInfoKeyTypeOperationFilterInput = {
  eq?: InputMaybe<ProductSetInfoKeyType>;
  in?: InputMaybe<Array<InputMaybe<ProductSetInfoKeyType>>>;
  neq?: InputMaybe<ProductSetInfoKeyType>;
  nin?: InputMaybe<Array<InputMaybe<ProductSetInfoKeyType>>>;
};

export type NullableOfProjectTypeOperationFilterInput = {
  eq?: InputMaybe<Badkamercore_ProjectType>;
  in?: InputMaybe<Array<InputMaybe<Badkamercore_ProjectType>>>;
  neq?: InputMaybe<Badkamercore_ProjectType>;
  nin?: InputMaybe<Array<InputMaybe<Badkamercore_ProjectType>>>;
};

export type NullableOfShowroomLocationOperationFilterInput = {
  eq?: InputMaybe<ShowroomLocation>;
  in?: InputMaybe<Array<InputMaybe<ShowroomLocation>>>;
  neq?: InputMaybe<ShowroomLocation>;
  nin?: InputMaybe<Array<InputMaybe<ShowroomLocation>>>;
};

export enum OperationType {
  Contact = 'CONTACT',
  CustomOffer = 'CUSTOM_OFFER',
  DrawingRequest = 'DRAWING_REQUEST',
  ExternalAction = 'EXTERNAL_ACTION',
  Note = 'NOTE'
}

export type OperationTypeOperationFilterInput = {
  eq?: InputMaybe<OperationType>;
  in?: InputMaybe<Array<OperationType>>;
  neq?: InputMaybe<OperationType>;
  nin?: InputMaybe<Array<OperationType>>;
};

/** Articles information. */
export type OrderArticle = {
  __typename?: 'OrderArticle';
  /** Gets or sets article identifier. */
  articleId: Scalars['UUID'];
  /** Gets or sets article code. */
  code: Scalars['String'];
  /** Gets or sets article code. */
  name?: Maybe<Scalars['String']>;
  /** Gets or sets article price. */
  price: Money;
  priceType: ProjectActivityTarget;
  /** Gets or sets supplier code. */
  supplierCode?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

/** the order by direction */
export enum OrderByDirection {
  /** orders content items in ascending order */
  Asc = 'ASC',
  /** orders content items in descending order */
  Desc = 'DESC'
}

/** Represents a Order Confirmation Page. */
export type OrderConfirmationPage = ContentItem & {
  __typename?: 'OrderConfirmationPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  bottomContent?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  homeButton?: Maybe<Scalars['String']>;
  /** MediaField */
  image?: Maybe<MediaField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Represents a ProjectType. */
  projectType?: Maybe<ProjectType>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type OrderConfirmationPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the OrderConfirmationPage content item filters */
export type OrderConfirmationPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<OrderConfirmationPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<OrderConfirmationPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<OrderConfirmationPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Order global custom offer information. */
export type OrderCustomOffer = {
  __typename?: 'OrderCustomOffer';
  /** Gets or sets confirmed status type. */
  confirmedStatusType: ConfirmedStatusType;
  /** Gets or sets the selection costs. */
  costs: Array<Maybe<CostEntry>>;
  /** Gets or sets the custom offer identifier. */
  customOfferId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the room selection identifier. */
  selectionId: Scalars['UUID'];
  /** Gets or sets room selection name. */
  selectionName: Scalars['String'];
  /** Gets or sets selection type. */
  selectionType: SelectionType;
};

/** Product combination sets information. */
export type OrderProductCombinationSet = {
  __typename?: 'OrderProductCombinationSet';
  /** Gets or sets the product combination costs. */
  costs: Array<Maybe<CostEntry>>;
  /** Gets the combination position. */
  position: Scalars['Int'];
  /** Gets or sets product combination type. */
  productCombinationId: Scalars['UUID'];
  /** Gets or sets product combination type. */
  productCombinationType: ProductCombinationType;
  /** Gets or sets returned product sets information. */
  returnedProductSets: Array<OrderProductSet>;
  /** Gets or sets selected product sets information. */
  selectedProductSets: Array<OrderProductSet>;
};

/** Product sets information. */
export type OrderProductSet = {
  __typename?: 'OrderProductSet';
  /** Gets or sets the answer yes no. */
  answerYesNo?: Maybe<Scalars['String']>;
  /** Gets or sets the area. */
  area?: Maybe<Scalars['Decimal']>;
  /** Gets or sets articles information. */
  articles: Array<OrderArticle>;
  /** Gets or sets the product set costs. */
  costs: Array<Maybe<CostEntry>>;
  displayableProductSetInfos?: Maybe<Array<Maybe<ProductSetInfo>>>;
  /** Gets or sets the fallback product identifier. */
  fallBackProductId?: Maybe<Scalars['UUID']>;
  /** Gets or sets a value indicating whether gets or sets is default. */
  isDefault: Scalars['Boolean'];
  /** Gets or sets the is to the ceiling. */
  isToTheCeiling?: Maybe<Scalars['Boolean']>;
  /** Gets or sets the length. */
  length?: Maybe<Scalars['Decimal']>;
  /** Gets or sets the product set name. */
  name: Scalars['String'];
  /** Gets or sets the product category identifier. */
  productCategoryId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the product set name. */
  productSetId: Scalars['UUID'];
  /** Gets or sets the wall identifier. */
  wallId?: Maybe<Scalars['UUID']>;
};


/** Product sets information. */
export type OrderProductSetDisplayableProductSetInfosArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<ProductSetInfoSortInput>>;
  where?: InputMaybe<ProductSetInfoFilterInput>;
};

/** Order room information. */
export type OrderRoom = {
  __typename?: 'OrderRoom';
  /** Gets or sets the brand identifier. */
  brandId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the brand name. */
  brandName?: Maybe<Scalars['String']>;
  /** Gets or sets confirmed status type. */
  confirmedStatusType: ConfirmedStatusType;
  /** Gets or sets the selection costs. */
  costs: Array<Maybe<CostEntry>>;
  /** Gets or sets the custom offer identifier. */
  customOfferId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the custom offer version. */
  customOfferVersion?: Maybe<Scalars['Int']>;
  /** Gets or sets design package identifier. */
  designPackageId?: Maybe<Scalars['UUID']>;
  /** Gets or sets product combination sets information. */
  productCombinationSets?: Maybe<Array<OrderProductCombinationSet>>;
  /** Gets or sets the product package identifier. */
  productPackageId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the product package name. */
  productPackageName?: Maybe<Scalars['String']>;
  /** Gets or sets room id. */
  roomId: Scalars['UUID'];
  /** Gets or sets room name. */
  roomName: Scalars['String'];
  /** Gets or sets the room selection identifier. */
  selectionId: Scalars['UUID'];
  /** Gets or sets room selection name. */
  selectionName: Scalars['String'];
  /** Gets or sets selection type. */
  selectionType: SelectionType;
  /** Gets or sets the style identifier. */
  styleId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the style name. */
  styleName?: Maybe<Scalars['String']>;
};

/** Contains information about the project overview. */
export type OverviewDto = {
  __typename?: 'OverviewDto';
  /** Gets or sets contains the cost overview specifications. */
  costOverviewSpecifications?: Maybe<Array<KeyValuePairOfCostTypeAndMoney>>;
  /** Gets or sets the products combinations cost overviews. */
  productCombinationCostOverviews?: Maybe<Array<ProductCombinationCostOverviewDto>>;
};

/** PhoneNumber object describes a phone number. */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /** Gets the phone number. */
  number: Scalars['String'];
};

/** PhoneNumber object describes a phone number. */
export type PhoneNumberFilterInput = {
  and?: InputMaybe<Array<PhoneNumberFilterInput>>;
  /** Gets the phone number. */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PhoneNumberFilterInput>>;
};

/** PhoneNumber object describes a phone number. */
export type PhoneNumberSortInput = {
  /** Gets the phone number. */
  number?: InputMaybe<SortEnumType>;
};

/** Price variant for Design Packages and Product Sets */
export enum PriceVariant {
  /** Lower price variant. */
  Comfort = 'COMFORT',
  /** Higher price variant. */
  Excellent = 'EXCELLENT',
  /** Intermediate price variant. */
  Premium = 'PREMIUM'
}

export type PriceVariantOperationFilterInput = {
  eq?: InputMaybe<PriceVariant>;
  in?: InputMaybe<Array<PriceVariant>>;
  neq?: InputMaybe<PriceVariant>;
  nin?: InputMaybe<Array<PriceVariant>>;
};

/** Product item represents a single product that is used in product sets. */
export type Product = {
  __typename?: 'Product';
  /** Gets the brand of the product. */
  brand?: Maybe<Scalars['String']>;
  /** Gets item code */
  code: Scalars['String'];
  /** Gets the cost price of the product. */
  costPrice: Money;
  /** Gets the description of the product. */
  description?: Maybe<Scalars['String']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the long description of the product. */
  longDescription?: Maybe<Scalars['String']>;
  /** Gets the marketing text of the product. */
  marketingText?: Maybe<Scalars['String']>;
  /** Gets the amount of m2/m1 per unit in a package of the product */
  packagingUnits: Scalars['String'];
  /** Gets the price type. */
  priceType: ProjectActivityTarget;
  /** Gets the status code of the product. */
  statusCode: Scalars['Int'];
  thumbnailUrl?: Maybe<Scalars['URL']>;
};

/** Represents a Product Brand Line Details. */
export type ProductBrandLineDetails = ContentItem & {
  __typename?: 'ProductBrandLineDetails';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  detailsLink?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** MediaField */
  images?: Maybe<MediaField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
};

export type ProductBrandLineDetailsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductBrandLineDetails content item filters */
export type ProductBrandLineDetailsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductBrandLineDetailsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductBrandLineDetailsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductBrandLineDetailsWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategory = {
  __typename?: 'ProductCategory';
  /** Gets the brand lines assigned to this category. */
  assignedBrandlines?: Maybe<Array<Maybe<ProductSetBrandline>>>;
  /** Gets the collection of ProductCategoryProductSetInfoKey objects that belongs to this category. */
  assignedProductCategoryProductSetInfoKeys?: Maybe<Array<Maybe<ProductCategoryProductSetInfoKey>>>;
  /**
   * Gets the header of the brandline.
   * @deprecated This field was replaced by content in CMS
   */
  brandlineHeader: Scalars['String'];
  /** @deprecated This field was replaced by content in CMS */
  categoryBrandlineHeader: Scalars['String'];
  /** @deprecated This field was replaced by content in CMS */
  categoryName: Scalars['String'];
  /** @deprecated This field was replaced by content in CMS */
  categoryTitle: Scalars['String'];
  /** Gets a value indicating whether the ProductCategory has a Yes/No question. */
  hasYesNoQuestion: Scalars['Boolean'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  isAccentTiles: Scalars['Boolean'];
  /** Gets a value indicating whether the ProductCategory is the accent wall tiles category. */
  isAccentWallTiles: Scalars['Boolean'];
  /** Gets a value indicating whether the ProductCategory is hidden. */
  isHidden: Scalars['Boolean'];
  /** Gets a value indicating whether the ProductCategory selection is optional. */
  isSelectionOptional: Scalars['Boolean'];
  isToTheCeiling: Scalars['Boolean'];
  /** Gets the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategory?: Maybe<ProductCategory>;
  /** Gets the identifier of the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategoryId?: Maybe<Scalars['UUID']>;
  /** Gets the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategory?: Maybe<ProductCategory>;
  /** Gets the identifier of the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategoryId?: Maybe<Scalars['UUID']>;
  /** Gets the name of the product combination. */
  name: Scalars['String'];
  /** Gets the identifier of the ProductSet that is the No Product for this Category. */
  noProductProductSetId?: Maybe<Scalars['UUID']>;
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position: Scalars['Int'];
  /** Gets the ProductCombination the Category is attached. */
  productCombination?: Maybe<ProductCombination>;
  /**
   * Gets the title of the product category.
   * @deprecated This field was replaced by content in CMS
   */
  title: Scalars['String'];
  /**
   * Gets the translated header of the BrandlineHeader.
   * @deprecated This field was replaced by content in CMS
   */
  translatedBrandlineHeader?: Maybe<Scalars['String']>;
  /**
   * Gets the translated name of the product category.
   * @deprecated This field was replaced by content in CMS
   */
  translatedName?: Maybe<Scalars['String']>;
  /**
   * Gets the translated title of the product category.
   * @deprecated This field was replaced by content in CMS
   */
  translatedTitle?: Maybe<Scalars['String']>;
};


/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategoryAssignedProductCategoryProductSetInfoKeysArgs = {
  order?: InputMaybe<Array<ProductCategoryProductSetInfoKeySortInput>>;
  where?: InputMaybe<ProductCategoryProductSetInfoKeyFilterInput>;
};


/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategoryCategoryBrandlineHeaderArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategoryCategoryNameArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategoryCategoryTitleArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};

/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategoryFilterInput = {
  and?: InputMaybe<Array<ProductCategoryFilterInput>>;
  /** Gets the brand lines assigned to this category. */
  assignedBrandlines?: InputMaybe<ListFilterInputTypeOfProductSetBrandlineFilterInput>;
  /** Gets the collection of ProductCategoryProductSetInfoKey objects that belongs to this category. */
  assignedProductCategoryProductSetInfoKeys?: InputMaybe<ListFilterInputTypeOfProductCategoryProductSetInfoKeyFilterInput>;
  /** Gets the collection of ProductSetRestriction objects that compose this category. */
  assignedProductSetRestrictions?: InputMaybe<ListFilterInputTypeOfProductSetRestrictionFilterInput>;
  /** Gets the header of the brandline. */
  brandlineHeader?: InputMaybe<StringOperationFilterInput>;
  /** Gets a value indicating whether the ProductCategory has a Yes/No question. */
  hasYesNoQuestion?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the ProductCategory is the accent wall tiles category. */
  isAccentWallTiles?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the ProductCategory is hidden. */
  isHidden?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the ProductCategory selection is optional. */
  isSelectionOptional?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the identifier of the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the identifier of the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name of the product combination. */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Gets the ProductSet that is the No Product Needed for this Category. */
  noProductNeededProductSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the identifier of the ProductSet that is the No Product Needed for this Category. */
  noProductNeededProductSetId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the ProductSet that is the No Product for this Category. */
  noProductProductSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the identifier of the ProductSet that is the No Product for this Category. */
  noProductProductSetId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductCategoryFilterInput>>;
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets the ProductCombination the Category is attached. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the identifier of the ProductCombination the Category is attached. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the title of the product category. */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Gets the translated header of the BrandlineHeader. */
  translatedBrandlineHeader?: InputMaybe<StringOperationFilterInput>;
  /** Gets the translated name of the product category. */
  translatedName?: InputMaybe<StringOperationFilterInput>;
  /** Gets the translated title of the product category. */
  translatedTitle?: InputMaybe<StringOperationFilterInput>;
  /** Gets the ProductSet that is the "No" answer to a YesNoQuestion. */
  yesNoQuestionNoProductSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the identifier of the ProductSet that is the "No" answer to a YesNoQuestion. */
  yesNoQuestionNoProductSetId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the ProductSet that is the "Yes" answer to a YesNoQuestion. */
  yesNoQuestionYesProductSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the identifier of the ProductSet that is the "Yes" answer to a YesNoQuestion. */
  yesNoQuestionYesProductSetId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProductCategoryProductSetInfoKey represents a valid key of information about a single ProductSet item of a ProductCategory. */
export type ProductCategoryProductSetInfoKey = {
  __typename?: 'ProductCategoryProductSetInfoKey';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether the property can be displayed to Customer. */
  isDisplayable: Scalars['Boolean'];
  /** Gets a value indicating whether the property can be used for filtering. */
  isFilterable: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  /** Gets a value indicating whether the property is optional. */
  isOptional: Scalars['Boolean'];
  /** Gets the integer by which the Category InfoKey should be ordered when displaying. */
  position: Scalars['Int'];
  productCategory: ProductCategory;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey: ProductSetInfoKey;
};


/** ProductCategoryProductSetInfoKey represents a valid key of information about a single ProductSet item of a ProductCategory. */
export type ProductCategoryProductSetInfoKeyProductCategoryArgs = {
  order?: InputMaybe<Array<ProductCategorySortInput>>;
  where?: InputMaybe<ProductCategoryFilterInput>;
};


/** ProductCategoryProductSetInfoKey represents a valid key of information about a single ProductSet item of a ProductCategory. */
export type ProductCategoryProductSetInfoKeyProductSetInfoKeyArgs = {
  where?: InputMaybe<ProductSetInfoKeyFilterInput>;
};

/** ProductCategoryProductSetInfoKey represents a valid key of information about a single ProductSet item of a ProductCategory. */
export type ProductCategoryProductSetInfoKeyFilterInput = {
  and?: InputMaybe<Array<ProductCategoryProductSetInfoKeyFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the property can be displayed to Customer. */
  isDisplayable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the property can be used for filtering. */
  isFilterable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the property is optional. */
  isOptional?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProductCategoryProductSetInfoKeyFilterInput>>;
  /** Gets the integer by which the Category InfoKey should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets or sets the product combination of the relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyFilterInput>;
  /** Gets the product set information key id of the relation. */
  productSetInfoKeyId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProductCategoryProductSetInfoKey represents a valid key of information about a single ProductSet item of a ProductCategory. */
export type ProductCategoryProductSetInfoKeySortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the property can be displayed to Customer. */
  isDisplayable?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the property can be used for filtering. */
  isFilterable?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the property is optional. */
  isOptional?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the Category InfoKey should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
  /** Gets or sets the product combination of the relation. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** Gets or sets the product set information key of the relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeySortInput>;
  /** Gets the product set information key id of the relation. */
  productSetInfoKeyId?: InputMaybe<SortEnumType>;
};

/** Contains information about a single ProductSetInfoKey assigned to a ProductCategory. */
export type ProductCategoryPropertyKeyDtoInput = {
  /** Gets a value indicating whether the property can be displayed to Customer. */
  isDisplayable: Scalars['Boolean'];
  /** Gets a value indicating whether the property can be used for filtering. */
  isFilterable: Scalars['Boolean'];
  /** Gets a value indicating whether the property is optional. */
  isOptional: Scalars['Boolean'];
  /** Gets the integer by which the Category InfoKey should be ordered when displaying. */
  position: Scalars['Int'];
  /** Gets the ProductCategoryProductSetInfoKey identifier. */
  productCategoryProductSetInfoKeyId?: InputMaybe<Scalars['UUID']>;
  /** Gets the ProductSetInfoKey identifier. */
  productSetInfoKeyId: Scalars['UUID'];
};

/** Contains information about the selected product category and product set. */
export type ProductCategorySet = {
  __typename?: 'ProductCategorySet';
  /** Gets or sets the id of the product category. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets the id of the product set. */
  productSetId: Scalars['UUID'];
  /** Gets or sets the wall identifier where the selected ProductSet will be placed. */
  wallId?: Maybe<Scalars['UUID']>;
};

/** Contains information about the selected product category and product set. */
export type ProductCategorySetInput = {
  /** Gets or sets the id of the product category. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets the id of the product set. */
  productSetId: Scalars['UUID'];
  /** Gets or sets the wall identifier where the selected ProductSet will be placed. */
  wallId?: InputMaybe<Scalars['UUID']>;
};

/**
 * ProductCategory represents a category of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCategory
 */
export type ProductCategorySortInput = {
  /** Gets the header of the brandline. */
  brandlineHeader?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductCategory has a Yes/No question. */
  hasYesNoQuestion?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductCategory is the accent wall tiles category. */
  isAccentWallTiles?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductCategory is hidden. */
  isHidden?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductCategory selection is optional. */
  isSelectionOptional?: InputMaybe<SortEnumType>;
  /** Gets the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the identifier of the additional ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromAdditionalCategoryId?: InputMaybe<SortEnumType>;
  /** Gets the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the identifier of the ProductCategory that is used to load ProductSets for this ProductCategory. */
  loadProductSetsFromCategoryId?: InputMaybe<SortEnumType>;
  /** Gets the name of the product combination. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the ProductSet that is the No Product Needed for this Category. */
  noProductNeededProductSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the identifier of the ProductSet that is the No Product Needed for this Category. */
  noProductNeededProductSetId?: InputMaybe<SortEnumType>;
  /** Gets the ProductSet that is the No Product for this Category. */
  noProductProductSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the identifier of the ProductSet that is the No Product for this Category. */
  noProductProductSetId?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
  /** Gets the ProductCombination the Category is attached. */
  productCombination?: InputMaybe<ProductCombinationSortInput>;
  /** Gets the identifier of the ProductCombination the Category is attached. */
  productCombinationId?: InputMaybe<SortEnumType>;
  /** Gets the title of the product category. */
  title?: InputMaybe<SortEnumType>;
  /** Gets the translated header of the BrandlineHeader. */
  translatedBrandlineHeader?: InputMaybe<SortEnumType>;
  /** Gets the translated name of the product category. */
  translatedName?: InputMaybe<SortEnumType>;
  /** Gets the translated title of the product category. */
  translatedTitle?: InputMaybe<SortEnumType>;
  /** Gets the ProductSet that is the "No" answer to a YesNoQuestion. */
  yesNoQuestionNoProductSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the identifier of the ProductSet that is the "No" answer to a YesNoQuestion. */
  yesNoQuestionNoProductSetId?: InputMaybe<SortEnumType>;
  /** Gets the ProductSet that is the "Yes" answer to a YesNoQuestion. */
  yesNoQuestionYesProductSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the identifier of the ProductSet that is the "Yes" answer to a YesNoQuestion. */
  yesNoQuestionYesProductSetId?: InputMaybe<SortEnumType>;
};

/**
 * ProductCombination represents a combination of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCombination
 */
export type ProductCombination = {
  __typename?: 'ProductCombination';
  /** Gets the type of combination. */
  combinationType: ProductCombinationType;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of the product combination. */
  name: Scalars['String'];
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position: Scalars['Int'];
  /**
   * Value that indicated if a filter for taps position is to be displayed.
   *
   *
   * **Returns:**
   * If a filter for Built in taps should be displayed.
   */
  showBuiltInOption: Scalars['Boolean'];
};

/** Contains information about a product combination cost overview. */
export type ProductCombinationCostOverviewDto = {
  __typename?: 'ProductCombinationCostOverviewDto';
  /** Gets or sets the product combination cost overviews. */
  productCombinationCostOverviewSpecification?: Maybe<Array<KeyValuePairOfCostTypeAndMoney>>;
  /** Gets or sets the type of the product combination. */
  productCombinationType: ProductCombinationType;
  /** Gets or sets the product package sets selected on each category. */
  productPackageSets?: Maybe<Array<KeyValuePairOfSelectedProductSetDictionaryKeyAndProductPackageSet>>;
};

/**
 * ProductCombination represents a combination of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCombination
 */
export type ProductCombinationFilterInput = {
  and?: InputMaybe<Array<ProductCombinationFilterInput>>;
  /** Gets the categories assigned to this combination. */
  assignedCategories?: InputMaybe<ListFilterInputTypeOfProductCategoryFilterInput>;
  /** Gets the product packages assigned restrictions to this project. */
  assignedRestrictions?: InputMaybe<ListFilterInputTypeOfProductCombinationRestrictionFilterInput>;
  /** Gets the type of combination. */
  combinationType?: InputMaybe<ProductCombinationTypeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name of the product combination. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductCombinationFilterInput>>;
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
};

/** Available product set info Keys in badkamer */
export enum ProductCombinationInfoKeyType {
  /** Has some walls for accent tiles. */
  HasAccentWalls = 'HAS_ACCENT_WALLS',
  /** Has BATHTUB combination. */
  HasBathtubCombination = 'HAS_BATHTUB_COMBINATION',
  /** Has some bath casing area to be filled with tiles. */
  HasBathCasingArea = 'HAS_BATH_CASING_AREA',
  /** Has built-in taps */
  HasBuiltintaps = 'HAS_BUILTINTAPS',
  /** Has FOUNTAIN combination. */
  HasFountainCombination = 'HAS_FOUNTAIN_COMBINATION',
  /** Has RADIATOR combination. */
  HasRadiatorCombination = 'HAS_RADIATOR_COMBINATION',
  /** Has SHOWER combination. */
  HasShowerCombination = 'HAS_SHOWER_COMBINATION',
  /** Has some shower floor area to be filled with tiles. */
  HasShowerFloorArea = 'HAS_SHOWER_FLOOR_AREA',
  /** Has some shower partition wall area to be filled with tiles. */
  HasShowerPartitionwallArea = 'HAS_SHOWER_PARTITIONWALL_AREA',
  /** Has TILES combination. */
  HasTilesCombination = 'HAS_TILES_COMBINATION',
  /** Has some toilet casing area to be filled with tiles. */
  HasToiletCasingArea = 'HAS_TOILET_CASING_AREA',
  /** Has TOILET combination. */
  HasToiletCombination = 'HAS_TOILET_COMBINATION',
  /** Has some plafond are between tiles and ceiling that can be covered with additional tiles. */
  HasToCeilingArea = 'HAS_TO_CEILING_AREA',
  /** Has WASHBASIN combination. */
  HasWashbasinCombination = 'HAS_WASHBASIN_COMBINATION',
  /** Not set */
  NotSet = 'NOT_SET'
}

export type ProductCombinationInfoKeyTypeOperationFilterInput = {
  eq?: InputMaybe<ProductCombinationInfoKeyType>;
  in?: InputMaybe<Array<ProductCombinationInfoKeyType>>;
  neq?: InputMaybe<ProductCombinationInfoKeyType>;
  nin?: InputMaybe<Array<ProductCombinationInfoKeyType>>;
};

/** Represents a Product Combination Page. */
export type ProductCombinationPage = ContentItem & {
  __typename?: 'ProductCombinationPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** Text field */
  disabledText?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  iconName?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Numeric field */
  position?: Maybe<Scalars['Decimal']>;
  /** Text field */
  positionSelectionTitle?: Maybe<Scalars['String']>;
  /** Text field */
  productCombinationType?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type ProductCombinationPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductCombinationPage content item filters */
export type ProductCombinationPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductCombinationPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductCombinationPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductCombinationPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Product Combination Position. */
export type ProductCombinationPosition = ContentItem & {
  __typename?: 'ProductCombinationPosition';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** MediaField */
  image?: Maybe<MediaField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  position?: Maybe<Scalars['String']>;
  /** Text field */
  priceRange?: Maybe<Scalars['String']>;
  /** Text field */
  productCombinationType?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  tooltip?: Maybe<Scalars['String']>;
};

export type ProductCombinationPositionOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductCombinationPosition content item filters */
export type ProductCombinationPositionWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductCombinationPositionWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductCombinationPositionWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductCombinationPositionWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/**
 * ProductCombinationRestriction represents a restriction on using a ProductSet in a ProductCombination.
 * Restriction can be because of room dimensions or other.
 */
export type ProductCombinationRestrictionFilterInput = {
  and?: InputMaybe<Array<ProductCombinationRestrictionFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductCombinationRestrictionFilterInput>>;
  /** Gets or sets the ProductCategory parent on this relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets or sets the id of the ProductCategory parent on this relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product combination of the relation. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the product combination id of the relation. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the product combination info key that triggers the rule. */
  productCombinationInfoKey?: InputMaybe<ProductCombinationInfoKeyTypeOperationFilterInput>;
  /** Gets the product combination info value that triggers the rule. */
  productCombinationInfoValue?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the compare operation to preform. */
  productSetInfoCompareOperation?: InputMaybe<ProductSetInfoCompareOperationOperationFilterInput>;
  /** Gets the 2nd compare operation to preform. */
  productSetInfoCompareOperation2?: InputMaybe<NullableOfProductSetInfoCompareOperationOperationFilterInput>;
  /** Gets or sets the value to be used in the compare operation. */
  productSetInfoCompareValue?: InputMaybe<StringOperationFilterInput>;
  /** Gets the 2nd value to be used in the compare operation. */
  productSetInfoCompareValue2?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the product set information key to be used to compare. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
  /** Gets the 2nd product set information key to be used to compare. */
  productSetInfoKey2?: InputMaybe<NullableOfProductSetInfoKeyTypeOperationFilterInput>;
};

/** Contains information about the selected product combination and product category sets. */
export type ProductCombinationSets = {
  __typename?: 'ProductCombinationSets';
  /** Gets or sets the list of selected ProductCategorySet. */
  productCategorySets?: Maybe<Array<ProductCategorySet>>;
  /** Gets or sets the id of the product combination. */
  productCombinationId: Scalars['UUID'];
};

/** Contains information about the selected product combination and product category sets. */
export type ProductCombinationSetsInput = {
  /** Gets or sets the list of selected ProductCategorySet. */
  productCategorySets?: InputMaybe<Array<ProductCategorySetInput>>;
  /** Gets or sets the id of the product combination. */
  productCombinationId: Scalars['UUID'];
};

/**
 * ProductCombination represents a combination of product sets present in one ProductPackage.
 * ProductSet are grouped into ProductCombination
 */
export type ProductCombinationSortInput = {
  /** Gets the type of combination. */
  combinationType?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of the product combination. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the ProductCombination should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
};

/** Available product combinations in badkamer */
export enum ProductCombinationType {
  /** Bathtub */
  Bathtub = 'BATHTUB',
  /** Fountain */
  Fountain = 'FOUNTAIN',
  /** Value not Set */
  NotSet = 'NOT_SET',
  /** Radiator */
  Radiator = 'RADIATOR',
  /** Shower */
  Shower = 'SHOWER',
  /** Tiles */
  Tiles = 'TILES',
  /** Toilet */
  Toilet = 'TOILET',
  /** Utilities */
  Utilities = 'UTILITIES',
  /** Wash basin */
  Washbasin = 'WASHBASIN'
}

export type ProductCombinationTypeOperationFilterInput = {
  eq?: InputMaybe<ProductCombinationType>;
  in?: InputMaybe<Array<ProductCombinationType>>;
  neq?: InputMaybe<ProductCombinationType>;
  nin?: InputMaybe<Array<ProductCombinationType>>;
};

/** Represents a Product Configuration Name Popup. */
export type ProductConfigurationNamePopup = ContentItem & {
  __typename?: 'ProductConfigurationNamePopup';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  errorAlreadyExists?: Maybe<Scalars['String']>;
  /** Text field */
  errorMaxLength?: Maybe<Scalars['String']>;
  /** Text field */
  errorRequired?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** Numeric field */
  maxLength?: Maybe<Scalars['Decimal']>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  text?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type ProductConfigurationNamePopupOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductConfigurationNamePopup content item filters */
export type ProductConfigurationNamePopupWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductConfigurationNamePopupWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductConfigurationNamePopupWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductConfigurationNamePopupWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Product item represents a single product that is used in product sets. */
export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  /** Gets the brand of the product. */
  brand?: InputMaybe<StringOperationFilterInput>;
  /** Gets item code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Gets the cost price of the product. */
  costPrice?: InputMaybe<MoneyFilterInput>;
  /** Gets the description of the product. */
  description?: InputMaybe<StringOperationFilterInput>;
  grossSalesPrice?: InputMaybe<MoneyFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the product was added manually. */
  isManualAdded?: InputMaybe<BooleanOperationFilterInput>;
  /**
   * Gets a value indicating whether product is available.
   *
   *
   * **Returns:**
   * True if product not available, false otherwise.
   */
  isNotAvailable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the long description of the product. */
  longDescription?: InputMaybe<StringOperationFilterInput>;
  /** Gets the marketing text of the product. */
  marketingText?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  /** Gets the amount of m2/m1 per unit in a package of the product */
  packagingUnits?: InputMaybe<StringOperationFilterInput>;
  /** Gets the price type. */
  priceType?: InputMaybe<ProjectActivityTargetOperationFilterInput>;
  /** Gets the status code of the product. */
  statusCode?: InputMaybe<IntOperationFilterInput>;
  /** Gets item supplier code */
  supplierCode?: InputMaybe<StringOperationFilterInput>;
};

/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackage = {
  __typename?: 'ProductPackage';
  assignedCategoryProductSets?: Maybe<AssignedCategoryProductSetsCollectionSegment>;
  assignedProductPackageCategories: Array<ProductPackageCategory>;
  assignedProductSets: Array<ProductPackageSet>;
  availableCombinations: Array<ProductCombination>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /**
   * Gets a value indicating whether the product package is a main product package.
   * Main product packages can not be removed. Only product package sets from main product packages can be used for QR code generation.
   */
  isMain: Scalars['Boolean'];
  /** Gets the name of the product package. */
  name: Scalars['String'];
  /** Gets the target project type. */
  targetProjectType?: Maybe<Badkamercore_ProjectType>;
  usedInDesignPackages: Array<DesignPackage>;
  usedInProjects: Array<ProjectProductPackage>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageAssignedCategoryProductSetsArgs = {
  order?: InputMaybe<Array<ProductPackageSetSortInput>>;
  productPackageCategoryId: Scalars['UUID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPackageSetFilterInput>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageAssignedProductPackageCategoriesArgs = {
  order?: InputMaybe<Array<ProductPackageCategorySortInput>>;
  where?: InputMaybe<ProductPackageCategoryFilterInput>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageAssignedProductSetsArgs = {
  order?: InputMaybe<Array<ProductPackageSetSortInput>>;
  where?: InputMaybe<ProductPackageSetFilterInput>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageAvailableCombinationsArgs = {
  order?: InputMaybe<Array<ProductCombinationSortInput>>;
  where?: InputMaybe<ProductCombinationFilterInput>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageUsedInDesignPackagesArgs = {
  where?: InputMaybe<DesignPackageFilterInput>;
};


/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageUsedInProjectsArgs = {
  where?: InputMaybe<ProjectProductPackageFilterInput>;
};

/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export type ProductPackageCategory = {
  __typename?: 'ProductPackageCategory';
  /** Gets a value indicating whether ProductCategory allows grouping ProductSet by brandline for the DesignPackage. */
  allowPickByBrand: Scalars['Boolean'];
  assignedProductSets?: Maybe<AssignedProductSetsCollectionSegment>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the ProductCategory part of this relation. */
  productCategory?: Maybe<ProductCategory>;
};


/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export type ProductPackageCategoryAssignedProductSetsArgs = {
  order?: InputMaybe<Array<ProductPackageSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPackageSetFilterInput>;
};

/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export type ProductPackageCategoryFilterInput = {
  /** Gets a value indicating whether ProductCategory allows grouping ProductSet by brandline for the DesignPackage. */
  allowPickByBrand?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ProductPackageCategoryFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductPackageCategoryFilterInput>>;
  /** Gets or sets the ProductCategory part of this relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the id of the ProductCategory part of this relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the ProductPackage part of this relation. */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
};

/**
 * ProductPackageCategory represents a category of product sets present in one DesignPackage.
 * ProductSet are grouped into ProductCategory and this class represents the specific settings in a given DesignPackage.
 */
export type ProductPackageCategorySortInput = {
  /** Gets a value indicating whether ProductCategory allows grouping ProductSet by brandline for the DesignPackage. */
  allowPickByBrand?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductCategory part of this relation. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the id of the ProductCategory part of this relation. */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductPackage part of this relation. */
  productPackage?: InputMaybe<ProductPackageSortInput>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageId?: InputMaybe<SortEnumType>;
};

/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageFilterInput = {
  and?: InputMaybe<Array<ProductPackageFilterInput>>;
  /** Gets the collection of ProductPackageCategory objects that compose this product package. */
  assignedProductPackageCategories?: InputMaybe<ListFilterInputTypeOfProductPackageCategoryFilterInput>;
  /** Gets the collection of ProductPackageSet objects that compose this product package. */
  assignedProductPackageSets?: InputMaybe<ListFilterInputTypeOfProductPackageSetFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Gets a value indicating whether the product package is a main product package.
   * Main product packages can not be removed. Only product package sets from main product packages can be used for QR code generation.
   */
  isMain?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the name of the product package. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductPackageFilterInput>>;
  /** Gets the status of the product package. */
  status?: InputMaybe<ProductPackageStatusOperationFilterInput>;
  /** Gets the target project type. */
  targetProjectType?: InputMaybe<NullableOfProjectTypeOperationFilterInput>;
  /** Gets the collection of DesignPackage objects that reference this product package. */
  usedInDesignPackages?: InputMaybe<ListFilterInputTypeOfDesignPackageFilterInput>;
  /** Gets the collection of ProjectProductPackage objects that reference this product package. */
  usedInProjects?: InputMaybe<ListFilterInputTypeOfProjectProductPackageFilterInput>;
};

/** Represents a Product Package Page. */
export type ProductPackagePage = ContentItem & {
  __typename?: 'ProductPackagePage';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  baseColor?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type ProductPackagePageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductPackagePage content item filters */
export type ProductPackagePageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductPackagePageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductPackagePageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductPackagePageWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSet = {
  __typename?: 'ProductPackageSet';
  /** Gets the area the ProductPackageSet. */
  area?: Maybe<Scalars['Decimal']>;
  /** Gets the collection of price variants assigned. */
  assignedPriceVariants?: Maybe<Array<Maybe<ProductPackageSetPriceVariant>>>;
  displayableProductSetInfos?: Maybe<Array<Maybe<ProductSetInfo>>>;
  /** Gets the fallback product for the ProductPackageSet. */
  fallBackProduct?: Maybe<ProductPackageSet>;
  /** Gets a value indicating whether the ProductSet is the default in the category for the DesignPackage. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /**
   * Gets a value indicating whether the product set is a no product.
   *
   *
   * **Returns:**
   * True if this is not an actual product.
   */
  isNoProduct: Scalars['Boolean'];
  /**
   * Gets a value indicating whether the product set is a no product needed.
   *
   *
   * **Returns:**
   * True if this is for when no product is needed.
   */
  isNoProductNeeded: Scalars['Boolean'];
  /** Gets a value indicating whether the ProductSet is the default in the category for the ProductPackage. */
  isProductPackageDefault?: Maybe<Scalars['Boolean']>;
  /** Gets a value indicating whether the ProductSet is a favorite in the category for the ProductPackage. */
  isProductPackageFavourite?: Maybe<Scalars['Boolean']>;
  /** Gets the length for the ProductPackageSet. */
  length?: Maybe<Scalars['Decimal']>;
  priceVariants: Scalars['String'];
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination: ProductCombination;
  /** Gets or sets the ProductPackage part of this relation. */
  productPackage: ProductPackage;
  /** Gets or sets the ProductPackageCategory parent on this relation. */
  productPackageCategory?: Maybe<ProductPackageCategory>;
  /** Gets or sets the ProductSet part of this relation. */
  productSet: ProductSet;
  /** Gets the coordination and provision sale price for the ProductSet. */
  saleCoordinationPrice?: Maybe<Money>;
  /** Gets the sale installation price for the ProductSet. */
  saleInstallationPrice?: Maybe<Money>;
  /** Gets the NON M1 sale price for the ProductSet. */
  salePrice?: Maybe<Money>;
  /** Gets the M1 sale price for the ProductSet. */
  salePriceM1?: Maybe<Money>;
  saleProductsPrice?: Maybe<Money>;
  /** Gets the status of the product set. */
  status: ProductSetStatus;
};


/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSetAssignedPriceVariantsArgs = {
  order?: InputMaybe<Array<ProductPackageSetPriceVariantSortInput>>;
  where?: InputMaybe<ProductPackageSetPriceVariantFilterInput>;
};


/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSetDisplayableProductSetInfosArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<ProductSetInfoSortInput>>;
  where?: InputMaybe<ProductSetInfoFilterInput>;
};


/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSetProductCombinationArgs = {
  where?: InputMaybe<ProductCombinationFilterInput>;
};

/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSetFilterInput = {
  and?: InputMaybe<Array<ProductPackageSetFilterInput>>;
  /** Gets the area the ProductPackageSet. */
  area?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the collection of price variants assigned. */
  assignedPriceVariants?: InputMaybe<ListFilterInputTypeOfProductPackageSetPriceVariantFilterInput>;
  /** Gets the fallback product for the ProductPackageSet. */
  fallBackProduct?: InputMaybe<ProductPackageSetFilterInput>;
  /** Gets the collection of costs tha make the SaleInstallation price. */
  installationCostComponents?: InputMaybe<ListFilterInputTypeOfKeyValuePairOfCostTypeAndMoneyFilterInput>;
  /** Gets a value indicating whether the ProductSet is the default in the category for the DesignPackage. */
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  /**
   * Gets a value indicating whether the product set is a no product.
   *
   *
   * **Returns:**
   * True if this is not an actual product.
   */
  isNoProduct?: InputMaybe<BooleanOperationFilterInput>;
  /**
   * Gets a value indicating whether the product set is a no product needed.
   *
   *
   * **Returns:**
   * True if this is for when no product is needed.
   */
  isNoProductNeeded?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the ProductSet is the default in the category for the ProductPackage. */
  isProductPackageDefault?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the ProductSet is a favorite in the category for the ProductPackage. */
  isProductPackageFavourite?: InputMaybe<BooleanOperationFilterInput>;
  /**
   * Gets a value indicating whether the product set is yes or no answer.
   *
   *
   * **Returns:**
   * True if this is a yes or no product set.
   */
  isYesNoAnswer?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the length for the ProductPackageSet. */
  length?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<ProductPackageSetFilterInput>>;
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the ProductPackage part of this relation. */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets or sets the ProductPackageCategory parent on this relation. */
  productPackageCategory?: InputMaybe<ProductPackageCategoryFilterInput>;
  /** Gets the id of the ProductPackageCategory parent on this relation. */
  productPackageCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the ProductSet part of this relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the coordination and provision sale price for the ProductSet. */
  saleCoordinationPrice?: InputMaybe<MoneyFilterInput>;
  /** Gets the sale installation price for the ProductSet. */
  saleInstallationPrice?: InputMaybe<MoneyFilterInput>;
  /** Gets the NON M1 sale price for the ProductSet. */
  salePrice?: InputMaybe<MoneyFilterInput>;
  /** Gets the M1 sale price for the ProductSet. */
  salePriceM1?: InputMaybe<MoneyFilterInput>;
  /** Gets the status of the product set. */
  status?: InputMaybe<ProductSetStatusOperationFilterInput>;
};

/** Represents a price variant assignment to a ProductPackageSet. */
export type ProductPackageSetPriceVariant = {
  __typename?: 'ProductPackageSetPriceVariant';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the price variant. */
  priceVariant: PriceVariant;
  /** Gets the Product Category. */
  productCategory: ProductCategory;
  /** Gets the Product Category identifier. */
  productCategoryId: Scalars['UUID'];
};

/** Represents a price variant assignment to a ProductPackageSet. */
export type ProductPackageSetPriceVariantFilterInput = {
  and?: InputMaybe<Array<ProductPackageSetPriceVariantFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductPackageSetPriceVariantFilterInput>>;
  /** Gets the price variant. */
  priceVariant?: InputMaybe<PriceVariantOperationFilterInput>;
  /** Gets the Product Category. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the Product Category identifier. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the ProductPackageSet part of this relation. */
  productPackageSet?: InputMaybe<ProductPackageSetFilterInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productPackageSetProductCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageSetProductPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productPackageSetProductSetId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a price variant assignment to a ProductPackageSet. */
export type ProductPackageSetPriceVariantSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the price variant. */
  priceVariant?: InputMaybe<SortEnumType>;
  /** Gets the Product Category. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the Product Category identifier. */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductPackageSet part of this relation. */
  productPackageSet?: InputMaybe<ProductPackageSetSortInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productPackageSetProductCombinationId?: InputMaybe<SortEnumType>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageSetProductPackageId?: InputMaybe<SortEnumType>;
  /** Gets the id of the ProductSet part of this relation. */
  productPackageSetProductSetId?: InputMaybe<SortEnumType>;
};

/**
 * Relational class that keeps track of relations (many to many) between ProductPackage,
 * a  ProductSet, a ProductCombination and optional ProductCategory.
 */
export type ProductPackageSetSortInput = {
  /** Gets the area the ProductPackageSet. */
  area?: InputMaybe<SortEnumType>;
  /** Gets the fallback product for the ProductPackageSet. */
  fallBackProduct?: InputMaybe<ProductPackageSetSortInput>;
  /** Gets a value indicating whether the ProductSet is the default in the category for the DesignPackage. */
  isDefault?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether the product set is a no product.
   *
   *
   * **Returns:**
   * True if this is not an actual product.
   */
  isNoProduct?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether the product set is a no product needed.
   *
   *
   * **Returns:**
   * True if this is for when no product is needed.
   */
  isNoProductNeeded?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductSet is the default in the category for the ProductPackage. */
  isProductPackageDefault?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the ProductSet is a favorite in the category for the ProductPackage. */
  isProductPackageFavourite?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether the product set is yes or no answer.
   *
   *
   * **Returns:**
   * True if this is a yes or no product set.
   */
  isYesNoAnswer?: InputMaybe<SortEnumType>;
  /** Gets the length for the ProductPackageSet. */
  length?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination?: InputMaybe<ProductCombinationSortInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productCombinationId?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductPackage part of this relation. */
  productPackage?: InputMaybe<ProductPackageSortInput>;
  /** Gets or sets the ProductPackageCategory parent on this relation. */
  productPackageCategory?: InputMaybe<ProductPackageCategorySortInput>;
  /** Gets the id of the ProductPackageCategory parent on this relation. */
  productPackageCategoryId?: InputMaybe<SortEnumType>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageId?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductSet part of this relation. */
  productSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productSetId?: InputMaybe<SortEnumType>;
  /** Gets the coordination and provision sale price for the ProductSet. */
  saleCoordinationPrice?: InputMaybe<MoneySortInput>;
  /** Gets the sale installation price for the ProductSet. */
  saleInstallationPrice?: InputMaybe<MoneySortInput>;
  /** Gets the NON M1 sale price for the ProductSet. */
  salePrice?: InputMaybe<MoneySortInput>;
  /** Gets the M1 sale price for the ProductSet. */
  salePriceM1?: InputMaybe<MoneySortInput>;
  /** Gets the status of the product set. */
  status?: InputMaybe<SortEnumType>;
};

/** ProductPackage represents a single product package for decorating rooms. */
export type ProductPackageSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether the product package is a main product package.
   * Main product packages can not be removed. Only product package sets from main product packages can be used for QR code generation.
   */
  isMain?: InputMaybe<SortEnumType>;
  /** Gets the name of the product package. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the status of the product package. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the target project type. */
  targetProjectType?: InputMaybe<SortEnumType>;
};

/** Available product package status in badkamer */
export enum ProductPackageStatus {
  /** Product package available for selection */
  Available = 'AVAILABLE',
  /** Product package disabled. Not available for selection but can become available again. */
  Disabled = 'DISABLED'
}

export type ProductPackageStatusOperationFilterInput = {
  eq?: InputMaybe<ProductPackageStatus>;
  in?: InputMaybe<Array<ProductPackageStatus>>;
  neq?: InputMaybe<ProductPackageStatus>;
  nin?: InputMaybe<Array<ProductPackageStatus>>;
};

/** ProductSet present in one or more ProductPackage. */
export type ProductSet = {
  __typename?: 'ProductSet';
  /** Gets the costs assigned to this product set. */
  assignedCosts: Array<Maybe<ProductSetCost>>;
  /** Gets the product set activities. */
  assignedProductSetActivities: Array<ProductSetAssignedActivity>;
  /** Gets the collection of ProductSetInfo objects that compose this product package. */
  assignedProductSetInfos: Array<ProductSetInfo>;
  /** Gets the collection of ProductSetProduct objects that compose this product package. */
  assignedProductSetProducts: Array<ProductSetProduct>;
  /** Gets the brandline for the product set */
  brandline?: Maybe<ProductSetBrandline>;
  /**
   * Gets the sum cost price of NON M1 products that compose the ProductSet.
   *
   *
   * **Returns:**
   * The NON M1 cost price.
   */
  costPrice: Money;
  /**
   * Gets the sum cost price of M1 products that compose the ProductSet.
   *
   *
   * **Returns:**
   * The M1 cost price.
   */
  costPriceM1: Money;
  /** Gets the sum cost price M1 plus project margins of NON M1 product. <br><br>PAY ATTENTION: This resolver can only calculate the Cost Price M1 Plus when a consumer is logged in, because the relevant project margins are retrieved via the login. */
  costPriceM1Plus: Money;
  /** Gets the sum cost price plus project margins of NON M1 product. <br><br>PAY ATTENTION: This resolver can only calculate the Cost Price Plus when a consumer is logged in, because the relevant project margins are retrieved via the login. */
  costPricePlus: Money;
  /** Gets the fixed price of the product set. */
  fixedPrice?: Maybe<Money>;
  /**
   * Gets the sum gross sales price of NON M1 products that compose the ProductSet.
   *
   *
   * **Returns:**
   * The NON M1 gross sales price.
   */
  grossSalesPrice: Money;
  /**
   * Gets the sum gross sales price of M1 products that compose the ProductSet.
   *
   *
   * **Returns:**
   * The M1 gross sales price.
   */
  grossSalesPriceM1: Money;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of this ProductSet. */
  name: Scalars['String'];
};


/** ProductSet present in one or more ProductPackage. */
export type ProductSetAssignedProductSetInfosArgs = {
  where?: InputMaybe<ProductSetInfoFilterInput>;
};


/** ProductSet present in one or more ProductPackage. */
export type ProductSetAssignedProductSetProductsArgs = {
  where?: InputMaybe<ProductSetProductFilterInput>;
};

/** Represents an activity assigned to a project with installations costs. */
export type ProductSetAssignedActivity = {
  __typename?: 'ProductSetAssignedActivity';
  /** Gets the activity. */
  activity?: Maybe<ProjectActivity>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether this activity costs are applicable for products sets in a design package with standard included. */
  isForStandardProductSet?: Maybe<Scalars['Boolean']>;
};

/** Represents an activity assigned to a project with installations costs. */
export type ProductSetAssignedActivityFilterInput = {
  /** Gets the activity. */
  activity?: InputMaybe<ProjectActivityFilterInput>;
  /** Gets the Product Set. */
  activityId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ProductSetAssignedActivityFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether this activity costs are applicable for products sets in a design package with standard included. */
  isForStandardProductSet?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetAssignedActivityFilterInput>>;
  /** Gets the Product Set. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the Product Set. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProductSetBrandline represents a single brandline of product sets. */
export type ProductSetBrandline = {
  __typename?: 'ProductSetBrandline';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of the brandline. */
  name: Scalars['String'];
  /** Gets the integer by which the ProductSetBrandline should be ordered when displaying. */
  position: Scalars['Int'];
  /** Gets the ProductCategory the brandline is attached. */
  productCategory?: Maybe<ProductCategory>;
};

/** ProductSetBrandline represents a single brandline of product sets. */
export type ProductSetBrandlineFilterInput = {
  and?: InputMaybe<Array<ProductSetBrandlineFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the ProductSets assigned to this brandline. */
  includedProductSets?: InputMaybe<ListFilterInputTypeOfProductSetFilterInput>;
  /** Gets the name of the brandline. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetBrandlineFilterInput>>;
  /** Gets the integer by which the ProductSetBrandline should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets the ProductCategory the brandline is attached. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the identifier of the ProductCategory the brandline is attached. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProductSetBrandline represents a single brandline of product sets. */
export type ProductSetBrandlineSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of the brandline. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the ProductSetBrandline should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
  /** Gets the ProductCategory the brandline is attached. */
  productCategory?: InputMaybe<ProductCategorySortInput>;
  /** Gets the identifier of the ProductCategory the brandline is attached. */
  productCategoryId?: InputMaybe<SortEnumType>;
};

/** ProductSetCost represents the costs of installing and assembling a ProductSet. */
export type ProductSetCost = {
  __typename?: 'ProductSetCost';
  /** Gets the cost for the ProductSet for a specific CostType. */
  cost: Money;
  /** Gets the cost type of this value for a product set. */
  costType: CostType;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether gets the flag that indicates if it's a standard product set price or not. */
  isStandard?: Maybe<Scalars['Boolean']>;
  /** Gets the product set id of the relation. */
  productSetId: Scalars['UUID'];
};

/** ProductSetCost represents the costs of installing and assembling a ProductSet. */
export type ProductSetCostFilterInput = {
  and?: InputMaybe<Array<ProductSetCostFilterInput>>;
  /** Gets the cost for the ProductSet for a specific CostType. */
  cost?: InputMaybe<MoneyFilterInput>;
  /** Gets the cost type of this value for a product set. */
  costType?: InputMaybe<CostTypeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether gets the flag that indicates if it's a standard product set price or not. */
  isStandard?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetCostFilterInput>>;
  /** Gets or sets the product set of the relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the product set id of the relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a Product Set Details. */
export type ProductSetDetails = ContentItem & {
  __typename?: 'ProductSetDetails';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** HtmlField */
  descriptionHTML?: Maybe<HtmlField>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** MediaField */
  images?: Maybe<MediaField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** HtmlField */
  shortDescriptionHTML?: Maybe<HtmlField>;
  /** Text field */
  subInfo?: Maybe<Scalars['String']>;
  /** MediaField */
  thumbnail?: Maybe<MediaField>;
  /** MediaField */
  thumbnailColorVariation?: Maybe<MediaField>;
};

export type ProductSetDetailsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductSetDetails content item filters */
export type ProductSetDetailsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductSetDetailsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductSetDetailsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductSetDetailsWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** ProductSet present in one or more ProductPackage. */
export type ProductSetFilterInput = {
  and?: InputMaybe<Array<ProductSetFilterInput>>;
  /** Gets the costs assigned to this product set. */
  assignedCosts?: InputMaybe<ListFilterInputTypeOfProductSetCostFilterInput>;
  /** Gets the product set activities. */
  assignedProductSetActivities?: InputMaybe<ListFilterInputTypeOfProductSetAssignedActivityFilterInput>;
  /** Gets the collection of ProductSetInfo objects that compose this product package. */
  assignedProductSetInfos?: InputMaybe<ListFilterInputTypeOfProductSetInfoFilterInput>;
  /** Gets the collection of ProductSetProduct objects that compose this product package. */
  assignedProductSetProducts?: InputMaybe<ListFilterInputTypeOfProductSetProductFilterInput>;
  /** Gets the brandline for the product set */
  brandline?: InputMaybe<ProductSetBrandlineFilterInput>;
  /** Gets the fixed price of the product set. */
  fixedPrice?: InputMaybe<MoneyFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Gets a value indicating whether product set is available.
   *
   *
   * **Returns:**
   * True if not available.
   */
  isNotAvailable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the name of this ProductSet. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetFilterInput>>;
  /** Gets the brandline Id for the product set */
  productSetBrandlineId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the status of the product set. */
  status?: InputMaybe<ProductSetStatusOperationFilterInput>;
};

/** ProductSetInfo represents a piece of information about a single ProductSet. */
export type ProductSetInfo = {
  __typename?: 'ProductSetInfo';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /**
   * Gets the information key
   *
   *
   * **Returns:**
   * The key of this information field.
   */
  key?: Maybe<ProductSetInfoKeyType>;
  label: Scalars['String'];
  /** Gets the information language */
  languageCode?: Maybe<Scalars['String']>;
  localizedValue: Array<Scalars['String']>;
  /** Gets or sets the ProductSetInfoKey part of this relation. */
  productSetInfoKey: ProductSetInfoKey;
  /** Gets the id of the ProductSetInfoKey part of this relation. */
  productSetInfoKeyId: Scalars['UUID'];
  /** Gets the unit of the information. */
  unit?: Maybe<Scalars['String']>;
  /** Gets the value of the information. */
  value?: Maybe<Scalars['String']>;
};


/** ProductSetInfo represents a piece of information about a single ProductSet. */
export type ProductSetInfoLabelArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/** ProductSetInfo represents a piece of information about a single ProductSet. */
export type ProductSetInfoLocalizedValueArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};

/** Available Product Set Info compare operations */
export enum ProductSetInfoCompareOperation {
  /** Different */
  Diff = 'DIFF',
  /** Equals */
  Eq = 'EQ',
  /** Greater or equal */
  Geq = 'GEQ',
  /** Greater than */
  Gt = 'GT',
  /** Lower or equal */
  Leq = 'LEQ',
  /** Lower than */
  Lt = 'LT',
  /** No operation */
  None = 'NONE'
}

export type ProductSetInfoCompareOperationOperationFilterInput = {
  eq?: InputMaybe<ProductSetInfoCompareOperation>;
  in?: InputMaybe<Array<ProductSetInfoCompareOperation>>;
  neq?: InputMaybe<ProductSetInfoCompareOperation>;
  nin?: InputMaybe<Array<ProductSetInfoCompareOperation>>;
};

/** ProductSetInfo represents a piece of information about a single ProductSet. */
export type ProductSetInfoFilterInput = {
  and?: InputMaybe<Array<ProductSetInfoFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Gets the information key
   *
   *
   * **Returns:**
   * The key of this information field.
   */
  key?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
  /** Gets the information language */
  languageCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetInfoFilterInput>>;
  /** Gets or sets the ProductSet part of this relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the ProductSetInfoKey part of this relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyFilterInput>;
  /** Gets the id of the ProductSetInfoKey part of this relation. */
  productSetInfoKeyId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the unit of the information. */
  unit?: InputMaybe<StringOperationFilterInput>;
  /** Gets the value of the information. */
  value?: InputMaybe<StringOperationFilterInput>;
};

/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKey = {
  __typename?: 'ProductSetInfoKey';
  /** Gets the data type to be used in operations. */
  dataType: ProductSetInfoKeyDataType;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  infoLabel: Scalars['String'];
  /** Gets a value indicating whether this field is editable from the admin. */
  isNotEditable: Scalars['Boolean'];
  /** Gets the information key */
  key: ProductSetInfoKeyType;
  keyLabel: Scalars['String'];
  keyPossibleValues: Array<KeyValuePairOfStringAndString>;
  possibleValues: Array<KeyValuePairOfStringAndString>;
};


/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeyInfoLabelArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeyKeyLabelArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeyKeyPossibleValuesArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeyPossibleValuesArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};

/** Available data types for Information. */
export enum ProductSetInfoKeyDataType {
  /** A area measurement value. Values are stored as mm2. */
  AreaMeasurement = 'AREA_MEASUREMENT',
  /** A boolean value. Will be used as a bool for operations. */
  Bool = 'BOOL',
  /** A color value. Will be used as a text for operations. */
  Color = 'COLOR',
  /** A numeric value with decimal. */
  Decimal = 'DECIMAL',
  /** A enum value fom a list. Will be used as a string for operations. */
  Enum = 'ENUM',
  /** A length measurement value. Values are stored as millimeters. */
  LengthMeasurement = 'LENGTH_MEASUREMENT',
  /** A multi enum value from a list. Will be used as a string for operations. */
  MultiEnum = 'MULTI_ENUM',
  /** A numeric value. */
  Number = 'NUMBER',
  /** A percentage value. */
  Percentage = 'PERCENTAGE',
  /** A percentage value in range 0 -100. */
  Percentage_0_100 = 'PERCENTAGE_0_100',
  /** A text value. Will be used as a string for operations. */
  Text = 'TEXT'
}

export type ProductSetInfoKeyDataTypeOperationFilterInput = {
  eq?: InputMaybe<ProductSetInfoKeyDataType>;
  in?: InputMaybe<Array<ProductSetInfoKeyDataType>>;
  neq?: InputMaybe<ProductSetInfoKeyDataType>;
  nin?: InputMaybe<Array<ProductSetInfoKeyDataType>>;
};

/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeyFilterInput = {
  and?: InputMaybe<Array<ProductSetInfoKeyFilterInput>>;
  /** Gets the data type to be used in operations. */
  dataType?: InputMaybe<ProductSetInfoKeyDataTypeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether this field should be displayed for clients. */
  isDisplayable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether this field is editable from the admin. */
  isNotEditable?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the information key */
  key?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
  /** Gets the label of the information. */
  label?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetInfoKeyFilterInput>>;
  /** Gets the translated label of the information. */
  translatedLabel?: InputMaybe<StringOperationFilterInput>;
};

/** ProductSetInfoKey represents a valid key of information about a single ProductSet. */
export type ProductSetInfoKeySortInput = {
  /** Gets the data type to be used in operations. */
  dataType?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether this field should be displayed for clients. */
  isDisplayable?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether this field is editable from the admin. */
  isNotEditable?: InputMaybe<SortEnumType>;
  /** Gets the information key */
  key?: InputMaybe<SortEnumType>;
  /** Gets the label of the information. */
  label?: InputMaybe<SortEnumType>;
  /** Gets the translated label of the information. */
  translatedLabel?: InputMaybe<SortEnumType>;
};

/** Available product set info Keys in badkamer */
export enum ProductSetInfoKeyType {
  /** Types of the shower enclosure allowed. */
  AllowedShowerEnclosureType = 'ALLOWED_SHOWER_ENCLOSURE_TYPE',
  /** If the bathtub of type Freestanding is allowed. */
  AllowBathtypeFreestanding = 'ALLOW_BATHTYPE_FREESTANDING',
  /** If the bathtub of type Whirlpool is allowed. */
  AllowBathtypeWhirlpool = 'ALLOW_BATHTYPE_WHIRLPOOL',
  /** If the drainage is allowed. */
  AllowDrainage = 'ALLOW_DRAINAGE',
  /**
   * If the drainage of type Gutter is allowed.
   * @deprecated Use only ALLOW_DRAINAGE
   */
  AllowDrainageGutter = 'ALLOW_DRAINAGE_GUTTER',
  /** If the position taps of type Built in is allowed. */
  AllowPositionTapsBuiltin = 'ALLOW_POSITION_TAPS_BUILTIN',
  /** If the shower toilet is allowed. */
  AllowShowerToilet = 'ALLOW_SHOWER_TOILET',
  /** Answer to Yes/No question. */
  AnswerYesNo = 'ANSWER_YES_NO',
  /** Is included in Basic room */
  BasicIncluded = 'BASIC_INCLUDED',
  /** Bath type */
  Bathtype = 'BATHTYPE',
  /** The area of bath casing to cover with tiles. In square meters (m2). */
  BathCasingArea = 'BATH_CASING_AREA',
  /** The length of bath casing profile. In meters (m1). */
  BathCasingLinear = 'BATH_CASING_LINEAR',
  /** Bath Drainage */
  BathDrainage = 'BATH_DRAINAGE',
  /** Bathtub Position of the taps */
  BathPositionTaps = 'BATH_POSITION_TAPS',
  /** Brand */
  Brand = 'BRAND',
  /** Colour */
  Colour = 'COLOUR',
  /** If this is a corner basin. */
  CornerBasin = 'CORNER_BASIN',
  /** Depth */
  Depth = 'DEPTH',
  /** Depth Range */
  DepthRange = 'DEPTH_RANGE',
  /**
   * Drainage
   * @deprecated Use SHOWER_DRAINAGE or BATH_DRAINAGE
   */
  Drainage = 'DRAINAGE',
  /** Has electricity */
  Electricity = 'ELECTRICITY',
  /** Area of the floor. */
  FloorArea = 'FLOOR_AREA',
  /** Type of a reservoir of a Toilet that a flash plate is for. */
  ForReservoirType = 'FOR_RESERVOIR_TYPE',
  /** Fontain Position of the taps */
  FountainPositionTaps = 'FOUNTAIN_POSITION_TAPS',
  /** Grip */
  Grip = 'GRIP',
  /** Has Bowl */
  HasBowl = 'HAS_BOWL',
  /** Has built-in taps */
  HasBuiltintaps = 'HAS_BUILTINTAPS',
  /** Has shower partition wall */
  HasPartitionwall = 'HAS_PARTITIONWALL',
  /** Has Tapholes */
  HasTapholes = 'HAS_TAPHOLES',
  /** Bath has whirlpool */
  HasWhirlpool = 'HAS_WHIRLPOOL',
  /** Height */
  Height = 'HEIGHT',
  /** Total width required for installation. */
  InstallWidth = 'INSTALL_WIDTH',
  /** Is Accent wall compatible? */
  IsAccentWallCompatible = 'IS_ACCENT_WALL_COMPATIBLE',
  /** Is Bath casing compatible? */
  IsBathCasingCompatible = 'IS_BATH_CASING_COMPATIBLE',
  /** Indicates if this product set is a no product selection. */
  IsNoProduct = 'IS_NO_PRODUCT',
  /** IsPartition wall compatible? */
  IsPartitionWallCompatible = 'IS_PARTITION_WALL_COMPATIBLE',
  /**
   * If the shower door is between two walls.
   * @deprecated Use SHOWER_ENCLOSURE_TYPE
   */
  IsShowerDoorBetweenWalls = 'IS_SHOWER_DOOR_BETWEEN_WALLS',
  /** Is Shower floor compatible? */
  IsShowerFloorCompatible = 'IS_SHOWER_FLOOR_COMPATIBLE',
  /** Is Toilet casing compatible? */
  IsToiletCasingCompatible = 'IS_TOILET_CASING_COMPATIBLE',
  /** Is wall filled with tiles to the ceiling? */
  IsWallTilesToCeiling = 'IS_WALL_TILES_TO_CEILING',
  /** Length */
  Length = 'LENGTH',
  /** Material */
  Material = 'MATERIAL',
  /** Minimum depth allowed. */
  MinDepth = 'MIN_DEPTH',
  /** Minimum length allowed. */
  MinLength = 'MIN_LENGTH',
  /** Minimum width allowed. */
  MinWidth = 'MIN_WIDTH',
  /** Not set */
  NotSet = 'NOT_SET',
  /** Number of drawers */
  NumberDrawers = 'NUMBER_DRAWERS',
  /** Number of taps */
  NumberTaps = 'NUMBER_TAPS',
  /** Number of washbasins */
  NumberWashbasins = 'NUMBER_WASHBASINS',
  /** Custom properties. */
  Other = 'OTHER',
  /** Number of items in a package. */
  PackingUnits = 'PACKING_UNITS',
  /**
   * Position of the taps
   * @deprecated Use WASHBASIN_POSITION_TAPS or SHOWER_POSITION_TAPS or BATH_POSITION_TAPS or FOUNTAIN_POSITION_TAPS
   */
  PositionTaps = 'POSITION_TAPS',
  /** Radiator capacity */
  RadiatorCapacity = 'RADIATOR_CAPACITY',
  /** Type of Radiator */
  RadiatorType = 'RADIATOR_TYPE',
  /** Type of a reservoir of a Toilet */
  ReservoirType = 'RESERVOIR_TYPE',
  /** Series */
  Series = 'SERIES',
  /** Color that identify the Product Set in a series. */
  SeriesColorCode = 'SERIES_COLOR_CODE',
  /** Shower Drainage */
  ShowerDrainage = 'SHOWER_DRAINAGE',
  /** The are of shower floor. In square meters (m2). */
  ShowerEnclosureProfileArea = 'SHOWER_ENCLOSURE_PROFILE_AREA',
  /** The length of shower profile. In meters (m1). */
  ShowerEnclosureProfileLinear = 'SHOWER_ENCLOSURE_PROFILE_LINEAR',
  /** Type of the shower enclosure. */
  ShowerEnclosureType = 'SHOWER_ENCLOSURE_TYPE',
  /** The area of shower partition wall to cover with tiles. In square meters (m2). */
  ShowerPartitionWallArea = 'SHOWER_PARTITION_WALL_AREA',
  /** The length of shower partition wall profile. In meters (m1). */
  ShowerPartitionWallLinear = 'SHOWER_PARTITION_WALL_LINEAR',
  /** Shower Position of the taps */
  ShowerPositionTaps = 'SHOWER_POSITION_TAPS',
  /** Has shower toilet */
  ShowerToilet = 'SHOWER_TOILET',
  /** The standard height to each wall will have tiles. */
  StandardWallTileHeight = 'STANDARD_WALL_TILE_HEIGHT',
  /** The percentage of tiles lost with cutting tiles. */
  TilesCutLoss = 'TILES_CUT_LOSS',
  /** Where can a tile be installed. */
  TileApplicableOn = 'TILE_APPLICABLE_ON',
  /** Area of the part surrounding the toilet. */
  ToiletSurroundArea = 'TOILET_SURROUND_AREA',
  /** Length of the profile surrounding the toilet. */
  ToiletSurroundLinear = 'TOILET_SURROUND_LINEAR',
  /** Washbasin Position of the taps */
  WashbasinPositionTaps = 'WASHBASIN_POSITION_TAPS',
  /** Width */
  Width = 'WIDTH'
}

export type ProductSetInfoKeyTypeOperationFilterInput = {
  eq?: InputMaybe<ProductSetInfoKeyType>;
  in?: InputMaybe<Array<ProductSetInfoKeyType>>;
  neq?: InputMaybe<ProductSetInfoKeyType>;
  nin?: InputMaybe<Array<ProductSetInfoKeyType>>;
};

/** ProductSetInfo represents a piece of information about a single ProductSet. */
export type ProductSetInfoSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /**
   * Gets the information key
   *
   *
   * **Returns:**
   * The key of this information field.
   */
  key?: InputMaybe<SortEnumType>;
  /** Gets the information language */
  languageCode?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductSet part of this relation. */
  productSet?: InputMaybe<ProductSetSortInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productSetId?: InputMaybe<SortEnumType>;
  /** Gets or sets the ProductSetInfoKey part of this relation. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeySortInput>;
  /** Gets the id of the ProductSetInfoKey part of this relation. */
  productSetInfoKeyId?: InputMaybe<SortEnumType>;
  /** Gets the unit of the information. */
  unit?: InputMaybe<SortEnumType>;
  /** Gets the value of the information. */
  value?: InputMaybe<SortEnumType>;
};

/** Represents a Product Set Page. */
export type ProductSetPage = ContentItem & {
  __typename?: 'ProductSetPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** Text field */
  costPriceLabel?: Maybe<Scalars['String']>;
  /** Text field */
  costPricePlusLabel?: Maybe<Scalars['String']>;
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  excludingVATLabel?: Maybe<Scalars['String']>;
  /** Text field */
  grossPriceLabel?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  noProductSetFoundLabel?: Maybe<Scalars['String']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  priceInformationLabel?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  scanQrCodeButton?: Maybe<Scalars['String']>;
  /** Text field */
  uScannedLabel?: Maybe<Scalars['String']>;
};

export type ProductSetPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProductSetPage content item filters */
export type ProductSetPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProductSetPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProductSetPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProductSetPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Relational class that keeps track of relations (many to many) between ProductSet and Product. */
export type ProductSetProduct = {
  __typename?: 'ProductSetProduct';
  /** Gets a value indicating whether the product/article is the primary in the product set. */
  isPrimary: Scalars['Boolean'];
  /** Gets the Product part of this relation. */
  product: Product;
};

/** Relational class that keeps track of relations (many to many) between ProductSet and Product. */
export type ProductSetProductFilterInput = {
  and?: InputMaybe<Array<ProductSetProductFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the product/article is the primary in the product set. */
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetProductFilterInput>>;
  /** Gets the Product part of this relation. */
  product?: InputMaybe<ProductFilterInput>;
  /** Gets the id of the Product part of this relation. */
  productId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the ProductSet part of this relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the id of the ProductSet part of this relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
};

/**
 * ProductSetRestriction represents a retriction on using a ProductSet in a DesignPackage.
 * Restriction can be baucause of previous ProductSet properties.
 */
export type ProductSetRestrictionFilterInput = {
  and?: InputMaybe<Array<ProductSetRestrictionFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets a value indicating whether this is a campare operation on the same property on parent and child. */
  isCompareParentToChildProperty?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProductSetRestrictionFilterInput>>;
  /** Gets or sets the ProductCategory parent on this relation. */
  parentProductCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets or sets the id of the ProductCategory parent on this relation. */
  parentProductCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the compare operation to preform on parent. */
  parentProductSetInfoCompareOperation?: InputMaybe<ProductSetInfoCompareOperationOperationFilterInput>;
  /** Gets or sets the value to be used in the compare operation on parent. */
  parentProductSetInfoCompareValue?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the product set information key to be used to compare on parent. */
  parentProductSetInfoKey?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
  /** Gets or sets the ProductCategory parent on this relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets or sets the id of the ProductCategory parent on this relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the id of the ProductPackage part of this relation. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the compare operation to preform. */
  productSetInfoCompareOperation?: InputMaybe<ProductSetInfoCompareOperationOperationFilterInput>;
  /** Gets or sets the value to be used in the compare operation. */
  productSetInfoCompareValue?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the product set information key to be used to compare. */
  productSetInfoKey?: InputMaybe<ProductSetInfoKeyTypeOperationFilterInput>;
};

/** ProductSet present in one or more ProductPackage. */
export type ProductSetSortInput = {
  /** Gets the brandline for the product set */
  brandline?: InputMaybe<ProductSetBrandlineSortInput>;
  /** Gets the fixed price of the product set. */
  fixedPrice?: InputMaybe<MoneySortInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether product set is available.
   *
   *
   * **Returns:**
   * True if not available.
   */
  isNotAvailable?: InputMaybe<SortEnumType>;
  /** Gets the name of this ProductSet. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the brandline Id for the product set */
  productSetBrandlineId?: InputMaybe<SortEnumType>;
  /** Gets the status of the product set. */
  status?: InputMaybe<SortEnumType>;
};

/** Available product set status in badkamer */
export enum ProductSetStatus {
  /** Product set available for selection */
  Available = 'AVAILABLE',
  /** Product set disabled. Not available for selection but can become available again. */
  Disabled = 'DISABLED',
  /** Product set obsolete. May not be available to selection */
  Obsolete = 'OBSOLETE',
  /** Product set retired. Not available to selection */
  Retired = 'RETIRED'
}

export type ProductSetStatusOperationFilterInput = {
  eq?: InputMaybe<ProductSetStatus>;
  in?: InputMaybe<Array<ProductSetStatus>>;
  neq?: InputMaybe<ProductSetStatus>;
  nin?: InputMaybe<Array<ProductSetStatus>>;
};

/** Product item represents a single product that is used in product sets. */
export type ProductSortInput = {
  /** Gets the brand of the product. */
  brand?: InputMaybe<SortEnumType>;
  /** Gets item code */
  code?: InputMaybe<SortEnumType>;
  /** Gets the cost price of the product. */
  costPrice?: InputMaybe<MoneySortInput>;
  /** Gets the description of the product. */
  description?: InputMaybe<SortEnumType>;
  grossSalesPrice?: InputMaybe<MoneySortInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the product was added manually. */
  isManualAdded?: InputMaybe<SortEnumType>;
  /**
   * Gets a value indicating whether product is available.
   *
   *
   * **Returns:**
   * True if product not available, false otherwise.
   */
  isNotAvailable?: InputMaybe<SortEnumType>;
  /** Gets the long description of the product. */
  longDescription?: InputMaybe<SortEnumType>;
  /** Gets the marketing text of the product. */
  marketingText?: InputMaybe<SortEnumType>;
  /** Gets the amount of m2/m1 per unit in a package of the product */
  packagingUnits?: InputMaybe<SortEnumType>;
  /** Gets the price type. */
  priceType?: InputMaybe<SortEnumType>;
  /** Gets the status code of the product. */
  statusCode?: InputMaybe<SortEnumType>;
  /** Gets item supplier code */
  supplierCode?: InputMaybe<SortEnumType>;
};

/** Extended properties for Project used for listing. */
export type Project = {
  __typename?: 'Project';
  /** Gets the brands assigned to this project. */
  assignedBrands: Array<ProjectBrand>;
  /** Gets the default products assigned to this project. */
  assignedDefaultProducts: Array<Maybe<ProjectDefaultProductSet>>;
  /** Gets the product packages assigned to this project. */
  assignedProductPackages: Array<ProjectProductPackage>;
  /** Gets the costs per project activity. */
  assignedProjectActivities: Array<ProjectAssignedActivity>;
  /** Gets the Dossier contacts assigned to this project. */
  assignedProjectDossierContacts: Array<ProjectDossierContact>;
  /** Gets the styles assigned to this project. */
  assignedStyles: Array<ProjectStyle>;
  /** Gets the date and time the project was last closed on. */
  closedOn?: Maybe<Scalars['DateTime']>;
  /** Gets the company margin applied on each sanitary ProductSet in the ProductPackage for the project. */
  companyMarginSanitary?: Maybe<Scalars['Decimal']>;
  /** Gets the company margin applied on each tiles ProductSet in the ProductPackage for the project. */
  companyMarginTiles?: Maybe<Scalars['Decimal']>;
  /** Gets the correction factor for construction prices applied on each ProductSet in the ProductPackage for the project. */
  correctionConstructionFactor?: Maybe<Scalars['Decimal']>;
  /** Gets the correction factor for installation CV prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationCVFactor?: Maybe<Scalars['Decimal']>;
  /** Gets the correction factor for installation E prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationEFactor?: Maybe<Scalars['Decimal']>;
  /**
   * Gets the correction factor for installation prices applied on each ProductSet in the ProductPackage for the project.
   * @deprecated CorrectionInstallationFactor is split into: CorrectionInstallationWFactor, CorrectionInstallationEFactor, CorrectionInstallationEFactor
   */
  correctionInstallationFactor?: Maybe<Scalars['Decimal']>;
  /** Gets the correction factor for installation W prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationWFactor?: Maybe<Scalars['Decimal']>;
  /** Gets the correction factor for tiler prices applied on each ProductSet in the ProductPackage for the project. */
  correctionTilerFactor?: Maybe<Scalars['Decimal']>;
  /** Gets a value indicating whether the custom offers should be divided by rooms feature is active. */
  divideCustomOfferByRooms: Scalars['Boolean'];
  /** Gets a value indicating whether the project is not using Project Activities for Product Set costs. */
  dontUseProjectActivityCosts: Scalars['Boolean'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets a value indicating whether the custom offer feature is active. */
  isCustomOfferFeatureActive: Scalars['Boolean'];
  /**
   * Gets whether a project is currently allowed to have its status changed to Open.
   * Business rule: Projects are only publishable if all rooms in all real estates have at least one design package assigned to it.
   *
   *
   * **Returns:**
   * true if all real estates have one or more design packages, otherwise false
   */
  isPublishable: Scalars['Boolean'];
  /** Gets the date and time the project was last imported on. */
  lastImportedOn?: Maybe<Scalars['DateTime']>;
  /** Gets the distinct layouts (for the real estates) of this project. */
  layouts: Array<Layout>;
  /** Gets the builder margin applied on each ProductSet in the ProductPackage for the project. */
  marginBuilder?: Maybe<Scalars['Decimal']>;
  /** Gets the installer margin applied on each ProductSet in the ProductPackage for the project. */
  marginInstaller?: Maybe<Scalars['Decimal']>;
  /** Gets the tiler margin applied on each tiles ProductSet in the ProductPackage for the project. */
  marginTiler?: Maybe<Scalars['Decimal']>;
  /** Gets the name of the project, according to Estimate. */
  name: Scalars['String'];
  /** Gets the number of the project in Estimate. */
  number: Scalars['String'];
  projectActivities: Array<ProjectAssignedActivity>;
  /** Gets the name of the project developer, according to Estimate. */
  projectDeveloperName: Scalars['String'];
  projectProductReturnPrices?: Maybe<ProjectProductReturnPricesCollectionSegment>;
  /** Gets the projectType. */
  projectType: Badkamercore_ProjectType;
  /** Gets the date and time the project was last published on. */
  publishedOn?: Maybe<Scalars['DateTime']>;
  /** Gets the real estates of this project. */
  realEstates: Array<RealEstate>;
  /** Gets the rooms of this project. */
  rooms: Array<Room>;
  /** Gets a value indicating whether the project allows ShellOnly delivery for rooms, according to Estimate. */
  shellOnlyAllowed: Scalars['Boolean'];
  /** Gets the current status of the project. */
  status: ProjectStatus;
  /** Gets the number of RealEstate still not invited. */
  uninvitedCount: Scalars['Int'];
  /** Gets a value indicating whether the project uses the Minus Return Price method for prices calculations. */
  useMinusReturnPriceCalculation: Scalars['Boolean'];
  /** Gets the VAT for the project. */
  vat?: Maybe<Scalars['Decimal']>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedBrandsArgs = {
  where?: InputMaybe<ProjectBrandFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedDefaultProductsArgs = {
  where?: InputMaybe<ProjectDefaultProductSetFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedProductPackagesArgs = {
  where?: InputMaybe<ProjectProductPackageFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedProjectActivitiesArgs = {
  order?: InputMaybe<Array<ProjectAssignedActivitySortInput>>;
  where?: InputMaybe<ProjectAssignedActivityFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedProjectDossierContactsArgs = {
  order?: InputMaybe<Array<ProjectDossierContactSortInput>>;
  where?: InputMaybe<ProjectDossierContactFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectAssignedStylesArgs = {
  where?: InputMaybe<ProjectStyleFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectLayoutsArgs = {
  where?: InputMaybe<LayoutFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectProjectActivitiesArgs = {
  order?: InputMaybe<Array<ProjectAssignedActivitySortInput>>;
  where?: InputMaybe<ProjectAssignedActivityFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectProjectProductReturnPricesArgs = {
  order?: InputMaybe<Array<ProjectProductReturnPriceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectProductReturnPriceFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectRealEstatesArgs = {
  where?: InputMaybe<RealEstateFilterInput>;
};


/** Extended properties for Project used for listing. */
export type ProjectRoomsArgs = {
  order?: InputMaybe<Array<RoomSortInput>>;
  where?: InputMaybe<RoomFilterInput>;
};

/** Represents an activity in a project. */
export type ProjectActivity = {
  __typename?: 'ProjectActivity';
  activityAdditionalInfo?: Maybe<Scalars['String']>;
  activityName: Scalars['String'];
  /** Gets the target of the activity. */
  activityTarget: ProjectActivityTarget;
  /** Gets the additional information. */
  additionalInfo?: Maybe<Scalars['String']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the activity name. */
  name: Scalars['String'];
  /** Gets the activity position label. */
  position: Scalars['Int'];
  /** Gets the activity position label. */
  positionLabel: Scalars['String'];
  /** Gets the product combination. */
  productCombination?: Maybe<ProductCombination>;
  /** Gets the additional information in English. */
  translatedAdditionalInfo?: Maybe<Scalars['String']>;
  /** Gets the activity name in English. */
  translatedName: Scalars['String'];
};


/** Represents an activity in a project. */
export type ProjectActivityActivityAdditionalInfoArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};


/** Represents an activity in a project. */
export type ProjectActivityActivityNameArgs = {
  cultureCode?: InputMaybe<Scalars['String']>;
};

/** Represents a cost of an activity in a particular project. */
export type ProjectActivityCost = {
  __typename?: 'ProjectActivityCost';
  /** Gets the type of cost. */
  costType: CostType;
  /** Gets the cost value. */
  costValue?: Maybe<Money>;
};

/** Represents a cost of a project activity. */
export type ProjectActivityCostDtoInput = {
  /** Gets the amount. */
  amount: Scalars['String'];
  /** Gets the cost type. */
  costType: CostType;
};

/** Represents a cost of an activity in a particular project. */
export type ProjectActivityCostFilterInput = {
  and?: InputMaybe<Array<ProjectActivityCostFilterInput>>;
  /** Gets the type of cost. */
  costType?: InputMaybe<CostTypeOperationFilterInput>;
  /** Gets the cost value. */
  costValue?: InputMaybe<MoneyFilterInput>;
  or?: InputMaybe<Array<ProjectActivityCostFilterInput>>;
  /** Gets the project assigned activity. */
  projectAssignedActivity?: InputMaybe<ProjectAssignedActivityFilterInput>;
  /** Gets the project assigned activity identifier. */
  projectAssignedActivityId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents an activity in a project. */
export type ProjectActivityFilterInput = {
  /** Gets the target of the activity. */
  activityTarget?: InputMaybe<ProjectActivityTargetOperationFilterInput>;
  /** Gets the additional information. */
  additionalInfo?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ProjectActivityFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the activity name. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProjectActivityFilterInput>>;
  /** Gets the activity position label. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets the activity position label. */
  positionLabel?: InputMaybe<StringOperationFilterInput>;
  /** Gets the product combination. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the product combination identifier. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the additional information in English. */
  translatedAdditionalInfo?: InputMaybe<StringOperationFilterInput>;
  /** Gets the activity name in English. */
  translatedName?: InputMaybe<StringOperationFilterInput>;
};

/** Represents an activity in a project. */
export type ProjectActivitySortInput = {
  /** Gets the target of the activity. */
  activityTarget?: InputMaybe<SortEnumType>;
  /** Gets the additional information. */
  additionalInfo?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the activity name. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the activity position label. */
  position?: InputMaybe<SortEnumType>;
  /** Gets the activity position label. */
  positionLabel?: InputMaybe<SortEnumType>;
  /** Gets the product combination. */
  productCombination?: InputMaybe<ProductCombinationSortInput>;
  /** Gets the product combination identifier. */
  productCombinationId?: InputMaybe<SortEnumType>;
  /** Gets the additional information in English. */
  translatedAdditionalInfo?: InputMaybe<SortEnumType>;
  /** Gets the activity name in English. */
  translatedName?: InputMaybe<SortEnumType>;
};

/** Where is the activity applied. */
export enum ProjectActivityTarget {
  /** Activity applied per meter. */
  Meters = 'METERS',
  /** Activity on a product set. */
  ProductSet = 'PRODUCT_SET',
  /** Activity applied per square meter. */
  SquareMeters = 'SQUARE_METERS'
}

export type ProjectActivityTargetOperationFilterInput = {
  eq?: InputMaybe<ProjectActivityTarget>;
  in?: InputMaybe<Array<ProjectActivityTarget>>;
  neq?: InputMaybe<ProjectActivityTarget>;
  nin?: InputMaybe<Array<ProjectActivityTarget>>;
};

/** Represents an activity assigned to a project with installations costs. */
export type ProjectAssignedActivity = {
  __typename?: 'ProjectAssignedActivity';
  /** Gets the activity. */
  activity: ProjectActivity;
  /** Gets the collections of costs. */
  activityCosts: Array<ProjectActivityCost>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
};

/** Represents an activity assigned to a project with installations costs. */
export type ProjectAssignedActivityFilterInput = {
  /** Gets the activity. */
  activity?: InputMaybe<ProjectActivityFilterInput>;
  /** Gets the collections of costs. */
  activityCosts?: InputMaybe<ListFilterInputTypeOfProjectActivityCostFilterInput>;
  and?: InputMaybe<Array<ProjectAssignedActivityFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProjectAssignedActivityFilterInput>>;
  /** Gets the project. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents an activity assigned to a project with installations costs. */
export type ProjectAssignedActivitySortInput = {
  /** Gets the activity. */
  activity?: InputMaybe<ProjectActivitySortInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the project. */
  project?: InputMaybe<ProjectSortInput>;
  /** Gets the project. */
  projectId?: InputMaybe<SortEnumType>;
};

/** Relational object that describes the relation between a project and a brand. */
export type ProjectBrand = {
  __typename?: 'ProjectBrand';
  /** Gets or sets the brand of the relation. */
  brand: Brand;
  /** Gets or sets the project of the relation. */
  project: Project;
};

/** Relational object that describes the relation between a project and a brand. */
export type ProjectBrandFilterInput = {
  and?: InputMaybe<Array<ProjectBrandFilterInput>>;
  /** Gets or sets the brand of the relation. */
  brand?: InputMaybe<BrandFilterInput>;
  /** Gets the brand id of the relation. */
  brandId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProjectBrandFilterInput>>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProjectDefaultProductSet represents a default ProductSet for a ProductCategory in a Project. */
export type ProjectDefaultProductSet = {
  __typename?: 'ProjectDefaultProductSet';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the product category of the relation. */
  productCategory: ProductCategory;
  /** Gets the product category id of the relation. */
  productCategoryId: Scalars['UUID'];
  /** Gets or sets the product set of the relation. */
  productSet: ProductSet;
  /** Gets the product set id of the relation. */
  productSetId: Scalars['UUID'];
};

/** ProjectDefaultProductSet represents a default ProductSet for a ProductCategory in a Project. */
export type ProjectDefaultProductSetFilterInput = {
  and?: InputMaybe<Array<ProjectDefaultProductSetFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProjectDefaultProductSetFilterInput>>;
  /** Gets or sets the product category of the relation. */
  productCategory?: InputMaybe<ProductCategoryFilterInput>;
  /** Gets the product category id of the relation. */
  productCategoryId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the product set of the relation. */
  productSet?: InputMaybe<ProductSetFilterInput>;
  /** Gets the product set id of the relation. */
  productSetId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a contact to send the project dossier. */
export type ProjectDossierContact = {
  __typename?: 'ProjectDossierContact';
  /** Gets the dossier contact email address. */
  email?: Maybe<Email>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
};

/** Represents a contact to send the project dossier. */
export type ProjectDossierContactDtoInput = {
  /** Gets the dossier contact email address. */
  email: Scalars['String'];
  /** Gets the identifier for this entity. */
  id: Scalars['UUID'];
};

/** Represents a contact to send the project dossier. */
export type ProjectDossierContactFilterInput = {
  and?: InputMaybe<Array<ProjectDossierContactFilterInput>>;
  /** Gets the dossier contact email address. */
  email?: InputMaybe<EmailFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProjectDossierContactFilterInput>>;
  /** Gets the project identifier of the contact relates to. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a contact to send the project dossier. */
export type ProjectDossierContactSortInput = {
  /** Gets the dossier contact email address. */
  email?: InputMaybe<EmailSortInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the project identifier of the contact relates to. */
  projectId?: InputMaybe<SortEnumType>;
};

/** Extended properties for Project used for listing. */
export type ProjectFilterInput = {
  and?: InputMaybe<Array<ProjectFilterInput>>;
  /** Gets the brands assigned to this project. */
  assignedBrands?: InputMaybe<ListFilterInputTypeOfProjectBrandFilterInput>;
  /** Gets the default products assigned to this project. */
  assignedDefaultProducts?: InputMaybe<ListFilterInputTypeOfProjectDefaultProductSetFilterInput>;
  /** Gets the product packages assigned to this project. */
  assignedProductPackages?: InputMaybe<ListFilterInputTypeOfProjectProductPackageFilterInput>;
  /** Gets the costs per project activity. */
  assignedProjectActivities?: InputMaybe<ListFilterInputTypeOfProjectAssignedActivityFilterInput>;
  /** Gets the Dossier contacts assigned to this project. */
  assignedProjectDossierContacts?: InputMaybe<ListFilterInputTypeOfProjectDossierContactFilterInput>;
  /** Gets the ProjectProductReturnPrice assigned to this project. */
  assignedProjectProductReturnPrices?: InputMaybe<ListFilterInputTypeOfProjectProductReturnPriceFilterInput>;
  /** Gets the styles assigned to this project. */
  assignedStyles?: InputMaybe<ListFilterInputTypeOfProjectStyleFilterInput>;
  /** Gets the date and time the project was last closed on. */
  closedOn?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the company margin applied on each sanitary ProductSet in the ProductPackage for the project. */
  companyMarginSanitary?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the company margin applied on each tiles ProductSet in the ProductPackage for the project. */
  companyMarginTiles?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for construction prices applied on each ProductSet in the ProductPackage for the project. */
  correctionConstructionFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for installation CV prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationCVFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for installation E prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationEFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for installation prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for installation W prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationWFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the correction factor for tiler prices applied on each ProductSet in the ProductPackage for the project. */
  correctionTilerFactor?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets a value indicating whether the custom offers should be divided by rooms feature is active. */
  divideCustomOfferByRooms?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the project is not using Project Activities for Product Set costs. */
  dontUseProjectActivityCosts?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets a value indicating whether the custom offer feature is active. */
  isCustomOfferFeatureActive?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the date and time the project was last imported on. */
  lastImportedOn?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the distinct layouts (for the real estates) of this project. */
  layouts?: InputMaybe<ListFilterInputTypeOfLayoutFilterInput>;
  /** Gets the builder margin applied on each ProductSet in the ProductPackage for the project. */
  marginBuilder?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the installer margin applied on each ProductSet in the ProductPackage for the project. */
  marginInstaller?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the tiler margin applied on each tiles ProductSet in the ProductPackage for the project. */
  marginTiler?: InputMaybe<DecimalOperationFilterInput>;
  /** Gets the name of the project, according to Estimate. */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Gets the number of the project in Estimate. */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProjectFilterInput>>;
  /** Gets the name of the project developer, according to Estimate. */
  projectDeveloperName?: InputMaybe<StringOperationFilterInput>;
  /** Gets the projectType. */
  projectType?: InputMaybe<ProjectTypeOperationFilterInput>;
  /** Gets the date and time the project was last published on. */
  publishedOn?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the real estates of this project. */
  realEstates?: InputMaybe<ListFilterInputTypeOfRealEstateFilterInput>;
  /** Gets the rooms of this project. */
  rooms?: InputMaybe<ListFilterInputTypeOfRoomFilterInput>;
  /** Gets a value indicating whether the project allows ShellOnly delivery for rooms, according to Estimate. */
  shellOnlyAllowed?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the current status of the project. */
  status?: InputMaybe<ProjectStatusOperationFilterInput>;
  /** Gets the number of RealEstate still not invited. */
  uninvitedCount?: InputMaybe<IntOperationFilterInput>;
  /** Gets a value indicating whether the project uses the Minus Return Price method for prices calculations. */
  useMinusReturnPriceCalculation?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the VAT for the project. */
  vat?: InputMaybe<DecimalOperationFilterInput>;
};

/** Represents a Project Landing Page. */
export type ProjectLandingPage = ContentItem & {
  __typename?: 'ProjectLandingPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  description?: Maybe<HtmlField>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Content stored as HTML. */
  htmlBody?: Maybe<HtmlBodyPart>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Text field */
  projectType?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  startButtonText?: Maybe<Scalars['String']>;
  /** Represents a StepBlock. */
  step1?: Maybe<Step1>;
  /** Represents a StepBlock. */
  step2?: Maybe<Step2>;
  /** Represents a StepBlock. */
  step3?: Maybe<Step3>;
};

export type ProjectLandingPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProjectLandingPage content item filters */
export type ProjectLandingPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProjectLandingPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProjectLandingPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProjectLandingPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Project page. */
export type ProjectPage = ContentItem & {
  __typename?: 'ProjectPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** HtmlField */
  introduction?: Maybe<HtmlField>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  projectType?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Numeric field */
  rotationTime?: Maybe<Scalars['Decimal']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
};

export type ProjectPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the ProjectPage content item filters */
export type ProjectPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<ProjectPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<ProjectPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<ProjectPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Relational object that describes the relation between a project and a product package. */
export type ProjectProductPackage = {
  __typename?: 'ProjectProductPackage';
  /** Gets or sets the style of the relation. */
  productPackage: ProductPackage;
  /** Gets or sets the project of the relation. */
  project: Project;
};

/** Relational object that describes the relation between a project and a product package. */
export type ProjectProductPackageFilterInput = {
  and?: InputMaybe<Array<ProjectProductPackageFilterInput>>;
  or?: InputMaybe<Array<ProjectProductPackageFilterInput>>;
  /** Gets or sets the style of the relation. */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets the project package id of the relation. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** ProjectProductReturnPrice. */
export type ProjectProductReturnPrice = {
  __typename?: 'ProjectProductReturnPrice';
  hashCode: Scalars['Int'];
  /** Gets or sets the Product part of this relation. */
  product: Product;
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination: ProductCombination;
  /** Gets or sets the project of the relation. */
  project: Project;
  /** Gets the return price of the product. */
  returnPrice?: Maybe<Money>;
};


/** ProjectProductReturnPrice. */
export type ProjectProductReturnPriceProductArgs = {
  order?: InputMaybe<Array<ProductSortInput>>;
};


/** ProjectProductReturnPrice. */
export type ProjectProductReturnPriceProductCombinationArgs = {
  order?: InputMaybe<Array<ProductCombinationSortInput>>;
};

/** ProjectProductReturnPrice. */
export type ProjectProductReturnPriceFilterInput = {
  and?: InputMaybe<Array<ProjectProductReturnPriceFilterInput>>;
  or?: InputMaybe<Array<ProjectProductReturnPriceFilterInput>>;
  /** Gets or sets the Product part of this relation. */
  product?: InputMaybe<ProductFilterInput>;
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination?: InputMaybe<ProductCombinationFilterInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productCombinationId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the id of the Product part of this relation. */
  productId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the return price of the product. */
  returnPrice?: InputMaybe<MoneyFilterInput>;
};

/** ProjectProductReturnPrice. */
export type ProjectProductReturnPriceSortInput = {
  /** Gets or sets the Product part of this relation. */
  product?: InputMaybe<ProductSortInput>;
  /** Gets or sets the ProductCombination part of this relation. */
  productCombination?: InputMaybe<ProductCombinationSortInput>;
  /** Gets the id of the ProductCombination part of this relation. */
  productCombinationId?: InputMaybe<SortEnumType>;
  /** Gets the id of the Product part of this relation. */
  productId?: InputMaybe<SortEnumType>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectSortInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<SortEnumType>;
  /** Gets the return price of the product. */
  returnPrice?: InputMaybe<MoneySortInput>;
};

/** A segment of a collection. */
export type ProjectProductReturnPricesCollectionSegment = {
  __typename?: 'ProjectProductReturnPricesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<ProjectProductReturnPrice>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Extended properties for Project used for listing. */
export type ProjectSortInput = {
  /** Gets the date and time the project was last closed on. */
  closedOn?: InputMaybe<SortEnumType>;
  /** Gets the company margin applied on each sanitary ProductSet in the ProductPackage for the project. */
  companyMarginSanitary?: InputMaybe<SortEnumType>;
  /** Gets the company margin applied on each tiles ProductSet in the ProductPackage for the project. */
  companyMarginTiles?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for construction prices applied on each ProductSet in the ProductPackage for the project. */
  correctionConstructionFactor?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for installation CV prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationCVFactor?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for installation E prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationEFactor?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for installation prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationFactor?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for installation W prices applied on each ProductSet in the ProductPackage for the project. */
  correctionInstallationWFactor?: InputMaybe<SortEnumType>;
  /** Gets the correction factor for tiler prices applied on each ProductSet in the ProductPackage for the project. */
  correctionTilerFactor?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the custom offers should be divided by rooms feature is active. */
  divideCustomOfferByRooms?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the project is not using Project Activities for Product Set costs. */
  dontUseProjectActivityCosts?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the custom offer feature is active. */
  isCustomOfferFeatureActive?: InputMaybe<SortEnumType>;
  /** Gets the date and time the project was last imported on. */
  lastImportedOn?: InputMaybe<SortEnumType>;
  /** Gets the builder margin applied on each ProductSet in the ProductPackage for the project. */
  marginBuilder?: InputMaybe<SortEnumType>;
  /** Gets the installer margin applied on each ProductSet in the ProductPackage for the project. */
  marginInstaller?: InputMaybe<SortEnumType>;
  /** Gets the tiler margin applied on each tiles ProductSet in the ProductPackage for the project. */
  marginTiler?: InputMaybe<SortEnumType>;
  /** Gets the name of the project, according to Estimate. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the number of the project in Estimate. */
  number?: InputMaybe<SortEnumType>;
  /** Gets the name of the project developer, according to Estimate. */
  projectDeveloperName?: InputMaybe<SortEnumType>;
  /** Gets the projectType. */
  projectType?: InputMaybe<SortEnumType>;
  /** Gets the date and time the project was last published on. */
  publishedOn?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the project allows ShellOnly delivery for rooms, according to Estimate. */
  shellOnlyAllowed?: InputMaybe<SortEnumType>;
  /** Gets the current status of the project. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the number of RealEstate still not invited. */
  uninvitedCount?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the project uses the Minus Return Price method for prices calculations. */
  useMinusReturnPriceCalculation?: InputMaybe<SortEnumType>;
  /** Gets the VAT for the project. */
  vat?: InputMaybe<SortEnumType>;
};

/** The possible statusses of a project. */
export enum ProjectStatus {
  /** The project is closed */
  Closed = 'CLOSED',
  /** The project has ended */
  Ended = 'ENDED',
  /** The project is open */
  Open = 'OPEN',
  /** The project is in preview */
  Preview = 'PREVIEW'
}

export type ProjectStatusOperationFilterInput = {
  eq?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<ProjectStatus>>;
  neq?: InputMaybe<ProjectStatus>;
  nin?: InputMaybe<Array<ProjectStatus>>;
};

/** Relational object that describes the relation between a project and a style. */
export type ProjectStyle = {
  __typename?: 'ProjectStyle';
  /** Gets or sets the project of the relation. */
  project: Project;
  /** Gets or sets the style of the relation. */
  style: Style;
};

/** Relational object that describes the relation between a project and a style. */
export type ProjectStyleFilterInput = {
  and?: InputMaybe<Array<ProjectStyleFilterInput>>;
  or?: InputMaybe<Array<ProjectStyleFilterInput>>;
  /** Gets or sets the project of the relation. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the project id of the relation. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the style of the relation. */
  style?: InputMaybe<StyleFilterInput>;
  /** Gets the style id of the relation. */
  styleId?: InputMaybe<UuidOperationFilterInput>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  /** Text field */
  projectType?: Maybe<Scalars['String']>;
};

export enum ProjectTypeEnum {
  DeBadkamerwerelden = 'DE_BADKAMERWERELDEN',
  Merkenpropositie = 'MERKENPROPOSITIE',
  Productenpropositie = 'PRODUCTENPROPOSITIE'
}

export type ProjectTypeOperationFilterInput = {
  eq?: InputMaybe<Badkamercore_ProjectType>;
  in?: InputMaybe<Array<Badkamercore_ProjectType>>;
  neq?: InputMaybe<Badkamercore_ProjectType>;
  nin?: InputMaybe<Array<Badkamercore_ProjectType>>;
};

/** A segment of a collection. */
export type ProjectsCollectionSegment = {
  __typename?: 'ProjectsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** Represents a About page. */
  aboutPage?: Maybe<Array<Maybe<AboutPage>>>;
  accessToken: Scalars['String'];
  activityTemplate?: Maybe<ActivityTemplate>;
  activityTemplates?: Maybe<ActivityTemplatesCollectionSegment>;
  adminUser: AdUserDto;
  /** Represents a Become Partner Page. */
  becomePartnerPage?: Maybe<Array<Maybe<BecomePartnerPage>>>;
  /** Represents a Brand detail. */
  brandDetail?: Maybe<Array<Maybe<BrandDetail>>>;
  /** Represents a Brand Listing Page. */
  brandListingPage?: Maybe<Array<Maybe<BrandListingPage>>>;
  /** Represents a Brand Page. */
  brandPage?: Maybe<Array<Maybe<BrandPage>>>;
  /** Represents a Brand Partner. */
  brandPartner?: Maybe<Array<Maybe<BrandPartner>>>;
  brands: Array<Brand>;
  /** Represents a Buyers Page. */
  buyersPage?: Maybe<Array<Maybe<BuyersPage>>>;
  /** Represents a Category. */
  category?: Maybe<Array<Maybe<Category>>>;
  /** Represents a Contact Page. */
  contactPage?: Maybe<Array<Maybe<ContactPage>>>;
  contactRequestByIdAsync?: Maybe<RequestForContact>;
  contactRequestMessageById: Array<MessageDto>;
  contactRequestMessages?: Maybe<ContactRequestMessagesCollectionSegment>;
  contactRequestsAsync?: Maybe<ContactRequestsAsyncCollectionSegment>;
  /** Content items are instances of content types, just like objects are instances of classes. */
  contentItem?: Maybe<ContentItem>;
  currentAdminUser: AdUserDto;
  /** Represents a Custom Offer Content. */
  customOfferContent?: Maybe<Array<Maybe<CustomOfferContent>>>;
  customOfferRequest?: Maybe<RequestForCustomOffer>;
  customOfferRequests?: Maybe<CustomOfferRequestsCollectionSegment>;
  cypressAccessToken: Scalars['String'];
  designPackage?: Maybe<DesignPackage>;
  /** Represents a Design package page. */
  designPackagePage?: Maybe<Array<Maybe<DesignPackagePage>>>;
  designPackageProductSetRestrictionInfoKeys: Array<DesignPackageProductSetRestrictionInfoKey>;
  /** Represents a Design Package Selection. */
  designPackageSelection?: Maybe<Array<Maybe<DesignPackageSelection>>>;
  designPackages: Array<DesignPackage>;
  distinctProductCodes: Array<Scalars['String']>;
  /** Represents a Error Definitions. */
  errorDefinitions?: Maybe<Array<Maybe<ErrorDefinitions>>>;
  /** Represents a Explanation Page. */
  explanationPage?: Maybe<Array<Maybe<ExplanationPage>>>;
  externalPreviewToken: Scalars['String'];
  /** Represents a Veelgestelde vragen. */
  fAQPage?: Maybe<Array<Maybe<FaqPage>>>;
  /** Represents a Help page. */
  helpPage?: Maybe<Array<Maybe<HelpPage>>>;
  /** Represents a Home Page. */
  homePage?: Maybe<Array<Maybe<HomePage>>>;
  /** Represents a How Does It Work - Content Item. */
  howDoesItWorkContentItem?: Maybe<Array<Maybe<HowDoesItWorkContentItem>>>;
  /** Represents a How Does It Work Page - Design Rooms. */
  howDoesItWorkPageDesignRooms?: Maybe<Array<Maybe<HowDoesItWorkPageDesignRooms>>>;
  /** Represents a How Does It Work Page - New Home. */
  howDoesItWorkPageNewHome?: Maybe<Array<Maybe<HowDoesItWorkPageNewHome>>>;
  importProductPackages: Array<ImportProductPackage>;
  /** Represents a Landing Page. */
  landingPage?: Maybe<Array<Maybe<LandingPage>>>;
  /** Represents a Layouts. */
  layouts?: Maybe<Array<Maybe<Layouts>>>;
  masterProductPackage?: Maybe<ProductPackage>;
  me?: Maybe<RealEstateContact>;
  /** Media assets are items that are part of your media library. */
  mediaAssets?: Maybe<Array<Maybe<MediaAsset>>>;
  /** Represents a Order Confirmation Page. */
  orderConfirmationPage?: Maybe<Array<Maybe<OrderConfirmationPage>>>;
  previewToken: Scalars['String'];
  /** Represents a Product Brand Line Details. */
  productBrandLineDetails?: Maybe<Array<Maybe<ProductBrandLineDetails>>>;
  productCategories: Array<ProductCategory>;
  productCategoryProductSetInfoKeys: Array<ProductCategoryProductSetInfoKey>;
  /** Represents a Product Combination Page. */
  productCombinationPage?: Maybe<Array<Maybe<ProductCombinationPage>>>;
  /** Represents a Product Combination Position. */
  productCombinationPosition?: Maybe<Array<Maybe<ProductCombinationPosition>>>;
  productCombinations: Array<ProductCombination>;
  /** Represents a Product Configuration Name Popup. */
  productConfigurationNamePopup?: Maybe<Array<Maybe<ProductConfigurationNamePopup>>>;
  productInfo?: Maybe<Product>;
  productPackage?: Maybe<ProductPackage>;
  /** Represents a Product Package Page. */
  productPackagePage?: Maybe<Array<Maybe<ProductPackagePage>>>;
  productPackages: Array<ProductPackage>;
  /** Represents a Product Set Details. */
  productSetDetails?: Maybe<Array<Maybe<ProductSetDetails>>>;
  productSetInfoKeys: Array<ProductSetInfoKey>;
  /** Represents a Product Set Page. */
  productSetPage?: Maybe<Array<Maybe<ProductSetPage>>>;
  products: Array<Product>;
  project?: Maybe<Project>;
  projectActivities: Array<ProjectActivity>;
  /** Represents a Project Landing Page. */
  projectLandingPage?: Maybe<Array<Maybe<ProjectLandingPage>>>;
  /** Represents a Project page. */
  projectPage?: Maybe<Array<Maybe<ProjectPage>>>;
  projects?: Maybe<ProjectsCollectionSegment>;
  realEstate?: Maybe<RealEstate>;
  realEstateOrder?: Maybe<RealEstateOrder>;
  rensaUsers: Array<AdUserDto>;
  /** Represents a Request Drawing Popup. */
  requestDrawingPopup?: Maybe<Array<Maybe<RequestDrawingPopup>>>;
  requestFor3DDrawingDetails: RequestFor3DDrawingDetailDto;
  requestFor3DDrawingDetailsAsync: Array<CustomOffer>;
  requestsFor3DDrawings?: Maybe<RequestsFor3DDrawingsCollectionSegment>;
  requestsForTechnicalDrawings?: Maybe<RequestsForTechnicalDrawingsCollectionSegment>;
  /** Represents a Room Page. */
  roomPage?: Maybe<Array<Maybe<RoomPage>>>;
  rooms: Array<Room>;
  /** The active cultures configured for the site. */
  siteCultures?: Maybe<Array<Maybe<SiteCulture>>>;
  /** Represents a Style detail. */
  styleDetail?: Maybe<Array<Maybe<StyleDetail>>>;
  /** Represents a Style Listing Page. */
  styleListingPage?: Maybe<Array<Maybe<StyleListingPage>>>;
  /** Represents a Style page. */
  stylePage?: Maybe<Array<Maybe<StylePage>>>;
  styles: Array<Style>;
  /** Represents a Success Page. */
  successPage?: Maybe<Array<Maybe<SuccessPage>>>;
  /** Represents a Terms And Conditions Page. */
  termsAndConditionsPage?: Maybe<Array<Maybe<TermsAndConditionsPage>>>;
  /** Represents a Terms & Conditions. */
  termsConditions?: Maybe<Array<Maybe<TermsConditions>>>;
  /** Represents a Translation. */
  translation?: Maybe<Array<Maybe<Translation>>>;
  /** Represents a Under Construction Page. */
  underConstructionPage?: Maybe<Array<Maybe<UnderConstructionPage>>>;
  userLogin: UserLoginDto;
};


export type QueryAboutPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AboutPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<AboutPageWhereInput>;
};


export type QueryAccessTokenArgs = {
  accessId: Scalars['UUID'];
  realEstateId: Scalars['UUID'];
};


export type QueryActivityTemplateArgs = {
  id: Scalars['UUID'];
};


export type QueryActivityTemplatesArgs = {
  order?: InputMaybe<Array<ActivityTemplateSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityTemplateFilterInput>;
};


export type QueryAdminUserArgs = {
  userId: Scalars['String'];
};


export type QueryBecomePartnerPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BecomePartnerPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BecomePartnerPageWhereInput>;
};


export type QueryBrandDetailArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BrandDetailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BrandDetailWhereInput>;
};


export type QueryBrandListingPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BrandListingPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BrandListingPageWhereInput>;
};


export type QueryBrandPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BrandPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BrandPageWhereInput>;
};


export type QueryBrandPartnerArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BrandPartnerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BrandPartnerWhereInput>;
};


export type QueryBrandsArgs = {
  where?: InputMaybe<BrandFilterInput>;
};


export type QueryBuyersPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuyersPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<BuyersPageWhereInput>;
};


export type QueryCategoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryContactPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ContactPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ContactPageWhereInput>;
};


export type QueryContactRequestByIdAsyncArgs = {
  id: Scalars['UUID'];
};


export type QueryContactRequestMessageByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryContactRequestMessagesArgs = {
  order?: InputMaybe<Array<MessageDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageDtoFilterInput>;
};


export type QueryContactRequestsAsyncArgs = {
  order?: InputMaybe<Array<RequestForContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestForContactFilterInput>;
};


export type QueryContentItemArgs = {
  contentItemId: Scalars['String'];
};


export type QueryCustomOfferContentArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CustomOfferContentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<CustomOfferContentWhereInput>;
};


export type QueryCustomOfferRequestArgs = {
  id: Scalars['UUID'];
};


export type QueryCustomOfferRequestsArgs = {
  customOfferRequestStatus?: InputMaybe<CustomOfferRequestStatus>;
  order?: InputMaybe<Array<RequestForCustomOfferSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestForCustomOfferFilterInput>;
};


export type QueryCypressAccessTokenArgs = {
  secret: Scalars['UUID'];
};


export type QueryDesignPackageArgs = {
  id: Scalars['UUID'];
};


export type QueryDesignPackagePageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DesignPackagePageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<DesignPackagePageWhereInput>;
};


export type QueryDesignPackageProductSetRestrictionInfoKeysArgs = {
  order?: InputMaybe<Array<DesignPackageProductSetRestrictionInfoKeySortInput>>;
  where?: InputMaybe<DesignPackageProductSetRestrictionInfoKeyFilterInput>;
};


export type QueryDesignPackageSelectionArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DesignPackageSelectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<DesignPackageSelectionWhereInput>;
};


export type QueryDesignPackagesArgs = {
  where?: InputMaybe<DesignPackageFilterInput>;
};


export type QueryErrorDefinitionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ErrorDefinitionsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ErrorDefinitionsWhereInput>;
};


export type QueryExplanationPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExplanationPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ExplanationPageWhereInput>;
};


export type QueryExternalPreviewTokenArgs = {
  realEstateId: Scalars['UUID'];
};


export type QueryFAqPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FaqPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<FaqPageWhereInput>;
};


export type QueryHelpPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HelpPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<HelpPageWhereInput>;
};


export type QueryHomePageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HomePageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<HomePageWhereInput>;
};


export type QueryHowDoesItWorkContentItemArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HowDoesItWorkContentItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<HowDoesItWorkContentItemWhereInput>;
};


export type QueryHowDoesItWorkPageDesignRoomsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HowDoesItWorkPageDesignRoomsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<HowDoesItWorkPageDesignRoomsWhereInput>;
};


export type QueryHowDoesItWorkPageNewHomeArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HowDoesItWorkPageNewHomeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<HowDoesItWorkPageNewHomeWhereInput>;
};


export type QueryImportProductPackagesArgs = {
  order?: InputMaybe<Array<ImportProductPackageSortInput>>;
  where?: InputMaybe<ImportProductPackageFilterInput>;
};


export type QueryLandingPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<LandingPageWhereInput>;
};


export type QueryLayoutsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LayoutsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<LayoutsWhereInput>;
};


export type QueryMediaAssetsArgs = {
  includeSubDirectories?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['String']>;
};


export type QueryOrderConfirmationPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderConfirmationPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<OrderConfirmationPageWhereInput>;
};


export type QueryPreviewTokenArgs = {
  realEstateId: Scalars['UUID'];
};


export type QueryProductBrandLineDetailsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductBrandLineDetailsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductBrandLineDetailsWhereInput>;
};


export type QueryProductCategoriesArgs = {
  order?: InputMaybe<Array<ProductCategorySortInput>>;
  where?: InputMaybe<ProductCategoryFilterInput>;
};


export type QueryProductCategoryProductSetInfoKeysArgs = {
  order?: InputMaybe<Array<ProductCategoryProductSetInfoKeySortInput>>;
  where?: InputMaybe<ProductCategoryProductSetInfoKeyFilterInput>;
};


export type QueryProductCombinationPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductCombinationPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductCombinationPageWhereInput>;
};


export type QueryProductCombinationPositionArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductCombinationPositionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductCombinationPositionWhereInput>;
};


export type QueryProductCombinationsArgs = {
  order?: InputMaybe<Array<ProductCombinationSortInput>>;
  where?: InputMaybe<ProductCombinationFilterInput>;
};


export type QueryProductConfigurationNamePopupArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductConfigurationNamePopupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductConfigurationNamePopupWhereInput>;
};


export type QueryProductInfoArgs = {
  code: Scalars['String'];
};


export type QueryProductPackageArgs = {
  id: Scalars['UUID'];
};


export type QueryProductPackagePageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductPackagePageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductPackagePageWhereInput>;
};


export type QueryProductPackagesArgs = {
  where?: InputMaybe<ProductPackageFilterInput>;
};


export type QueryProductSetDetailsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductSetDetailsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductSetDetailsWhereInput>;
};


export type QueryProductSetInfoKeysArgs = {
  order?: InputMaybe<Array<ProductSetInfoKeySortInput>>;
  where?: InputMaybe<ProductSetInfoKeyFilterInput>;
};


export type QueryProductSetPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProductSetPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProductSetPageWhereInput>;
};


export type QueryProductsArgs = {
  where?: InputMaybe<ProductFilterInput>;
};


export type QueryProjectArgs = {
  id: Scalars['UUID'];
};


export type QueryProjectActivitiesArgs = {
  order?: InputMaybe<Array<ProjectActivitySortInput>>;
  where?: InputMaybe<ProjectActivityFilterInput>;
};


export type QueryProjectLandingPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProjectLandingPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProjectLandingPageWhereInput>;
};


export type QueryProjectPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProjectPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<ProjectPageWhereInput>;
};


export type QueryProjectsArgs = {
  order?: InputMaybe<Array<ProjectSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectFilterInput>;
};


export type QueryRealEstateArgs = {
  id: Scalars['UUID'];
};


export type QueryRealEstateOrderArgs = {
  realEstateId: Scalars['UUID'];
};


export type QueryRensaUsersArgs = {
  filterType: UsersGroupFilterType;
  searchTerm: Scalars['String'];
};


export type QueryRequestDrawingPopupArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RequestDrawingPopupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<RequestDrawingPopupWhereInput>;
};


export type QueryRequestFor3DDrawingDetailsArgs = {
  id: Scalars['UUID'];
};


export type QueryRequestFor3DDrawingDetailsAsyncArgs = {
  id: Scalars['UUID'];
};


export type QueryRequestsFor3DDrawingsArgs = {
  order?: InputMaybe<Array<RequestFor3DDrawingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestFor3DDrawingFilterInput>;
};


export type QueryRequestsForTechnicalDrawingsArgs = {
  order?: InputMaybe<Array<RequestForTechnicalDrawingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestForTechnicalDrawingFilterInput>;
};


export type QueryRoomPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RoomPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<RoomPageWhereInput>;
};


export type QueryRoomsArgs = {
  order?: InputMaybe<Array<RoomSortInput>>;
  where?: InputMaybe<RoomFilterInput>;
};


export type QueryStyleDetailArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StyleDetailOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<StyleDetailWhereInput>;
};


export type QueryStyleListingPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StyleListingPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<StyleListingPageWhereInput>;
};


export type QueryStylePageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StylePageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<StylePageWhereInput>;
};


export type QueryStylesArgs = {
  where?: InputMaybe<StyleFilterInput>;
};


export type QuerySuccessPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SuccessPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<SuccessPageWhereInput>;
};


export type QueryTermsAndConditionsPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TermsAndConditionsPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<TermsAndConditionsPageWhereInput>;
};


export type QueryTermsConditionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TermsConditionsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<TermsConditionsWhereInput>;
};


export type QueryTranslationArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TranslationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<TranslationWhereInput>;
};


export type QueryUnderConstructionPageArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnderConstructionPageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  where?: InputMaybe<UnderConstructionPageWhereInput>;
};


export type QueryUserLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Real estate represents a single building. */
export type RealEstate = {
  __typename?: 'RealEstate';
  /** Gets the current active external action of the real estate. */
  activeExternalAction?: Maybe<RealEstateExternalAction>;
  activeRequestForContacts?: Maybe<Array<Maybe<MessageDto>>>;
  /**
   * Gets the current active status of the real estate.
   *
   *
   * **Returns:**
   * The active status.
   */
  activeStatus?: Maybe<RealEstateStatusUpdate>;
  /**
   * Gets whether there a quote request has already been done for this real estate.
   *
   *
   * **Returns:**
   * True if a quote has been requested
   */
  areQuotesRequested: Scalars['Boolean'];
  /** Gets the build number (bouwnummer) of the real estate. */
  buildNumber?: Maybe<Scalars['String']>;
  /**
   * Gets the date and time the RealEstateContact is no longer allowed to select a DesignPackage for this RealEstate.
   * ///
   */
  closeDateUtc?: Maybe<Scalars['DateTime']>;
  /** Gets the RealEstateContact for this real estate. */
  contact?: Maybe<RealEstateContact>;
  customOfferRequest?: Maybe<RequestForCustomOffer>;
  customOfferSelections: Array<Maybe<RealEstateCustomOfferSelection>>;
  /**
   * Gets the date and time the dossier was last sent.
   * ///
   */
  dossierSentDateUtc?: Maybe<Scalars['DateTime']>;
  /** Gets the collection of RealEstateExternalAction of this real estate. */
  externalActions: Array<RealEstateExternalAction>;
  files: Array<RealEstateFileDto>;
  hasAdditionalWork?: Maybe<Scalars['Boolean']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /**
   * Gets whether the real estate is open for quote requests.
   * Business rule: Real Estates that have requests for quotes are not open for new ones.
   *
   *
   * **Returns:**
   * true if the real estate is open for quote requests, otherwise false
   */
  isOpenForQuoteRequests: Scalars['Boolean'];
  /**
   * Gets whether the real estate was invited.
   *
   *
   * **Returns:**
   * true if the real estate was invited.
   */
  isRealEstateInvited: Scalars['Boolean'];
  /** Gets the Layout of the real estate. */
  layout: Layout;
  /** Gets the collection of notes of this real estate. */
  notes?: Maybe<Array<Maybe<MessageDto>>>;
  /** Gets the number (no) of this real estate in Estimate. */
  number: Scalars['String'];
  /** Gets the Project this real estate belongs to. */
  project: Project;
  realEstateOrder?: Maybe<RealEstateOrder>;
  requestFor3DDrawings: Array<RequestFor3DDrawing>;
  /** Gets the collection of RequestForQuote requested by this real estate. */
  requestForQuotes: Array<RequestForQuote>;
  /** To be used by the projects listing. */
  requestForQuotesV2: Array<RequestForQuote>;
  roomSelections: Array<Maybe<RealEstateRoomSelection>>;
  roomSelectionsCosts: RealEstateSelectionCostsDto;
  /** Gets the collection of RealEstateStatusUpdate of this real estate. */
  statusUpdates: Array<RealEstateStatusUpdate>;
};


/** Real estate represents a single building. */
export type RealEstateRequestFor3DDrawingsArgs = {
  where?: InputMaybe<RequestFor3DDrawingFilterInput>;
};


/** Real estate represents a single building. */
export type RealEstateRequestForQuotesArgs = {
  where?: InputMaybe<RequestForQuoteFilterInput>;
};


/** Real estate represents a single building. */
export type RealEstateRequestForQuotesV2Args = {
  where?: InputMaybe<RequestForQuoteFilterInput>;
};

/** Represents a single person that is a future owner of the RealEstate in the scope of one RealEstate within one Project. */
export type RealEstateContact = {
  __typename?: 'RealEstateContact';
  /** Gets the access token for the contact. */
  accessToken: Scalars['UUID'];
  /** Gets the email address of the contact. */
  email: Email;
  /** Gets the formal name for this contact according to Estimate. */
  formalName?: Maybe<Scalars['String']>;
  /**
   * Checks if the contact has a user.
   *
   *
   * **Returns:**
   * True if the contact has a user.
   */
  hasUser: Scalars['Boolean'];
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the date and time this contact was sent an invitation. */
  invitationSent?: Maybe<Scalars['DateTime']>;
  /** Gets the date and time this contact logged in. */
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastNoLoginNotification?: Maybe<RealEstateContactNotification>;
  lastNoQuoteNotification?: Maybe<RealEstateContactNotification>;
  /** Gets the legal name for this contact according to Estimate. */
  legalName?: Maybe<Scalars['String']>;
  /** Gets a value indicating whether the notifications are disabled. */
  notificationsDisabled: Scalars['Boolean'];
  /** Gets the phone number of the contact. */
  phone?: Maybe<PhoneNumber>;
  /** Gets the RealEstate this is a contact for. */
  realEstate: RealEstate;
};

/** Represents a single person that is a future owner of the RealEstate in the scope of one RealEstate within one Project. */
export type RealEstateContactFilterInput = {
  /** Gets the access token for the contact. */
  accessToken?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RealEstateContactFilterInput>>;
  /** Gets the email address of the contact. */
  email?: InputMaybe<EmailFilterInput>;
  /** Gets the formal name for this contact according to Estimate. */
  formalName?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the date and time this contact was sent an invitation. */
  invitationSent?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the date and time this contact logged in. */
  lastLogin?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the legal name for this contact according to Estimate. */
  legalName?: InputMaybe<StringOperationFilterInput>;
  /** Gets a value indicating whether the notifications are disabled. */
  notificationsDisabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RealEstateContactFilterInput>>;
  /** Gets the phone number of the contact. */
  phone?: InputMaybe<PhoneNumberFilterInput>;
  /** Gets the RealEstate this is a contact for. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the collection of RealEstateContactNotification sent to this real estate contact. */
  realEstateContactNotifications?: InputMaybe<ListFilterInputTypeOfRealEstateContactNotificationFilterInput>;
  /** Gets the id of the real estate this contact will be the owner for. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the user this contact belongs to. */
  user?: InputMaybe<UserFilterInput>;
  /** Gets the user id this contact belongs to. */
  userId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a single notification sent to a person that is a future owner of the RealEstate in the scope of one RealEstate within one Project. */
export type RealEstateContactNotification = {
  __typename?: 'RealEstateContactNotification';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the reminder notification type. */
  notificationType: ReminderNotificationType;
  /** Gets the RealEstateContact this notifications was sent. */
  realEstateContact: RealEstateContact;
  /** Gets the real estate contact identifier. */
  realEstateContactId: Scalars['UUID'];
  /** Gets the date the notification was sent. */
  sentDate: Scalars['DateTime'];
};

/** Represents a single notification sent to a person that is a future owner of the RealEstate in the scope of one RealEstate within one Project. */
export type RealEstateContactNotificationFilterInput = {
  and?: InputMaybe<Array<RealEstateContactNotificationFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the reminder notification type. */
  notificationType?: InputMaybe<ReminderNotificationTypeOperationFilterInput>;
  or?: InputMaybe<Array<RealEstateContactNotificationFilterInput>>;
  /** Gets the RealEstateContact this notifications was sent. */
  realEstateContact?: InputMaybe<RealEstateContactFilterInput>;
  /** Gets the real estate contact identifier. */
  realEstateContactId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the date the notification was sent. */
  sentDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Represents a single person that is a future owner of the RealEstate in the scope of one RealEstate within one Project. */
export type RealEstateContactSortInput = {
  /** Gets the access token for the contact. */
  accessToken?: InputMaybe<SortEnumType>;
  /** Gets the email address of the contact. */
  email?: InputMaybe<EmailSortInput>;
  /** Gets the formal name for this contact according to Estimate. */
  formalName?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the date and time this contact was sent an invitation. */
  invitationSent?: InputMaybe<SortEnumType>;
  /** Gets the date and time this contact logged in. */
  lastLogin?: InputMaybe<SortEnumType>;
  /** Gets the legal name for this contact according to Estimate. */
  legalName?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether the notifications are disabled. */
  notificationsDisabled?: InputMaybe<SortEnumType>;
  /** Gets the phone number of the contact. */
  phone?: InputMaybe<PhoneNumberSortInput>;
  /** Gets the RealEstate this is a contact for. */
  realEstate?: InputMaybe<RealEstateSortInput>;
  /** Gets the id of the real estate this contact will be the owner for. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the user this contact belongs to. */
  user?: InputMaybe<UserSortInput>;
  /** Gets the user id this contact belongs to. */
  userId?: InputMaybe<SortEnumType>;
};

/** Contains information about a single selection for a room. */
export type RealEstateCustomOfferSelection = {
  __typename?: 'RealEstateCustomOfferSelection';
  customOffer: CustomOffer;
  /** Gets or sets the custom offer id. */
  customOfferId: Scalars['UUID'];
  /** Gets unique identifier for a Room selection. */
  id: Scalars['UUID'];
  /** Gets or sets a value indicating whether this selection is choosen. */
  isSelected: Scalars['Boolean'];
  /** Gets or sets the name for this selection. */
  name: Scalars['String'];
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
  /** Gets or sets the aditional value to be paid for the selection. */
  surcharge?: Maybe<Money>;
};

/** Represents an action pending on an external entity. */
export type RealEstateExternalAction = {
  __typename?: 'RealEstateExternalAction';
  /** Gets the date the external action was closed. */
  closeDateUtc?: Maybe<Scalars['DateTime']>;
  /** Gets the target of the external action. */
  externalActionTarget: RealEstateExternalActionTargetType;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  notes?: Maybe<Array<Maybe<MessageDto>>>;
  /** Gets the date the external action was opened. */
  requestDateUtc: Scalars['DateTime'];
  /** Gets the external action status. */
  status: RealEstateExternalActionStatus;
};

/** Represents an action pending on an external entity. */
export type RealEstateExternalActionFilterInput = {
  and?: InputMaybe<Array<RealEstateExternalActionFilterInput>>;
  /** Gets the date the external action was closed. */
  closeDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the target of the external action. */
  externalActionTarget?: InputMaybe<RealEstateExternalActionTargetTypeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RealEstateExternalActionFilterInput>>;
  /** Gets the real estate identifier. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the date the external action was opened. */
  requestDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the external action status. */
  status?: InputMaybe<RealEstateExternalActionStatusOperationFilterInput>;
};

/** Represents an action pending on an external entity. */
export type RealEstateExternalActionSortInput = {
  /** Gets the date the external action was closed. */
  closeDateUtc?: InputMaybe<SortEnumType>;
  /** Gets the target of the external action. */
  externalActionTarget?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the real estate identifier. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the date the external action was opened. */
  requestDateUtc?: InputMaybe<SortEnumType>;
  /** Gets the external action status. */
  status?: InputMaybe<SortEnumType>;
};

/** The valid status for an external action. */
export enum RealEstateExternalActionStatus {
  /** Closed */
  Closed = 'CLOSED',
  /** New */
  New = 'NEW',
  /** Pending */
  Pending = 'PENDING'
}

export type RealEstateExternalActionStatusOperationFilterInput = {
  eq?: InputMaybe<RealEstateExternalActionStatus>;
  in?: InputMaybe<Array<RealEstateExternalActionStatus>>;
  neq?: InputMaybe<RealEstateExternalActionStatus>;
  nin?: InputMaybe<Array<RealEstateExternalActionStatus>>;
};

/** The type on entities that are the target of the external action. */
export enum RealEstateExternalActionTargetType {
  /** Contractor. */
  Contractor = 'CONTRACTOR',
  /** Installer. */
  Installer = 'INSTALLER',
  /** Other target entity. */
  Other = 'OTHER'
}

export type RealEstateExternalActionTargetTypeOperationFilterInput = {
  eq?: InputMaybe<RealEstateExternalActionTargetType>;
  in?: InputMaybe<Array<RealEstateExternalActionTargetType>>;
  neq?: InputMaybe<RealEstateExternalActionTargetType>;
  nin?: InputMaybe<Array<RealEstateExternalActionTargetType>>;
};

export type RealEstateFileDto = {
  __typename?: 'RealEstateFileDto';
  fileExtension: FileExtension;
  isDeletable: Scalars['Boolean'];
  lastModified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
  setIsDeletable: Scalars['Boolean'];
  setName: Scalars['String'];
  type: UploadFileType;
};


export type RealEstateFileDtoSetIsDeletableArgs = {
  isDeletable: Scalars['Boolean'];
};


export type RealEstateFileDtoSetNameArgs = {
  name: Scalars['String'];
};

/** Real estate represents a single building. */
export type RealEstateFilterInput = {
  /**
   * Gets the current active custom offer request.
   *
   *
   * **Returns:**
   * The custom offer request if any.
   */
  activeCustomOfferRequest?: InputMaybe<RequestForCustomOfferFilterInput>;
  /** Gets the current active external action of the real estate. */
  activeExternalAction?: InputMaybe<RealEstateExternalActionFilterInput>;
  /**
   * Gets the current active status of the real estate.
   *
   *
   * **Returns:**
   * The active status.
   */
  activeStatus?: InputMaybe<RealEstateStatusUpdateFilterInput>;
  and?: InputMaybe<Array<RealEstateFilterInput>>;
  /** Gets the build number (bouwnummer) of the real estate. */
  buildNumber?: InputMaybe<StringOperationFilterInput>;
  /**
   * Gets the date and time the RealEstateContact is no longer allowed to select a DesignPackage for this RealEstate.
   * ///
   */
  closeDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the RealEstateContact for this real estate. */
  contact?: InputMaybe<RealEstateContactFilterInput>;
  /**
   * Gets the date and time the dossier was last sent.
   * ///
   */
  dossierSentDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the collection of RealEstateExternalAction of this real estate. */
  externalActions?: InputMaybe<ListFilterInputTypeOfRealEstateExternalActionFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the Layout of the real estate. */
  layout?: InputMaybe<LayoutFilterInput>;
  /** Gets the number (no) of this real estate in Estimate. */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RealEstateFilterInput>>;
  /** Gets the Project this real estate belongs to. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the id of the project this real estate belongs to. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the collection of RequestFor3DDrawing requested by this real estate. */
  requestFor3DDrawings?: InputMaybe<ListFilterInputTypeOfRequestFor3DDrawingFilterInput>;
  /** Gets the collection of RequestForContact requested by this real estate. */
  requestForContacts?: InputMaybe<ListFilterInputTypeOfRequestForContactFilterInput>;
  /** Gets the collection of RequestForCustomOffer requested by this real estate. */
  requestForCustomOffers?: InputMaybe<ListFilterInputTypeOfRequestForCustomOfferFilterInput>;
  /** Gets the collection of RequestForQuote requested by this real estate. */
  requestForQuotes?: InputMaybe<ListFilterInputTypeOfRequestForQuoteFilterInput>;
  /** Gets the collection of RequestForTechnicalDrawing requested by this real estate. */
  requestForTechnicalDrawings?: InputMaybe<ListFilterInputTypeOfRequestForTechnicalDrawingFilterInput>;
  /** Gets the collection of RealEstateStatusUpdate of this real estate. */
  statusUpdates?: InputMaybe<ListFilterInputTypeOfRealEstateStatusUpdateFilterInput>;
};

/** Real estate order. */
export type RealEstateOrder = {
  __typename?: 'RealEstateOrder';
  /** Gets or sets the real estate build number. */
  buildNumber?: Maybe<Scalars['String']>;
  /** Gets or sets confirmed status type. */
  confirmedStatusType: ConfirmedStatusType;
  /** Gets or sets the order costs. */
  costs: Array<Maybe<CostEntry>>;
  /** Gets or sets date when was created. */
  createdDate: Scalars['DateTime'];
  /** Gets or sets the room selections. */
  globalCustomOffer?: Maybe<OrderCustomOffer>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets or sets the project identifier. */
  projectId: Scalars['UUID'];
  /** Gets or sets the project name. */
  projectName: Scalars['String'];
  /** Gets or sets the project number. */
  projectNumber: Scalars['String'];
  /** Gets or sets the project type. */
  projectType: Badkamercore_ProjectType;
  /** Gets or sets the real estate identifier. */
  realEstateId: Scalars['UUID'];
  /** Gets or sets the real estate number. */
  realEstateNumber: Scalars['String'];
  /** Gets or sets the room selections. */
  rooms: Array<OrderRoom>;
};

/** Contains information about a single selection for a room. */
export type RealEstateRoomSelection = {
  __typename?: 'RealEstateRoomSelection';
  /** Gets or sets the custom offer id. */
  customOfferId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the design package identifier. */
  designPackageId?: Maybe<Scalars['UUID']>;
  /** Gets or sets a value indicating whether the selection contains one or more products that are no longer available. */
  hasUnavailableProductSets?: Maybe<Scalars['Boolean']>;
  /** Gets unique identifier for a Room selection. */
  id: Scalars['UUID'];
  /** Gets or sets a value indicating whether this selection is choosen. */
  isSelected: Scalars['Boolean'];
  /** Gets or sets the name for this selection. */
  name?: Maybe<Scalars['String']>;
  /** Gets or sets the status of the request for a 3D drawing. */
  request3DDrawingStatus?: Maybe<DrawingRequestStatus>;
  /** Gets or sets the room identifier. */
  roomId: Scalars['UUID'];
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
  /** Gets or sets the aditional value to be paid for the selection. */
  surcharge?: Maybe<Money>;
};

/** Contains information about the real estate selection costs. */
export type RealEstateSelectionCostsDto = {
  __typename?: 'RealEstateSelectionCostsDto';
  /** Gets or sets contains the cost overview specifications. */
  costOverview: Array<KeyValuePairOfCostTypeAndMoney>;
};

/** Real estate represents a single building. */
export type RealEstateSortInput = {
  /**
   * Gets the current active custom offer request.
   *
   *
   * **Returns:**
   * The custom offer request if any.
   */
  activeCustomOfferRequest?: InputMaybe<RequestForCustomOfferSortInput>;
  /** Gets the current active external action of the real estate. */
  activeExternalAction?: InputMaybe<RealEstateExternalActionSortInput>;
  /**
   * Gets the current active status of the real estate.
   *
   *
   * **Returns:**
   * The active status.
   */
  activeStatus?: InputMaybe<RealEstateStatusUpdateSortInput>;
  /** Gets the build number (bouwnummer) of the real estate. */
  buildNumber?: InputMaybe<SortEnumType>;
  /**
   * Gets the date and time the RealEstateContact is no longer allowed to select a DesignPackage for this RealEstate.
   * ///
   */
  closeDateUtc?: InputMaybe<SortEnumType>;
  /** Gets the RealEstateContact for this real estate. */
  contact?: InputMaybe<RealEstateContactSortInput>;
  /**
   * Gets the date and time the dossier was last sent.
   * ///
   */
  dossierSentDateUtc?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the Layout of the real estate. */
  layout?: InputMaybe<LayoutSortInput>;
  /** Gets the number (no) of this real estate in Estimate. */
  number?: InputMaybe<SortEnumType>;
  /** Gets the Project this real estate belongs to. */
  project?: InputMaybe<ProjectSortInput>;
  /** Gets the id of the project this real estate belongs to. */
  projectId?: InputMaybe<SortEnumType>;
};

/** The status of the RealEstate. */
export enum RealEstateStatus {
  /** Real estate choosing. */
  BuyerChoosing = 'BUYER_CHOOSING',
  /** Completed process. */
  Completed = 'COMPLETED',
  /** Waiting for draftsman. */
  ControlDraftsman = 'CONTROL_DRAFTSMAN',
  /** Real estate requested Custom Offer. */
  CustomOffer = 'CUSTOM_OFFER',
  /** Real estate invited to the platform. */
  Invited = 'INVITED',
  /** Real estate sold to new buyer. */
  NewBuyer = 'NEW_BUYER',
  /** Ready to send dossier. */
  SendDossier = 'SEND_DOSSIER',
  /** Ready to technical drawing. */
  TechnicalDrawing = 'TECHNICAL_DRAWING',
  /** Real estate not sold yet. */
  Unsold = 'UNSOLD'
}

export type RealEstateStatusOperationFilterInput = {
  eq?: InputMaybe<RealEstateStatus>;
  in?: InputMaybe<Array<RealEstateStatus>>;
  neq?: InputMaybe<RealEstateStatus>;
  nin?: InputMaybe<Array<RealEstateStatus>>;
};

/** Represent an update on the RealEstate status. */
export type RealEstateStatusUpdate = {
  __typename?: 'RealEstateStatusUpdate';
  /** Gets the RealEstate status. */
  status: RealEstateStatus;
  /** Gets the date when the status was updated. */
  updateDate: Scalars['DateTime'];
};

/** Represent an update on the RealEstate status. */
export type RealEstateStatusUpdateFilterInput = {
  and?: InputMaybe<Array<RealEstateStatusUpdateFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RealEstateStatusUpdateFilterInput>>;
  /** Gets the RealEstate identifier. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the RealEstate status. */
  status?: InputMaybe<RealEstateStatusOperationFilterInput>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Represent an update on the RealEstate status. */
export type RealEstateStatusUpdateSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the RealEstate identifier. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the RealEstate status. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<SortEnumType>;
};

/** Available reminder notification types. */
export enum ReminderNotificationType {
  /** Notification sent when the real estate haven't logged in yet. */
  NoLogin = 'NO_LOGIN',
  /** Notification sent when the real estate haven't sent a quote yet. */
  NoQuote = 'NO_QUOTE'
}

export type ReminderNotificationTypeOperationFilterInput = {
  eq?: InputMaybe<ReminderNotificationType>;
  in?: InputMaybe<Array<ReminderNotificationType>>;
  neq?: InputMaybe<ReminderNotificationType>;
  nin?: InputMaybe<Array<ReminderNotificationType>>;
};

/** Represents a Request Drawing Popup. */
export type RequestDrawingPopup = ContentItem & {
  __typename?: 'RequestDrawingPopup';
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  cancelButton?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestCancelbutton?: Maybe<Scalars['String']>;
  /** Text field */
  cancelRequestConfirmbutton?: Maybe<Scalars['String']>;
  /** HtmlField */
  cancelRequestContent?: Maybe<HtmlField>;
  /** Text field */
  cancelRequestTitle?: Maybe<Scalars['String']>;
  /** Text field */
  confirmButton?: Maybe<Scalars['String']>;
  /** HtmlField */
  content?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** Text field */
  defaultTriggerTextCancelRequest?: Maybe<Scalars['String']>;
  /** Text field */
  defaultTriggerTextDownloadDrawing?: Maybe<Scalars['String']>;
  /** Text field */
  defaultTriggerTextDrawingPending?: Maybe<Scalars['String']>;
  /** Text field */
  defaultTriggerTextRequestDrawing?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type RequestDrawingPopupOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the RequestDrawingPopup content item filters */
export type RequestDrawingPopupWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<RequestDrawingPopupWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<RequestDrawingPopupWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<RequestDrawingPopupWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a request for a 3D drawing on a selected DesignPackage (for a specific room) in a specific RealEstate with a specific list of selected ProductSets. */
export type RequestFor3DDrawing = {
  __typename?: 'RequestFor3DDrawing';
  assignedDrawer?: Maybe<AdUserDto>;
  /** Gets the identifier of the drawer assigned to 3d drawing request. */
  assignedDrawerId?: Maybe<Scalars['String']>;
  /** Gets the name for this selection. */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Gets the design package for this request. */
  designPackage: DesignPackage;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the identifier for this product selection. */
  productSelectionId: Scalars['UUID'];
  /** Gets the real estate for this request. */
  realEstate: RealEstate;
  /** Gets the status of the request for a 3D drawing. */
  request3DDrawingStatus?: Maybe<DrawingRequestStatus>;
  /** Gets the name for this selection. */
  requested: Scalars['DateTime'];
  /** Gets the identifier for this room selection. */
  roomSelectionId: Scalars['UUID'];
  /** Gets the name for this selection. */
  selectionName: Scalars['String'];
};

/** Contains detail information about a 3D drawing request. */
export type RequestFor3DDrawingDetailDto = {
  __typename?: 'RequestFor3DDrawingDetailDto';
  /** Gets or sets the product package sets. */
  productPackageSets: Array<KeyValuePairOfSelectedProductSetDictionaryKeyAndProductPackageSet>;
  /** Gets the 3D drawing request information. */
  requestFor3DDrawing: RequestFor3DDrawing;
};

/** Represents a request for a 3D drawing on a selected DesignPackage (for a specific room) in a specific RealEstate with a specific list of selected ProductSets. */
export type RequestFor3DDrawingFilterInput = {
  and?: InputMaybe<Array<RequestFor3DDrawingFilterInput>>;
  /** Gets the identifier of the drawer assigned to 3d drawing request. */
  assignedDrawerId?: InputMaybe<StringOperationFilterInput>;
  /** Gets the name for this selection. */
  deadline?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the design package for this request. */
  designPackage?: InputMaybe<DesignPackageFilterInput>;
  /** Gets the id of the design package this request involves. */
  designPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestFor3DDrawingFilterInput>>;
  /** Gets the identifier for this product selection. */
  productSelectionId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the status of the request for a 3D drawing. */
  request3DDrawingStatus?: InputMaybe<NullableOfDrawingRequestStatusOperationFilterInput>;
  /** Gets the name for this selection. */
  requested?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the identifier for this room selection. */
  roomSelectionId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name for this selection. */
  selectionName?: InputMaybe<StringOperationFilterInput>;
};

/** Represents a request for a 3D drawing on a selected DesignPackage (for a specific room) in a specific RealEstate with a specific list of selected ProductSets. */
export type RequestFor3DDrawingSortInput = {
  /** Gets the identifier of the drawer assigned to 3d drawing request. */
  assignedDrawerId?: InputMaybe<SortEnumType>;
  /** Gets the name for this selection. */
  deadline?: InputMaybe<SortEnumType>;
  /** Gets the design package for this request. */
  designPackage?: InputMaybe<DesignPackageSortInput>;
  /** Gets the id of the design package this request involves. */
  designPackageId?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the identifier for this product selection. */
  productSelectionId?: InputMaybe<SortEnumType>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateSortInput>;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the status of the request for a 3D drawing. */
  request3DDrawingStatus?: InputMaybe<SortEnumType>;
  /** Gets the name for this selection. */
  requested?: InputMaybe<SortEnumType>;
  /** Gets the identifier for this room selection. */
  roomSelectionId?: InputMaybe<SortEnumType>;
  /** Gets the name for this selection. */
  selectionName?: InputMaybe<SortEnumType>;
};

/** Extended properties for RequestForContact used for filtering. */
export type RequestForContact = {
  __typename?: 'RequestForContact';
  /** Gets the type of contact of the request. */
  contactType?: Maybe<ContactType>;
  /** Gets the RequestForContact current status. */
  currentStatus?: Maybe<ContactRequestStatus>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  messages?: Maybe<Array<Maybe<MessageDto>>>;
  /** Gets the phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Gets the real estate for this request. */
  realEstate: RealEstate;
  /** Gets the id of the real estate this request for contact involves. */
  realEstateId: Scalars['UUID'];
  /** Gets the status of the request. */
  requestStatus?: Maybe<RequestForContactStatusUpdate>;
  /** Gets the date of the request. */
  requested: Scalars['DateTime'];
};

/** Extended properties for RequestForContact used for filtering. */
export type RequestForContactFilterInput = {
  and?: InputMaybe<Array<RequestForContactFilterInput>>;
  /** Gets the identifier of the specialist assigned to contact request. */
  assignedSpecialistId?: InputMaybe<StringOperationFilterInput>;
  /** Gets the type of contact of the request. */
  contactType?: InputMaybe<ContactTypeOperationFilterInput>;
  /** Gets the RequestForContact current status. */
  currentStatus?: InputMaybe<NullableOfContactRequestStatusOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestForContactFilterInput>>;
  /** Gets the phone number. */
  phone?: InputMaybe<StringOperationFilterInput>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the id of the real estate this request for contact involves. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the status of the request. */
  requestStatus?: InputMaybe<RequestForContactStatusUpdateFilterInput>;
  /** Gets the date of the request. */
  requested?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the collection of RequestForContactStatusUpdate of this request for contact. */
  statusUpdates?: InputMaybe<ListFilterInputTypeOfRequestForContactStatusUpdateFilterInput>;
};

/** Extended properties for RequestForContact used for filtering. */
export type RequestForContactSortInput = {
  /** Gets the identifier of the specialist assigned to contact request. */
  assignedSpecialistId?: InputMaybe<SortEnumType>;
  /** Gets the type of contact of the request. */
  contactType?: InputMaybe<SortEnumType>;
  /** Gets the RequestForContact current status. */
  currentStatus?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the phone number. */
  phone?: InputMaybe<SortEnumType>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateSortInput>;
  /** Gets the id of the real estate this request for contact involves. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the status of the request. */
  requestStatus?: InputMaybe<RequestForContactStatusUpdateSortInput>;
  /** Gets the date of the request. */
  requested?: InputMaybe<SortEnumType>;
};

/** Represent an update on the RequestForContact status. */
export type RequestForContactStatusUpdate = {
  __typename?: 'RequestForContactStatusUpdate';
  /** Gets the RequestForContact status. */
  status: ContactRequestStatus;
  /** Gets the date when the status was updated. */
  updateDate: Scalars['DateTime'];
};

/** Represent an update on the RequestForContact status. */
export type RequestForContactStatusUpdateFilterInput = {
  and?: InputMaybe<Array<RequestForContactStatusUpdateFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestForContactStatusUpdateFilterInput>>;
  /** Gets the RequestForContact identifier. */
  requestForContactId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the RequestForContact status. */
  status?: InputMaybe<ContactRequestStatusOperationFilterInput>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Represent an update on the RequestForContact status. */
export type RequestForContactStatusUpdateSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the RequestForContact identifier. */
  requestForContactId?: InputMaybe<SortEnumType>;
  /** Gets the RequestForContact status. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<SortEnumType>;
};

/** Extended properties for RequestForCustomOffer used for filtering. */
export type RequestForCustomOffer = {
  __typename?: 'RequestForCustomOffer';
  /** Gets the scheduled date. */
  appointmentDate?: Maybe<Scalars['DateTime']>;
  /** Gets the collection of CustomOfferRequestAppointment of this request for custom offer. */
  appointmentDates?: Maybe<Array<Maybe<CustomOfferRequestAppointment>>>;
  /** Gets the type of the appointment of the request. */
  appointmentType?: Maybe<CustomOfferAppointmentType>;
  /** Gets the offers assigned to this request. */
  assignedOffers?: Maybe<Array<Maybe<CustomOffer>>>;
  /** Gets the rooms assigned to this request. */
  assignedRooms?: Maybe<Array<Maybe<Room>>>;
  assignedSpecialist?: Maybe<AdUserDto>;
  /** Gets the identifier of the specialist assigned to custom offer request. */
  assignedSpecialistId?: Maybe<Scalars['String']>;
  /** Gets the type of contact of the request. */
  contactType?: Maybe<ContactType>;
  /** Gets the RequestForCustomOffer current status. */
  currentStatus?: Maybe<CustomOfferRequestStatus>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the location where the appointment should happen. */
  location?: Maybe<ShowroomLocation>;
  /** Gets the phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Gets the real estate for this request. */
  realEstate: RealEstate;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId: Scalars['UUID'];
  requestNotes?: Maybe<Array<Maybe<MessageDto>>>;
  /** Gets the status of the request. */
  requestStatus?: Maybe<RequestForCustomOfferStatusUpdate>;
  /** Gets the date of the request. */
  requested: Scalars['DateTime'];
  /** Gets a value indicating whether it's passed the waiting period in the shared status. */
  sharedWaiting: Scalars['Boolean'];
};

/** Extended properties for RequestForCustomOffer used for filtering. */
export type RequestForCustomOfferFilterInput = {
  and?: InputMaybe<Array<RequestForCustomOfferFilterInput>>;
  /** Gets the scheduled date. */
  appointmentDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets the collection of CustomOfferRequestAppointment of this request for custom offer. */
  appointmentDates?: InputMaybe<ListFilterInputTypeOfCustomOfferRequestAppointmentFilterInput>;
  /** Gets the type of the appointment of the request. */
  appointmentType?: InputMaybe<CustomOfferAppointmentTypeOperationFilterInput>;
  /** Gets the offers assigned to this request. */
  assignedOffers?: InputMaybe<ListFilterInputTypeOfCustomOfferFilterInput>;
  /** Gets the rooms assigned to this request. */
  assignedRooms?: InputMaybe<ListFilterInputTypeOfRoomFilterInput>;
  /** Gets the identifier of the specialist assigned to custom offer request. */
  assignedSpecialistId?: InputMaybe<StringOperationFilterInput>;
  /** Gets the type of contact of the request. */
  contactType?: InputMaybe<NullableOfContactTypeOperationFilterInput>;
  /** Gets the RequestForCustomOffer current status. */
  currentStatus?: InputMaybe<NullableOfCustomOfferRequestStatusOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the location where the appointment should happen. */
  location?: InputMaybe<NullableOfShowroomLocationOperationFilterInput>;
  or?: InputMaybe<Array<RequestForCustomOfferFilterInput>>;
  /** Gets the phone number. */
  phone?: InputMaybe<StringOperationFilterInput>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the status of the request. */
  requestStatus?: InputMaybe<RequestForCustomOfferStatusUpdateFilterInput>;
  /** Gets the date of the request. */
  requested?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets a value indicating whether it's passed the waiting period in the shared status. */
  sharedWaiting?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the collection of RequestForCustomOfferStatusUpdate of this request for custom offer. */
  statusUpdates?: InputMaybe<ListFilterInputTypeOfRequestForCustomOfferStatusUpdateFilterInput>;
};

/** Extended properties for RequestForCustomOffer used for filtering. */
export type RequestForCustomOfferSortInput = {
  /** Gets the scheduled date. */
  appointmentDate?: InputMaybe<SortEnumType>;
  /** Gets the type of the appointment of the request. */
  appointmentType?: InputMaybe<SortEnumType>;
  /** Gets the identifier of the specialist assigned to custom offer request. */
  assignedSpecialistId?: InputMaybe<SortEnumType>;
  /** Gets the type of contact of the request. */
  contactType?: InputMaybe<SortEnumType>;
  /** Gets the RequestForCustomOffer current status. */
  currentStatus?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the location where the appointment should happen. */
  location?: InputMaybe<SortEnumType>;
  /** Gets the phone number. */
  phone?: InputMaybe<SortEnumType>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateSortInput>;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the status of the request. */
  requestStatus?: InputMaybe<RequestForCustomOfferStatusUpdateSortInput>;
  /** Gets the date of the request. */
  requested?: InputMaybe<SortEnumType>;
  /** Gets a value indicating whether it's passed the waiting period in the shared status. */
  sharedWaiting?: InputMaybe<SortEnumType>;
};

/** Represent an update on the RequestForCustomOffer status. */
export type RequestForCustomOfferStatusUpdate = {
  __typename?: 'RequestForCustomOfferStatusUpdate';
  /** Gets the RequestForCustomOffer status. */
  status: CustomOfferRequestStatus;
  /** Gets the date when the status was updated. */
  updateDate: Scalars['DateTime'];
};

/** Represent an update on the RequestForCustomOffer status. */
export type RequestForCustomOfferStatusUpdateFilterInput = {
  and?: InputMaybe<Array<RequestForCustomOfferStatusUpdateFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestForCustomOfferStatusUpdateFilterInput>>;
  /** Gets the RequestForCustomOffer identifier. */
  requestForCustomOfferId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the RequestForCustomOffer status. */
  status?: InputMaybe<CustomOfferRequestStatusOperationFilterInput>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Represent an update on the RequestForCustomOffer status. */
export type RequestForCustomOfferStatusUpdateSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the RequestForCustomOffer identifier. */
  requestForCustomOfferId?: InputMaybe<SortEnumType>;
  /** Gets the RequestForCustomOffer status. */
  status?: InputMaybe<SortEnumType>;
  /** Gets the date when the status was updated. */
  updateDate?: InputMaybe<SortEnumType>;
};

/** Represents a request for a quotation on a selected DesignPackage (for a specific room) in a specific RealEstate. */
export type RequestForQuote = {
  __typename?: 'RequestForQuote';
  /** Gets a value indicating when this request for quote was created. */
  created: Scalars['DateTime'];
  /** Gets a value indicating whether the design package selected has a bath for this request. */
  designPackageHasBath?: Maybe<Scalars['Boolean']>;
  /** Gets a value indicating whether the design package selected is a fixed price for this request. */
  designPackageIsFixedPrice?: Maybe<Scalars['Boolean']>;
  /** Gets a value indicating whether the design package selected is enlarged for this request. */
  designPackageIsXL?: Maybe<Scalars['Boolean']>;
  /** Gets the design package name selected for this request. */
  designPackageName?: Maybe<Scalars['String']>;
  /** Gets the price for the design package selected for this request. */
  designPackagePrice?: Maybe<Money>;
  /** Gets the style name for the style of the design package selected for this request. */
  designPackageStyleName?: Maybe<Scalars['String']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the real estate for this request. */
  realEstate: RealEstate;
  /** Gets the room name for this request. */
  roomName?: Maybe<Scalars['String']>;
  /** Gets the quoterequested selected option like standard, shellOnly or custom */
  selectionType: SelectionType;
};

/** Contains information about a single selection for a room with additional price. */
export type RequestForQuoteAddToRoomSelectionDtoInput = {
  /** Gets or sets the custom offer identifier. */
  customOfferId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the design package identifier. */
  designPackageId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets a value indicating whether this selection is chosen. */
  isSelected?: InputMaybe<Scalars['Boolean']>;
  /** Gets or sets the name of the selection. */
  name?: InputMaybe<Scalars['String']>;
  /** Gets or sets the room identifier. */
  roomId: Scalars['UUID'];
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
  /** Gets or sets the additional value to be paid for the selection. */
  surcharge?: InputMaybe<MoneyInput>;
};

/** Represents a request for a quotation on a selected DesignPackage (for a specific room) in a specific RealEstate. */
export type RequestForQuoteFilterInput = {
  and?: InputMaybe<Array<RequestForQuoteFilterInput>>;
  /** Gets a value indicating when this request for quote was created. */
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets a value indicating whether the design package selected has a bath for this request. */
  designPackageHasBath?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the design package selected is a fixed price for this request. */
  designPackageIsFixedPrice?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets a value indicating whether the design package selected is enlarged for this request. */
  designPackageIsXL?: InputMaybe<BooleanOperationFilterInput>;
  /** Gets the design package name selected for this request. */
  designPackageName?: InputMaybe<StringOperationFilterInput>;
  /** Gets the price for the design package selected for this request. */
  designPackagePrice?: InputMaybe<MoneyFilterInput>;
  /** Gets the style name for the style of the design package selected for this request. */
  designPackageStyleName?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestForQuoteFilterInput>>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the id of the real estate this request for quote involves. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the room name for this request. */
  roomName?: InputMaybe<StringOperationFilterInput>;
  /** Gets the quoterequested selected option like standard, shellOnly or custom */
  selectionType?: InputMaybe<SelectionTypeOperationFilterInput>;
};

/** Contains information about a single global selection for a real estate. */
export type RequestForQuoteGlobalSelectionItemDtoInput = {
  /** Gets or sets the custom offer identifier. */
  customOfferId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the design package identifier. */
  designPackageId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
};

/** Contains information about a single selection for a room including selected ProductSets. */
export type RequestForQuoteRoomSelectionItemDto = {
  __typename?: 'RequestForQuoteRoomSelectionItemDto';
  /** Gets or sets the design package identifier. */
  designPackageId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the room identifier. */
  roomId?: Maybe<Scalars['UUID']>;
  /** Gets or sets the list of selected ProductSet Ids. */
  selectedProductSets?: Maybe<SelectedProductSets>;
};

/** Contains information about a single selection for a room including selected ProductSets. */
export type RequestForQuoteRoomSelectionItemDtoInput = {
  /** Gets or sets the custom offer identifier. */
  customOfferId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the design package identifier. */
  designPackageId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the room identifier. */
  roomId: Scalars['UUID'];
  /** Gets or sets the list of selected ProductSet Ids. */
  selectedProductSets?: InputMaybe<SelectedProductSetsInput>;
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
};

/** Contains information about a single selection for a room. */
export type RequestForQuoteUpdateFromRoomSelectionDtoInput = {
  /** Gets or sets the custom offer identifier. */
  customOfferId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the design package identifier. */
  designPackageId?: InputMaybe<Scalars['UUID']>;
  /** Gets or sets the identifier for the selection to remove. */
  id: Scalars['UUID'];
  /** Gets or sets the room identifier. */
  roomId: Scalars['UUID'];
  /** Gets or sets the type of the selection. */
  selectionType: SelectionType;
};

/** Represents a request for a technical drawing on an order from a specific RealEstate. */
export type RequestForTechnicalDrawing = {
  __typename?: 'RequestForTechnicalDrawing';
  assignedDrawer?: Maybe<AdUserDto>;
  /** Gets the identifier of the drawer assigned to technical drawing request. */
  assignedDrawerId?: Maybe<Scalars['String']>;
  /** Gets the date and time limit to finish the technical drawing. */
  deadline?: Maybe<Scalars['DateTime']>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the real estate for this request. */
  realEstate: RealEstate;
  /** Gets the status of the request for a technical drawing request. */
  requestTechnicalDrawingStatus?: Maybe<DrawingRequestStatus>;
  /** Gets the date and time the request was issued. */
  requested: Scalars['DateTime'];
};

/** Represents a request for a technical drawing on an order from a specific RealEstate. */
export type RequestForTechnicalDrawingFilterInput = {
  and?: InputMaybe<Array<RequestForTechnicalDrawingFilterInput>>;
  /** Gets the identifier of the drawer assigned to technical drawing request. */
  assignedDrawerId?: InputMaybe<StringOperationFilterInput>;
  /** Gets the date and time limit to finish the technical drawing. */
  deadline?: InputMaybe<DateTimeOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RequestForTechnicalDrawingFilterInput>>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateFilterInput>;
  /** Gets the identifier of the real estate for this request. */
  realEstateId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the status of the request for a technical drawing request. */
  requestTechnicalDrawingStatus?: InputMaybe<DrawingRequestStatusOperationFilterInput>;
  /** Gets the date and time the request was issued. */
  requested?: InputMaybe<DateTimeOperationFilterInput>;
};

/** Represents a request for a technical drawing on an order from a specific RealEstate. */
export type RequestForTechnicalDrawingSortInput = {
  /** Gets the identifier of the drawer assigned to technical drawing request. */
  assignedDrawerId?: InputMaybe<SortEnumType>;
  /** Gets the date and time limit to finish the technical drawing. */
  deadline?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the real estate for this request. */
  realEstate?: InputMaybe<RealEstateSortInput>;
  /** Gets the identifier of the real estate for this request. */
  realEstateId?: InputMaybe<SortEnumType>;
  /** Gets the status of the request for a technical drawing request. */
  requestTechnicalDrawingStatus?: InputMaybe<SortEnumType>;
  /** Gets the date and time the request was issued. */
  requested?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RequestsFor3DDrawingsCollectionSegment = {
  __typename?: 'RequestsFor3DDrawingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RequestFor3DDrawing>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type RequestsForTechnicalDrawingsCollectionSegment = {
  __typename?: 'RequestsForTechnicalDrawingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RequestForTechnicalDrawing>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Room present in one or more RealEstate in a Project. */
export type Room = {
  __typename?: 'Room';
  designPackages: Array<DesignPackage>;
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  layoutRooms: Array<LayoutRoom>;
  /** Gets the name of this Room. */
  name: Scalars['String'];
  /** Gets the integer by which the Rooms should be ordered when displaying. */
  position: Scalars['Int'];
};


/** Room present in one or more RealEstate in a Project. */
export type RoomDesignPackagesArgs = {
  order?: InputMaybe<Array<DesignPackageSortInput>>;
  where?: InputMaybe<DesignPackageFilterInput>;
};


/** Room present in one or more RealEstate in a Project. */
export type RoomLayoutRoomsArgs = {
  where?: InputMaybe<LayoutRoomFilterInput>;
};

/** Room present in one or more RealEstate in a Project. */
export type RoomFilterInput = {
  and?: InputMaybe<Array<RoomFilterInput>>;
  /** Gets the custom offer requests for the room. */
  customOfferRequests?: InputMaybe<ListFilterInputTypeOfRequestForCustomOfferFilterInput>;
  /** Gets the collection of DesignPackage for this Room. */
  designPackages?: InputMaybe<ListFilterInputTypeOfDesignPackageFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the collection of Layout to Room relations. */
  layoutRooms?: InputMaybe<ListFilterInputTypeOfLayoutRoomFilterInput>;
  /** Gets the name of this Room. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoomFilterInput>>;
  /** Gets the integer by which the Rooms should be ordered when displaying. */
  position?: InputMaybe<IntOperationFilterInput>;
  /** Gets the Project this Room belongs to. */
  project?: InputMaybe<ProjectFilterInput>;
  /** Gets the id of the Project this Room belongs to. */
  projectId?: InputMaybe<UuidOperationFilterInput>;
};

/** Represents a Room Page. */
export type RoomPage = ContentItem & {
  __typename?: 'RoomPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** HtmlField */
  descriptionBrandProposition?: Maybe<HtmlField>;
  /** HtmlField */
  descriptionProductProposition?: Maybe<HtmlField>;
  /** HtmlField */
  descriptionStyleProposition?: Maybe<HtmlField>;
  /** Text field */
  designPackagesGroupTitle?: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Text field */
  layoutsPageTitle?: Maybe<Scalars['String']>;
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  standardLabel?: Maybe<Scalars['String']>;
  /** Text field */
  viewDesignPackageButton?: Maybe<Scalars['String']>;
};

export type RoomPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the RoomPage content item filters */
export type RoomPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<RoomPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<RoomPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<RoomPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Room present in one or more RealEstate in a Project. */
export type RoomSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of this Room. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the integer by which the Rooms should be ordered when displaying. */
  position?: InputMaybe<SortEnumType>;
  /** Gets the Project this Room belongs to. */
  project?: InputMaybe<ProjectSortInput>;
  /** Gets the id of the Project this Room belongs to. */
  projectId?: InputMaybe<SortEnumType>;
};

/** Represents an Dictionary index on a association between ProductCategory and ProductSet for a specific DesignPackageWall if any. */
export type SelectedProductSetDictionaryKey = {
  __typename?: 'SelectedProductSetDictionaryKey';
  /** The ProductCategory identifier. */
  categoryId: Scalars['UUID'];
  /** The DesignPackageWall identifier. */
  wallId?: Maybe<Scalars['UUID']>;
};

export type SelectedProductSetDtoInput = {
  forCategoryId: Scalars['UUID'];
  productSetId: Scalars['UUID'];
  wallId?: InputMaybe<Scalars['UUID']>;
};

/** Contains information about the selected product sets. */
export type SelectedProductSets = {
  __typename?: 'SelectedProductSets';
  /** Gets or sets the list of selected ProductSet Ids. */
  productCombinationSets?: Maybe<Array<ProductCombinationSets>>;
};

/** Contains information about the selected product sets. */
export type SelectedProductSetsInput = {
  /** Gets or sets the list of selected ProductSet Ids. */
  productCombinationSets?: InputMaybe<Array<ProductCombinationSetsInput>>;
};

/** The request for quote selection options */
export enum SelectionType {
  /** Custom room option selection */
  Custom = 'CUSTOM',
  /** None room option selection */
  DesignPackage = 'DESIGN_PACKAGE',
  /** Custom global option selection */
  GlobalCustom = 'GLOBAL_CUSTOM',
  /** ShellOnly room option selection */
  ShellOnly = 'SHELL_ONLY',
  /** Standard room option selection */
  Standard = 'STANDARD'
}

export type SelectionTypeOperationFilterInput = {
  eq?: InputMaybe<SelectionType>;
  in?: InputMaybe<Array<SelectionType>>;
  neq?: InputMaybe<SelectionType>;
  nin?: InputMaybe<Array<SelectionType>>;
};

/** Showroom locations. */
export enum ShowroomLocation {
  /** Bunnik. */
  Bunnik = 'BUNNIK',
  /** Drachten. */
  Drachten = 'DRACHTEN'
}

export type SiteCulture = {
  __typename?: 'SiteCulture';
  /** The culture code. */
  culture?: Maybe<Scalars['String']>;
  /** Whether this is the default culture. */
  default?: Maybe<Scalars['Boolean']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** publication status */
export enum Status {
  /** all historical versions */
  All = 'ALL',
  /** draft content item version */
  Draft = 'DRAFT',
  /** the latest version, either published or draft */
  Latest = 'LATEST',
  /** published content item version */
  Published = 'PUBLISHED'
}

export type Step1 = {
  __typename?: 'Step1';
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** MediaField */
  image?: Maybe<MediaField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type Step2 = {
  __typename?: 'Step2';
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** MediaField */
  image?: Maybe<MediaField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type Step3 = {
  __typename?: 'Step3';
  /** Text field */
  description?: Maybe<Scalars['String']>;
  /** MediaField */
  image?: Maybe<MediaField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

/** Style represents a single, unified set of design elements for decorating rooms. */
export type Style = {
  __typename?: 'Style';
  /** Gets or sets the primary key for this entity. */
  id: Scalars['UUID'];
  /** Gets the name of the style. */
  name: Scalars['String'];
  /** Gets the product package of the style. */
  productPackage?: Maybe<ProductPackage>;
  /** Gets the identifier of the product package of the style. */
  productPackageId?: Maybe<Scalars['UUID']>;
  usedInDesignPackages: Array<DesignPackage>;
  usedInProjects: Array<ProjectStyle>;
};


/** Style represents a single, unified set of design elements for decorating rooms. */
export type StyleUsedInDesignPackagesArgs = {
  where?: InputMaybe<DesignPackageFilterInput>;
};


/** Style represents a single, unified set of design elements for decorating rooms. */
export type StyleUsedInProjectsArgs = {
  where?: InputMaybe<ProjectStyleFilterInput>;
};

/** Represents a Style detail. */
export type StyleDetail = ContentItem & {
  __typename?: 'StyleDetail';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** MediaField */
  teaserImage?: Maybe<MediaField>;
  /** Text field */
  teaserText?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type StyleDetailOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the StyleDetail content item filters */
export type StyleDetailWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<StyleDetailWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<StyleDetailWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<StyleDetailWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Style represents a single, unified set of design elements for decorating rooms. */
export type StyleFilterInput = {
  and?: InputMaybe<Array<StyleFilterInput>>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the name of the style. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StyleFilterInput>>;
  /** Gets the product package of the style. */
  productPackage?: InputMaybe<ProductPackageFilterInput>;
  /** Gets the identifier of the product package of the style. */
  productPackageId?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the collection of DesignPackage objects that reference this style. */
  usedInDesignPackages?: InputMaybe<ListFilterInputTypeOfDesignPackageFilterInput>;
  /** Gets the collection of ProjectStyle objects that reference this style. */
  usedInProjects?: InputMaybe<ListFilterInputTypeOfProjectStyleFilterInput>;
};

/** Represents a Style Listing Page. */
export type StyleListingPage = ContentItem & {
  __typename?: 'StyleListingPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Text field */
  goToStyle?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** HtmlField */
  pageDescription?: Maybe<HtmlField>;
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type StyleListingPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the StyleListingPage content item filters */
export type StyleListingPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<StyleListingPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<StyleListingPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<StyleListingPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type StyleOverviewBlock1 = {
  __typename?: 'StyleOverviewBlock1';
  /** HtmlField */
  text?: Maybe<HtmlField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type StyleOverviewBlock2 = {
  __typename?: 'StyleOverviewBlock2';
  /** HtmlField */
  text?: Maybe<HtmlField>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

/** Represents a Style page. */
export type StylePage = ContentItem & {
  __typename?: 'StylePage';
  /** Alternative path for the content item */
  alias?: Maybe<AliasPart>;
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Text field */
  pageTitle?: Maybe<Scalars['String']>;
  /** Numeric field */
  position?: Maybe<Scalars['Decimal']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** ContentPickerField */
  styleDetails?: Maybe<ContentPickerField>;
  /** MediaField */
  styleOverViewBanner?: Maybe<MediaField>;
  /** MediaField */
  styleOverViewImages?: Maybe<MediaField>;
  /** Represents a StyleOverviewBlock. */
  styleOverviewBlock1?: Maybe<StyleOverviewBlock1>;
  /** Represents a StyleOverviewBlock. */
  styleOverviewBlock2?: Maybe<StyleOverviewBlock2>;
  /** Represents a StyleOverView. */
  styleoverview?: Maybe<Styleoverview>;
  /** MediaField */
  teaserImage?: Maybe<MediaField>;
  /** Text field */
  teaserSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  teaserTitle?: Maybe<Scalars['String']>;
};

export type StylePageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the StylePage content item filters */
export type StylePageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<StylePageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<StylePageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<StylePageWhereInput>>>;
  /** the alias part of the content item */
  alias?: InputMaybe<AliasPartInput>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Style represents a single, unified set of design elements for decorating rooms. */
export type StyleSortInput = {
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the name of the style. */
  name?: InputMaybe<SortEnumType>;
  /** Gets the product package of the style. */
  productPackage?: InputMaybe<ProductPackageSortInput>;
  /** Gets the identifier of the product package of the style. */
  productPackageId?: InputMaybe<SortEnumType>;
};

export type Styleoverview = {
  __typename?: 'Styleoverview';
  /** HtmlField */
  introduction?: Maybe<HtmlField>;
};

/** Represents a Success Page. */
export type SuccessPage = ContentItem & {
  __typename?: 'SuccessPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  body?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
};

export type SuccessPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the SuccessPage content item filters */
export type SuccessPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<SuccessPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<SuccessPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<SuccessPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Terms And Conditions Page. */
export type TermsAndConditionsPage = ContentItem & {
  __typename?: 'TermsAndConditionsPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  content?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type TermsAndConditionsPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the TermsAndConditionsPage content item filters */
export type TermsAndConditionsPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<TermsAndConditionsPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<TermsAndConditionsPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<TermsAndConditionsPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Terms & Conditions. */
export type TermsConditions = ContentItem & {
  __typename?: 'TermsConditions';
  /** The author of the content item */
  author: Scalars['String'];
  /** Text field */
  checkboxLabelText?: Maybe<Scalars['String']>;
  /** Text field */
  checkboxLinkText?: Maybe<Scalars['String']>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** MediaField */
  termsConditionsFile?: Maybe<MediaField>;
  /** Text field */
  warningText?: Maybe<Scalars['String']>;
};

export type TermsConditionsOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the TermsConditions content item filters */
export type TermsConditionsWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<TermsConditionsWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<TermsConditionsWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<TermsConditionsWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** Represents a Translation. */
export type Translation = ContentItem & {
  __typename?: 'Translation';
  /** The author of the content item */
  author: Scalars['String'];
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Represents a TranslationAuthentication. */
  translationAuthentication?: Maybe<TranslationAuthentication>;
  /** Represents a TranslationBrand. */
  translationBrand?: Maybe<TranslationBrand>;
  /** Represents a TranslationDesignPackage. */
  translationDesignPackage?: Maybe<TranslationDesignPackage>;
  /** Represents a TranslationError. */
  translationError?: Maybe<TranslationError>;
  /** Represents a TranslationErrorPages. */
  translationErrorPages?: Maybe<TranslationErrorPages>;
  /** Represents a TranslationGlobal. */
  translationGlobal?: Maybe<TranslationGlobal>;
  /** Represents a TranslationProductProposition. */
  translationProductProposition?: Maybe<TranslationProductProposition>;
  /** Represents a TranslationProject. */
  translationProject?: Maybe<TranslationProject>;
  /** Represents a TranslationStyle. */
  translationStyle?: Maybe<TranslationStyle>;
};

export type TranslationAuthentication = {
  __typename?: 'TranslationAuthentication';
  /** Text field */
  authenticationLoginButton?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginForgotPasswordText?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginPasswordPlaceholder?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginRealEstateListItemPrefix?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginRealEstatesText?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginTitle?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationLoginUsernamePlaceholder?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordConfirmMessage?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordEmailPlaceholder?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordInvalidEmail?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordSendLinkButton?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordText?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationResetPasswordTitle?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordConfirmPlaceholder?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordPasswordNotMatchError?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordPasswordRulesError?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordSave?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordText?: Maybe<Scalars['String']>;
  /** Text field */
  authenticationSetPasswordTitle?: Maybe<Scalars['String']>;
};

export type TranslationBrand = {
  __typename?: 'TranslationBrand';
  /** Text field */
  backToBrands?: Maybe<Scalars['String']>;
  /** Text field */
  readMore?: Maybe<Scalars['String']>;
};

export type TranslationDesignPackage = {
  __typename?: 'TranslationDesignPackage';
  /** Text field */
  designPackageDesignFor?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageEnlarged?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageFor?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageFrom?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageLayout?: Maybe<Scalars['String']>;
  /** Text field */
  designPackagePhotos?: Maybe<Scalars['String']>;
  /** Text field */
  designPackagePopupChoosethisdesign?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageProductList?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageSelectThisDesign?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageStandardDesign?: Maybe<Scalars['String']>;
  /** Text field */
  enlarged?: Maybe<Scalars['String']>;
  /** Text field */
  for?: Maybe<Scalars['String']>;
  /** Text field */
  from?: Maybe<Scalars['String']>;
  /** Text field */
  selectThisStandardDesign?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
  /** Text field */
  viewBrands?: Maybe<Scalars['String']>;
  /** Text field */
  viewProductsConfig?: Maybe<Scalars['String']>;
  /** Text field */
  viewStyles?: Maybe<Scalars['String']>;
};

export type TranslationError = {
  __typename?: 'TranslationError';
  /** Text field */
  errorGoToHomePage?: Maybe<Scalars['String']>;
  /** Text field */
  errorInternalServerError?: Maybe<Scalars['String']>;
  /** Text field */
  errorPageNotFound?: Maybe<Scalars['String']>;
  /** Text field */
  errorProjectClosedText?: Maybe<Scalars['String']>;
  /** Text field */
  errorProjectClosedTitle?: Maybe<Scalars['String']>;
};

export type TranslationErrorPages = {
  __typename?: 'TranslationErrorPages';
  /** Text field */
  errorPagesGotoHomePage?: Maybe<Scalars['String']>;
  /** Text field */
  errorPagesInternalServerError?: Maybe<Scalars['String']>;
  /** Text field */
  errorPagesPageNotFound?: Maybe<Scalars['String']>;
};

export type TranslationGlobal = {
  __typename?: 'TranslationGlobal';
  /** Text field */
  back?: Maybe<Scalars['String']>;
  /** Text field */
  basic?: Maybe<Scalars['String']>;
  /** Text field */
  calculating?: Maybe<Scalars['String']>;
  /** Text field */
  globalAboutUs?: Maybe<Scalars['String']>;
  /** Text field */
  globalCancel?: Maybe<Scalars['String']>;
  /** Text field */
  globalClosingDate?: Maybe<Scalars['String']>;
  /** Text field */
  globalClosingDateTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalColors?: Maybe<Scalars['String']>;
  /** Text field */
  globalConfirm?: Maybe<Scalars['String']>;
  /** Text field */
  globalContact?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactPreferenceEmail?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactPreferenceNoPreference?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactPreferencePhone?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactPreferenceTitle?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactRequestButton?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactRequestSend?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactRequestSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  globalContactRequestTitle?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesCoordinationCommission?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesCoordinationCommissionTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesCustomOffer?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesCustomOfferTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesDrawings?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesDrawingsTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesInstallationConstruction?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesInstallationConstructionTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesLowerPriceStandardProducts?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesLowerPriceStandardProductsTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesProductsAndInstallationMaterials?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesProductsAndInstallationMaterialsTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesTilesAndFasteners?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesTilesAndFastenersTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesTotalSurcharge?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesVAT?: Maybe<Scalars['String']>;
  /** Text field */
  globalCostTypesVATTooltip?: Maybe<Scalars['String']>;
  /** Text field */
  globalDayPartAfternoon?: Maybe<Scalars['String']>;
  /** Text field */
  globalDayPartEvening?: Maybe<Scalars['String']>;
  /** Text field */
  globalDayPartMorning?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysFri?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysMon?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysSat?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysSun?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysThu?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysTue?: Maybe<Scalars['String']>;
  /** Text field */
  globalDaysWed?: Maybe<Scalars['String']>;
  /** Text field */
  globalDesigns?: Maybe<Scalars['String']>;
  /** Text field */
  globalDetails?: Maybe<Scalars['String']>;
  /** Text field */
  globalDownloadPDF?: Maybe<Scalars['String']>;
  /** Text field */
  globalFAQ?: Maybe<Scalars['String']>;
  /** Text field */
  globalFiltersAppliedFiltersText?: Maybe<Scalars['String']>;
  /** Text field */
  globalFiltersApplyFiltersButton?: Maybe<Scalars['String']>;
  /** Text field */
  globalFiltersOpenFiltersButton?: Maybe<Scalars['String']>;
  /** Text field */
  globalFiltersResetFiltersButton?: Maybe<Scalars['String']>;
  /** Text field */
  globalForContractors?: Maybe<Scalars['String']>;
  /** Text field */
  globalIncludingVAT?: Maybe<Scalars['String']>;
  /** Text field */
  globalLogout?: Maybe<Scalars['String']>;
  /** Text field */
  globalMoreInfo?: Maybe<Scalars['String']>;
  /** Text field */
  globalMySelection?: Maybe<Scalars['String']>;
  /** Text field */
  globalOverview?: Maybe<Scalars['String']>;
  /** Text field */
  globalPhotoZoomLabel?: Maybe<Scalars['String']>;
  /** Text field */
  globalPrice?: Maybe<Scalars['String']>;
  /** Text field */
  globalRequestSentPopupDescription?: Maybe<Scalars['String']>;
  /** Text field */
  globalRequestSentPopupTitle?: Maybe<Scalars['String']>;
  /** Text field */
  globalRequiredFieldsLabel?: Maybe<Scalars['String']>;
  /** Text field */
  globalStandard?: Maybe<Scalars['String']>;
  /** Text field */
  globalTermsAndConditions?: Maybe<Scalars['String']>;
  /** Text field */
  globalView?: Maybe<Scalars['String']>;
  /** Text field */
  globalViewDesign?: Maybe<Scalars['String']>;
  /** Text field */
  globalViewDesigns?: Maybe<Scalars['String']>;
  /** Text field */
  globalWarning?: Maybe<Scalars['String']>;
  /** Text field */
  globalWithBath?: Maybe<Scalars['String']>;
  /** Text field */
  globalWithToilet?: Maybe<Scalars['String']>;
  /** Text field */
  globalXL?: Maybe<Scalars['String']>;
};

export type TranslationLayout = {
  __typename?: 'TranslationLayout';
  /** Text field */
  layoutWithBath?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithBathXLVariant?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithToilet?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithToiletBath?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithToiletBathXLVariant?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithToiletXLVariant?: Maybe<Scalars['String']>;
  /** Text field */
  layoutWithXLVariant?: Maybe<Scalars['String']>;
};

export type TranslationOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

export type TranslationProductProposition = {
  __typename?: 'TranslationProductProposition';
  /** Text field */
  bannerButtonTitle?: Maybe<Scalars['String']>;
  /** Text field */
  brandLineSelectedButton?: Maybe<Scalars['String']>;
  /** Text field */
  brandLineUnselectedButton?: Maybe<Scalars['String']>;
  /** Text field */
  changeSelection?: Maybe<Scalars['String']>;
  /** Text field */
  changeSelectionWarningCancelButton?: Maybe<Scalars['String']>;
  /** Text field */
  changeSelectionWarningConfirmButton?: Maybe<Scalars['String']>;
  /** Text field */
  changeSelectionWarningText?: Maybe<Scalars['String']>;
  /** Text field */
  changeSelectionWarningTitle?: Maybe<Scalars['String']>;
  /** Text field */
  combination?: Maybe<Scalars['String']>;
  /** Text field */
  combinationProductsAndInstallationMaterialsCosts?: Maybe<Scalars['String']>;
  /** Text field */
  coordinationCommissionCosts?: Maybe<Scalars['String']>;
  /** Text field */
  defaultProductSetBadge?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageBackToStart?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigOverviewButton?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigOverviewPageCostOverviewTitle?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigOverviewPageSelectedProductSetsOverviewTitle?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigPrice?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigPriceDescription?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageConfigureButton?: Maybe<Scalars['String']>;
  /** Text field */
  designPackageContentCardTitle?: Maybe<Scalars['String']>;
  /** Text field */
  favouriteProductSetBadge?: Maybe<Scalars['String']>;
  /** Text field */
  installationConstructionCosts?: Maybe<Scalars['String']>;
  /** HtmlField */
  introduction?: Maybe<HtmlField>;
  /** Text field */
  lowerPriceStandardProductsCosts?: Maybe<Scalars['String']>;
  /** Text field */
  nextButtonCombination?: Maybe<Scalars['String']>;
  /** Text field */
  nextButtonOverview?: Maybe<Scalars['String']>;
  /** Text field */
  orderDepthHighToLow?: Maybe<Scalars['String']>;
  /** Text field */
  orderDepthLowToHigh?: Maybe<Scalars['String']>;
  /** Text field */
  orderHeightHighToLow?: Maybe<Scalars['String']>;
  /** Text field */
  orderHeightLowToHigh?: Maybe<Scalars['String']>;
  /** Text field */
  orderLengthHighToLow?: Maybe<Scalars['String']>;
  /** Text field */
  orderLengthLowToHigh?: Maybe<Scalars['String']>;
  /** Text field */
  orderPriceHighToLow?: Maybe<Scalars['String']>;
  /** Text field */
  orderPriceLowToHigh?: Maybe<Scalars['String']>;
  /** Text field */
  orderRelevance?: Maybe<Scalars['String']>;
  /** Text field */
  orderWidthHighToLow?: Maybe<Scalars['String']>;
  /** Text field */
  orderWidthLowToHigh?: Maybe<Scalars['String']>;
  /** Text field */
  overviewAddToSelection?: Maybe<Scalars['String']>;
  /** Text field */
  overviewAllConfigurationsLink?: Maybe<Scalars['String']>;
  /** Text field */
  overviewCostsTitle?: Maybe<Scalars['String']>;
  /** Text field */
  overviewRequest3DDrawing?: Maybe<Scalars['String']>;
  /** Text field */
  overviewStandardSelection?: Maybe<Scalars['String']>;
  /** Text field */
  overviewUnsavedSelection?: Maybe<Scalars['String']>;
  /** Text field */
  productPropositionOverviewChooseYour?: Maybe<Scalars['String']>;
  /** Text field */
  productPropositionOverviewIncompleteCombination?: Maybe<Scalars['String']>;
  /** Text field */
  productPropositionOverviewNoSelectionYet?: Maybe<Scalars['String']>;
  /** Text field */
  productPropositionOverviewUnavailableProductSet?: Maybe<Scalars['String']>;
  /** Text field */
  productSetNextButton?: Maybe<Scalars['String']>;
  /** Text field */
  productSetPreviousButton?: Maybe<Scalars['String']>;
  /** Text field */
  productSetSelectButton?: Maybe<Scalars['String']>;
  /** Text field */
  productSetSelectedButton?: Maybe<Scalars['String']>;
  /** Text field */
  productSetSelectedButtonHover?: Maybe<Scalars['String']>;
  /** Text field */
  productSetUnselectButton?: Maybe<Scalars['String']>;
  /** Text field */
  productSetUnselectedButton?: Maybe<Scalars['String']>;
  /** Text field */
  productsAndInstallationMaterialsCosts?: Maybe<Scalars['String']>;
  /** Text field */
  roomCardButtonTitle?: Maybe<Scalars['String']>;
  /** Text field */
  roomsGroupTitle?: Maybe<Scalars['String']>;
  /** Text field */
  salutationSubtitle?: Maybe<Scalars['String']>;
  /** Text field */
  salutationTitle?: Maybe<Scalars['String']>;
  /** Text field */
  tilesAndFastenersCosts?: Maybe<Scalars['String']>;
  /** Text field */
  totalSurchargeCosts?: Maybe<Scalars['String']>;
};

export type TranslationProject = {
  __typename?: 'TranslationProject';
  /** Text field */
  projectBrandsNavigateCarouselLeft?: Maybe<Scalars['String']>;
  /** Text field */
  projectBrandsNavigateCarouselRight?: Maybe<Scalars['String']>;
  /** Text field */
  projectBrandsView?: Maybe<Scalars['String']>;
  /** Text field */
  projectCustomDesign?: Maybe<Scalars['String']>;
  /** Text field */
  projectNavigateCarouselLeft?: Maybe<Scalars['String']>;
  /** Text field */
  projectNavigateCarouselRight?: Maybe<Scalars['String']>;
  /** Text field */
  projectSalutationTitle?: Maybe<Scalars['String']>;
  /** Text field */
  projectStylesNavigateCarouselLeft?: Maybe<Scalars['String']>;
  /** Text field */
  projectStylesNavigateCarouselRight?: Maybe<Scalars['String']>;
  /** Text field */
  projectStylesView?: Maybe<Scalars['String']>;
  /** Text field */
  projectViewDesigns?: Maybe<Scalars['String']>;
  /** Text field */
  projectViewStyleWorld?: Maybe<Scalars['String']>;
  /** Text field */
  projectViewStyles?: Maybe<Scalars['String']>;
};

export type TranslationStyle = {
  __typename?: 'TranslationStyle';
  /** Text field */
  backToStyles?: Maybe<Scalars['String']>;
};

/** the Translation content item filters */
export type TranslationWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<TranslationWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<TranslationWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<TranslationWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type TupleOfCostTypeAndStringAndBooleanInput = {
  item1: CostType;
  item2: Scalars['String'];
  item3: Scalars['Boolean'];
};

export type TupleOfCostTypeAndStringInput = {
  item1: CostType;
  item2: Scalars['String'];
};

export type TupleOfGuidAndNullableOfBooleanInput = {
  item1: Scalars['UUID'];
  item2?: InputMaybe<Scalars['Boolean']>;
};

export type TupleOfGuidAndPriceVariantInput = {
  item1: Scalars['UUID'];
  item2: PriceVariant;
};

export type TupleOfGuidAndStringInput = {
  item1: Scalars['UUID'];
  item2: Scalars['String'];
};

export type TupleOfStringAndBooleanInput = {
  item1: Scalars['String'];
  item2: Scalars['Boolean'];
};

export type TupleOfStringAndStringAndBooleanInput = {
  item1: Scalars['String'];
  item2: Scalars['String'];
  item3: Scalars['Boolean'];
};

export type TupleOfStringAndUploadFileTypeInput = {
  item1: Scalars['String'];
  item2: UploadFileType;
};

/** Represents a Under Construction Page. */
export type UnderConstructionPage = ContentItem & {
  __typename?: 'UnderConstructionPage';
  /** The author of the content item */
  author: Scalars['String'];
  /** HtmlField */
  contentBlock?: Maybe<HtmlField>;
  /** Content item id */
  contentItemId: Scalars['String'];
  /** The content item version id */
  contentItemVersionId: Scalars['String'];
  /** Type of content */
  contentType: Scalars['String'];
  /** The date and time of creation */
  createdUtc?: Maybe<Scalars['DateTime']>;
  /** The display text of the content item */
  displayText?: Maybe<Scalars['String']>;
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** Localization cultures for your content item. */
  localization?: Maybe<LocalizationPart>;
  /** The date and time of modification */
  modifiedUtc?: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Scalars['String'];
  /** Is the published version */
  published: Scalars['Boolean'];
  /** The date and time of publication */
  publishedUtc?: Maybe<Scalars['DateTime']>;
  render?: Maybe<Scalars['String']>;
  /** Text field */
  subtitle?: Maybe<Scalars['String']>;
  /** Text field */
  title?: Maybe<Scalars['String']>;
};

export type UnderConstructionPageOrderByInput = {
  author?: InputMaybe<OrderByDirection>;
  contentItemId?: InputMaybe<OrderByDirection>;
  contentItemVersionId?: InputMaybe<OrderByDirection>;
  contentType?: InputMaybe<OrderByDirection>;
  createdUtc?: InputMaybe<OrderByDirection>;
  displayText?: InputMaybe<OrderByDirection>;
  latest?: InputMaybe<OrderByDirection>;
  modifiedUtc?: InputMaybe<OrderByDirection>;
  owner?: InputMaybe<OrderByDirection>;
  published?: InputMaybe<OrderByDirection>;
  publishedUtc?: InputMaybe<OrderByDirection>;
};

/** the UnderConstructionPage content item filters */
export type UnderConstructionPageWhereInput = {
  /** AND logical operation */
  AND?: InputMaybe<Array<InputMaybe<UnderConstructionPageWhereInput>>>;
  /** NOT logical operation */
  NOT?: InputMaybe<Array<InputMaybe<UnderConstructionPageWhereInput>>>;
  /** OR logical operation */
  OR?: InputMaybe<Array<InputMaybe<UnderConstructionPageWhereInput>>>;
  /** the author of the content item is equal to */
  author?: InputMaybe<Scalars['String']>;
  /** the author of the content item contains the string */
  author_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item ends with the string */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is in collection */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item is not equal to */
  author_not?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not contain the string */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** the author of the content item does not end with the string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item is not in collection */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the author of the content item does not start with the string */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the author of the content item starts with the string */
  author_starts_with?: InputMaybe<Scalars['String']>;
  /** content item id is equal to */
  contentItemId?: InputMaybe<Scalars['ID']>;
  /** content item id is in collection */
  contentItemId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** content item id is not equal to */
  contentItemId_not?: InputMaybe<Scalars['ID']>;
  /** content item id is not in collection */
  contentItemId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is equal to */
  contentItemVersionId?: InputMaybe<Scalars['ID']>;
  /** the content item version id is in collection */
  contentItemVersionId_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the content item version id is not equal to */
  contentItemVersionId_not?: InputMaybe<Scalars['ID']>;
  /** the content item version id is not in collection */
  contentItemVersionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** the date and time of creation is equal to */
  createdUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than */
  createdUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is greater than or equal */
  createdUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is in collection */
  createdUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of creation is less than */
  createdUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is less than or equal */
  createdUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not equal to */
  createdUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of creation is not in collection */
  createdUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the display text of the content item is equal to */
  displayText?: InputMaybe<Scalars['String']>;
  /** the display text of the content item contains the string */
  displayText_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item ends with the string */
  displayText_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is in collection */
  displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item is not equal to */
  displayText_not?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not contain the string */
  displayText_not_contains?: InputMaybe<Scalars['String']>;
  /** the display text of the content item does not end with the string */
  displayText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item is not in collection */
  displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the display text of the content item does not start with the string */
  displayText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the display text of the content item starts with the string */
  displayText_starts_with?: InputMaybe<Scalars['String']>;
  /** the localization part of the content item */
  localization?: InputMaybe<LocalizationInputObjectType>;
  /** the date and time of modification is equal to */
  modifiedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than */
  modifiedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is greater than or equal */
  modifiedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is in collection */
  modifiedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of modification is less than */
  modifiedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is less than or equal */
  modifiedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not equal to */
  modifiedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of modification is not in collection */
  modifiedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the owner of the content item is equal to */
  owner?: InputMaybe<Scalars['String']>;
  /** the owner of the content item contains the string */
  owner_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item ends with the string */
  owner_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is in collection */
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item is not equal to */
  owner_not?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not contain the string */
  owner_not_contains?: InputMaybe<Scalars['String']>;
  /** the owner of the content item does not end with the string */
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item is not in collection */
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** the owner of the content item does not start with the string */
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  /** the owner of the content item starts with the string */
  owner_starts_with?: InputMaybe<Scalars['String']>;
  /** the date and time of publication is equal to */
  publishedUtc?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than */
  publishedUtc_gt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is greater than or equal */
  publishedUtc_gte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is in collection */
  publishedUtc_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** the date and time of publication is less than */
  publishedUtc_lt?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is less than or equal */
  publishedUtc_lte?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not equal to */
  publishedUtc_not?: InputMaybe<Scalars['DateTime']>;
  /** the date and time of publication is not in collection */
  publishedUtc_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export enum UploadFileType {
  CustomOffer = 'CUSTOM_OFFER',
  Drawing3D = 'DRAWING3_D',
  Manual = 'MANUAL',
  TechnicalDrawing = 'TECHNICAL_DRAWING'
}

/** Represents a single User that is a future owner of the RealEstate. */
export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  /** Gets the email address of the user. */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Gets the date and time this user logged in. */
  lastLogin?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  /** Gets the RealEstateContact this is a user for. */
  realEstateContacts?: InputMaybe<ListFilterInputTypeOfRealEstateContactFilterInput>;
};

/** User Login information. */
export type UserLoginDto = {
  __typename?: 'UserLoginDto';
  /** Gets or sets the user access token. */
  accessToken?: Maybe<Scalars['String']>;
  /** Gets or sets the user available real estates. */
  availableRealEstates?: Maybe<Array<UserLoginRealEstateDto>>;
};

/** Real estate information. */
export type UserLoginRealEstateDto = {
  __typename?: 'UserLoginRealEstateDto';
  /** Gets or sets real estate contact access token. */
  accessId: Scalars['UUID'];
  /** Gets or sets real estate building number. */
  buildingNumber?: Maybe<Scalars['String']>;
  /** Gets or sets real estate identifier. */
  realEstateId: Scalars['UUID'];
  /** Gets or sets the real estate number. */
  realEstateNumber: Scalars['String'];
};

/** Represents a single User that is a future owner of the RealEstate. */
export type UserSortInput = {
  /** Gets the email address of the user. */
  email?: InputMaybe<SortEnumType>;
  /** Gets or sets the primary key for this entity. */
  id?: InputMaybe<SortEnumType>;
  /** Gets the date and time this user logged in. */
  lastLogin?: InputMaybe<SortEnumType>;
};

/** The group of users that should be included. */
export enum UsersGroupFilterType {
  /** All users. No Restrictions. */
  AllRensa = 'ALL_RENSA',
  /** User that do designs and drawings. */
  Draftsman = 'DRAFTSMAN',
  /** All users from the Showroom department. */
  Showroom = 'SHOWROOM',
  /** Users that are DeBadkamer specialists. */
  Specialists = 'SPECIALISTS'
}

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

/** The valid types for a project */
export enum Badkamercore_ProjectType {
  /** Style based project */
  DeBadkamerwerelden = 'DE_BADKAMERWERELDEN',
  /**
   * Master product package with defaults only.
   * @deprecated Remove after Master product package creation
   */
  MasterWithDefault = 'MASTER_WITH_DEFAULT',
  /** Brand based project */
  Merkenpropositie = 'MERKENPROPOSITIE',
  /** Product packaged based project */
  Productenpropositie = 'PRODUCTENPROPOSITIE'
}
